import React from "react";

import "../CardGenericoSearch/styles.css";
import { converter_data } from "../../../utils/functions";
import CardGenericoSearch from "../../../components/Gestao/CardGenericoSearch/CardGenericoSearch";

// 317280_2276

export default function CardSearch({ iten, motivo }) {

  const ofertado = iten.available;
  let textoStatus = iten.available ? "Ofertado" : iten.status == "Accepted" ? "Ofertado" : "Não ofertado"
  let classeStatus = iten.available ? "badged green" : iten.status == "Accepted" ? "badged green" : "badged red"
  let classeMotivo = iten.sku ? "badged orange" : "badged purple";
  let textoMotivo = motivo ? motivo : iten.sku ? "Catálogo" : "Operação";

  return (

    <div className="box-card-busca" >

      <CardGenericoSearch iten={iten} />

      <div className="divider-card-search" />

      <div className="card-section-2">
        <p className="title-section-2">Detalhes</p>


        <div className="box-skus-2">
          <p className="title-card-search color-title-2">Coleção:</p>
          <p className="title-card-search color-value-2 margin-value-2">{iten.COLECAO ? iten.COLECAO : '-'}</p>
          <p className="title-card-search color-title-2">Pacote:</p>
          <p className="title-card-search color-value-2 margin-value-2">{iten.PACOTE ? iten.PACOTE : '-'}</p>
          <p className="title-card-search color-title-2">Data de lançamento:</p>
          <p className="title-card-search color-value-2 margin-value-2">{iten.data_programada ? converter_data(iten.data_programada) : '-'}</p>
        </div>


        <div className="box-skus-2 box-skus-3">
          <p className="title-card-search color-title-2">Status:</p>
          <p className={classeStatus}> {textoStatus}</p>
          <div className="divider-card-search divider-card-search-2" />
          <p className="title-card-search color-title-2">Motivo:</p>

          {!ofertado ? (<>
            <p className={classeMotivo}>{textoMotivo}</p>
          </>) : (<><p className="default">-</p></>)}

          < div className="divider-card-search divider-card-search-2" />
          <p className="title-card-search color-title-2">Critérios:</p>

          {!ofertado ? (<>{
            iten.criterios.map((criterio) => {
              return <p className="badged gray">{criterio}</p>
            })
          }
          </>) : (<p className="default">-</p>)}
        </div>

      </div>



    </div >

  );
}
