import { useState, useEffect } from "react";
import * as React from 'react';
import "./ofertados.css";
import CardGenericoSearch from "../../../components/Gestao/CardGenericoSearch/CardGenericoSearch";
import { Typography, Grid } from "@material-ui/core";
import { defaultRequest, formatPrice } from "../../../utils/functions";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { Skeleton } from "@mui/material";
import Container from '@mui/material/Container';
import ScrollSelect from "../../../components/Gestao/ScrollSelect/ScrollSelect";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router";
import MenuFarolDeskMobile from "./../../../components/Gestao/MenuFarol/MenuDeskMobile";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import CardSearch from "../../../components/Gestao/CardSearch/CardSearch";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "#fff !important",
    },
  },
  default: {
    backgroundColor: "inherit !important",
  },
});


export default function Ofertados() {

  const classes = useStyles();
  const [params, setParams] = useState({ filtro_lancados: false, filtro_colecao: false });
  const route = "/produtosOfertados";
  const [isLoading, setLoading] = useState(true);
  const [valorSelectOrdenacao, setValorSelectOrdenacao] = useState('');
  const [busca, setBusca] = useState(false);
  const [dados, setDados] = useState(false);
  const [titulo, setTitulo] = useState(false);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tipo_produtos = urlParams.get('id');
    params.tipo_produtos = tipo_produtos ? tipo_produtos.toUpperCase() : false;

    setTitulo(tipo_produtos == 'marketplace' ? "Estoque ofertado marketplace" : "Estoque ofertado")

    defaultRequest(route, params).then(function (response) {
      setDados(response)
      setLoading(false)
    }).catch((error) => {
      console.log(error.message);
      return;
    });

  }, []);


  const handleChangeOrdenacao = async (e) => {

    let skus = dados.dados;

    await skus.sort(function (a, b) {
      let valorA = a.ESTOQUE * a.PRECO_ECOMMERCE;
      let valorB = b.ESTOQUE * b.PRECO_ECOMMERCE;

      if (e.target.value == 'maior') {
        return valorB - valorA;
      } else if (e.target.value == 'menor') {
        return valorA - valorB;
      }
    });

    setValorSelectOrdenacao(e.target.value)
  };


  return (
    <>
      <MenuFarolDeskMobile
        title={<HeaderDesk atualizado={dados.atualizado} setBusca={setBusca} tipoBusca={"GESTAO"} setLoading={setLoading} title={titulo ? titulo : []} enderecoVoltar={"/gestao"} />}

        body={
          <Container maxWidth="sm" className="container">
            <InfoMenuMobile title={"Estoque ofertado"} setBusca={setBusca} tipoBusca={"GESTAO"} setLoading={setLoading} atualizado={dados.atualizado} tipoTela={true} enderecoVoltar={"/gestao"} />
            <div className="divider-top" />

            {!busca ? (<>

              <div className="box-top">
                <ScrollSelect colecoes={dados.colecoes} params={params} setParams={setParams} setDados={setDados} setLoading={setLoading} tela={'ofertados'} route={route} pacotes={dados.pacotes} />
              </div>

              <div className="box-individual-ofertados"> <Typography component="h2" className="titulo-box-ofertados"> Receita Total</Typography>

                {isLoading ? (<Skeleton className="skeleton-format skeleton-ofertados" variant="rounded" sx={{ minHeight: 'inherit', Height: 'inherit' }} />) : (<>
                  <div className="box-principal">
                    <p className="sub-titulo-2 titulo-receita"> {formatPrice(dados.total_receita)} </p>
                    <div className=" divider-ofertados" />
                    <div className="box-skus">
                      <p className="sub-titulo-1 sub-titulo-1-ofertados"> Qtd. de SKU:  </p>
                      <p className="sub-titulo-2 sub-titulo-2-ofertados"> {dados.total_skus} </p>
                    </div>
                  </div>
                </>)}
              </div>

              <div className="box-pai">
                <div className="box-select-ordenacao">
                  <h5 className="h5-ofer">Ordenar por: </h5>
                  <FormControl variant="outlined" className="select">
                    <Select displayEmpty inputProps={{ 'aria-label': 'Without label' }} onChange={handleChangeOrdenacao} name="ordenacao" value={valorSelectOrdenacao} >
                      <MenuItem value=""> Sem Ordenação </MenuItem>
                      <MenuItem className={classes.root} value='maior'>Maior Valor</MenuItem>
                      <MenuItem className={classes.root} value='menor'>Menor Valor</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <Grid container spacing={1} >
                  {isLoading ? (<Skeleton className="skeleton-format skeleton-ofertados" variant="rounded" sx={{ minHeight: 'inherit', Height: 'inherit' }} />) : (<>
                    {dados.dados.map((iten) => { return <CardGenericoSearch iten={iten} isLoading={isLoading} /> })}
                  </>)}
                </Grid>
              </div>

            </>) : (<>

              <div className="white-space-2" />
              <Grid container spacing={1} >
                {busca.map((iten) => { return (<CardSearch iten={iten} />); })}
              </Grid>
            </>)}

          </Container>
        } />
    </>);
}
