import * as React from "react";
import { useState, useEffect } from "react";
import { selTodasReferencias } from "../../../utils/functions";
import Row from "./RowProdutosMarketplace";
import "./TabelaProdutosMarketplace.css";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CardOffer from "../../../components/Gestao/CardOffer/CardOffer";
import { Pagination } from "@mui/material";

export default function TabelaProdutosMarketplace({ rows, tipo_tabela }) {
  const [dados, setDados] = useState([]);
  const [ordenacao, setOrdenacao] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  let pageCount = Math.floor(dados.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setDados(rows);
  }, []);

  const sort = () => {
    let dadosOrdenados = dados.sort(function (a, b) {
      let valorA = a.ESTOQUE * a.PRECO_ECOMMERCE;
      let valorB = b.ESTOQUE * b.PRECO_ECOMMERCE;
      return ordenacao ? valorA - valorB : valorB - valorA;
    });
    setOrdenacao(ordenacao ? false : true);
    setDados(dadosOrdenados);
  };

  return (
    <div className="tabela-responsiva-marketplace">
      <table className="table-border" cellspacing="0">
        <thead>
          <tr className="header-tabela">
            {tipo_tabela && (
              <th className="">
                <label className="custom-checkbox checkbox-mobile" style={{ width: "120px" }}>
                  <input
                    type="checkbox"
                    id="checkboxTodasReferencias"
                    onClick={(e) => {
                      selTodasReferencias(e, "checkboxMarketplace", "refSelecionadasMarketplace", "btn_aprovar_marketplace");
                    }}
                  />
                  <span className="checkmark">Referência</span>
                </label>
              </th>
            )}

            {!tipo_tabela && <th className="p-l-20">Referência </th>}
            <th>Qtd de SKU</th>
            <th>Coleção</th>
            <th>
              {" "}
              <div className="valor-estoque">
                {" "}
                Valor em estoque{" "}
                <button className="sort" onClick={sort}>
                  {" "}
                  {ordenacao ? <ExpandLess /> : <ExpandMore />}{" "}
                </button>{" "}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {!rows ? (
            <Skeleton className="skeleton-format skeleton-nao-ofertados" variant="rectangular" />
          ) : (
            <>
              <CardOffer id={tipo_tabela ? "cardOfferPendentes" : false} texto={"Não há referências pendentes de aprovação"} />
              {dados.length > 0 && dados.length > 99
                ? dados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((iten) => {
                    return <Row row={iten} />;
                  })
                : dados.map((iten) => {
                    return <Row row={iten} />;
                  })}
            </>
          )}
        </tbody>
      </table>

      <div className="wrapper-pagination">
        <Pagination
          shape="rounded"
          color="primary"
          count={pageCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
}
