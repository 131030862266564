import React, { useState, useEffect } from "react";
import "./principal.css";
import { Grid } from "@material-ui/core";
import Container from "@mui/material/Container";
import Grafico from "../../../components/Gestao/Grafico";
import { defaultRequest } from "../../../utils/functions";
import CardOffer from "../../../components/Gestao/CardOffer/CardOffer";
import { Skeleton } from "@mui/material";
import ScrollSelect from "../../../components/Gestao/ScrollSelect/ScrollSelect";
import ModalPageNew from "./../../../components/Gestao/ModalPageNew/ModalPageNew";
import MenuFarolDeskMobile from "./../../../components/Gestao/MenuFarol/MenuDeskMobile";
import CardGenericoEstudio from "../../../components/Estudio/CardGenericoEstudio/CardGenericoEstudio";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import IosShareTwoToneIcon from "@mui/icons-material/IosShareTwoTone";
import ButtonModal from "../../../components/Gestao/Modal/ButtonModal";
import { formatPrice, objToCSV, salvarLoginAceso } from "../../../utils/functions";
import ButtonSnackbar from "../../../components/Estudio/ButtonSnackbar/ButtonSnackbar.js";
import BotaoExportar from "../../../components/Estudio/BotaoExportar/BotaoExportar";
import { getSessionStorage } from "../../../utils/sessionStorage";
import TopoTabsTabela from "../../../components/Estudio/TopoTabsTabelas/TopoTabsTabela";
import ModalImportarDatas from "../../../components/Estudio/ModalImportarDatas/modalImportarDatas.js";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";

export default function EstudioPrincipal() {
  const [params, setParams] = useState({ filtro_lancados: false, filtro_colecao: false, filtro_marca: false, filtro_canal: false });
  const route = "/estudio/principal";
  const [dados, setDados] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [busca, setBusca] = useState(false);
  const [open, setOpen] = useState(false);
  const [msgRetorno, setMsgRetorno] = useState(false);
  const [tipo, setTipo] = useState("success");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [value, setValue] = React.useState("1");
  const user = getSessionStorage("user");

  const exportCsv = async () => {
    setLoading(true);
    defaultRequest("/estudio/exportarUploadCsv", {}, setLoading, isLoading).then(function (response) {
      
      let data = new Date();
      let name = `Atributos_vtex_${user.marcaSelecionada}_${data.getDate()} -${(data.getMonth() + 1)} -${data.getFullYear()} `;
      let hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(response);
      hiddenElement.target = "_blank";
      hiddenElement.download = name + ".csv";
      hiddenElement.click();

      setMsgRetorno("Arquivo exportado com sucesso");
      setTipo("success");
      setOpen(true);
      setLoading(false);
    });
  };

  const handleResize = async () => setWindowWidth(window.innerWidth);

  const alternarTabs = (event, newValue) => {
    let btnEmail = document.getElementsByClassName("btn-enviarEmail");
    let btnData = document.getElementsByClassName("btn-dataPricker");
    let btnComentario = document.getElementsByClassName("btn-comentario");

    if (newValue == 2) {
      btnEmail[0].removeAttribute("style");
      btnData[0].removeAttribute("style");

      let storage2 = getSessionStorage(
        `refSelecionadasPacoteUpload_${user.marcaSelecionada}`
      );
      if (storage2) {
        btnComentario[0].setAttribute("style", "color: #000 !important");
      } else {
        btnComentario[0].removeAttribute("style");
      }
    } else {
      let storage = getSessionStorage(
        `refSelecionadasPacote_${user.marcaSelecionada}`
      );
      if (storage) {
        btnEmail[0].setAttribute("style", "color: #000 !important");
        btnData[0].setAttribute("style", "color: #000 !important");
        btnComentario[0].setAttribute("style", "color: #000 !important");
      } else {
        btnComentario[0].removeAttribute("style");
      }
    }
    setValue(newValue);
  };

  const desabilitarBotoes = () => {
    let btnEmail = document.getElementsByClassName("btn-enviarEmail");
    let btnData = document.getElementsByClassName("btn-dataPricker");
    let btnComentario = document.getElementsByClassName("btn-comentario");
    btnEmail[0].removeAttribute("style");
    btnData[0].removeAttribute("style");
    btnComentario[0].removeAttribute("style");
  };

  const salvarDataSessaoFoto = async (e) => {
    if (value == 2) return;

    const el = document.getElementsByClassName("diaSelecionado");
    if (el.length == 0) {
      setMsgRetorno("Escolha uma data.");
      setTipo("warning");
      setOpen(true);
      return false;
    }

    const refs = getSessionStorage(
      `refSelecionadasPacote_${user.marcaSelecionada}`
    );
    if (!refs || refs.length == 0) {
      return false;
    }
    setDados(false);

    let params = sessionStorage.getItem("params")
      ? JSON.parse(sessionStorage.getItem("params"))
      : {};
    params.referencias = refs;
    params.usuario = user.id_usuario;
    params.data = el[0].getAttribute("data");

    defaultRequest("/estudio/salvarDataSessaoFotos", params)
      .then((response) => {
        setMsgRetorno("Data salva com sucesso");
        setTipo("success");
        setOpen(true);
        sessionStorage.removeItem(
          `refSelecionadasPacote_${user.marcaSelecionada}`
        );
        setDados(response);
        desabilitarBotoes();
      })
      .catch((error) => {
        console.log(error);
        setMsgRetorno("Houve um erro interno 1!");
        setTipo("error");
        setOpen(true);
        return;
      });
  };

  const salvarDataEnvioEmail = async (e) => {
    if (value == 2) return;

    const refs = getSessionStorage(
      `refSelecionadasPacote_${user.marcaSelecionada}`
    );
    if (!refs || refs.length == 0) {
      return false;
    }
    setDados(false);

    let params = sessionStorage.getItem("params") ? JSON.parse(sessionStorage.getItem("params")) : {};
    params.referencias = refs;
    params.usuario = user.id_usuario;

    defaultRequest("/estudio/salvarDataEnvioEmail", params)
      .then((response) => {
        setMsgRetorno("Email enviado com sucesso");
        setTipo("success");
        setOpen(true);
        sessionStorage.removeItem(
          `refSelecionadasPacote_${user.marcaSelecionada}`
        );
        setDados(response);
        desabilitarBotoes();
      })
      .catch((error) => {
        console.log(error);
        setMsgRetorno("Houve um erro interno 1!");
        setTipo("error");
        setOpen(true);
        return;
      });
  };

  const salvarComentario = (e) => {
    const marca = getSessionStorage("user").marcaSelecionada;

    const comentario = document.querySelector('input[name="radio-buttons-group"]:checked');
    if (comentario == null) {
      return;
    }

    const referencias = getSessionStorage(`refSelecionadasPacote_${marca}`);
    if (!referencias || referencias.length == 0) {
      return;
    }

    let params = sessionStorage.getItem("params") ? JSON.parse(sessionStorage.getItem("params")) : {};
    params.referencias = referencias;
    params.usuario = user.id_usuario;
    params.comentario = comentario.value;

    defaultRequest("/estudio/salvarComentario", params)
      .then((response) => {
        setMsgRetorno("Comentário salvo com sucesso");
        setTipo("success");
        setOpen(true);
        sessionStorage.removeItem(`refSelecionadasPacote_${marca}`);
        desabilitarBotoes();
      })
      .catch((error) => {
        console.log(error);
        setMsgRetorno("Houve um erro interno 2!");
        setTipo("error");
        setOpen(true);
      });
  };

  useEffect(() => {
    // sessionStorage.removeItem('params')
    salvarLoginAceso()
    defaultRequest(route, params).then(function (response) {
      setDados(response);
      setLoading(false);
    }).catch((error) => {
      console.log(error.message); return;
    });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (<>
    <MenuFarolDeskMobile title={<HeaderDesk atualizado={dados.atualizado} setBusca={setBusca} tipoBusca={"ESTUDIO"} setLoading={setLoading} tipoTela={"PRINCIPAL"} title={"Planejamento"} enderecoVoltar={"/estudio"} />}
      body={
        <>
          <ButtonSnackbar funcaoClick={false} texto={""} abrirToast={open} msgRetorno={msgRetorno} tipo={tipo} setAbrirToast={setOpen} id={""} />
          <ModalPageNew />

          <Container maxWidth="sm" className="container">
            <InfoMenuMobile title={"Planejamento"} setBusca={setBusca} tipoBusca={"ESTUDIO"} setLoading={setLoading} atualizado={dados.atualizado} tipoTela={true} enderecoVoltar={"/estudio"} />
            <div className="divider-top" />

            {!busca ? (<>
              <div className="box-top">
                <ScrollSelect colecoes={dados.colecoes} params={params} setParams={setParams} setDados={setDados} setLoading={setLoading} tela={"estudio"} route={route} />
              </div>

              <div className="cards-grafico">
                <div className="box box-estudio" style={{ "paddingLeft": "unset" }}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" >
                    <Grid container className="box-individual-estudio tamanho-box-estudio">
                      {isLoading ? (<Skeleton className="skeleton-format" variant="rounded" height={250} />) : (<>

                        <Grid direction="row" item xs={12}>
                          <div className="graph-pecas">
                            <p className="titulo-grafico2-estudio">Total de Referências</p>
                            <p className="total-card-grafico"> Total: {dados.com_foto + dados.sem_foto + dados.prioridade} </p>
                          </div>
                        </Grid>


                        <Grid direction="row" item xs={12}>
                          <div className="graph-estudio">
                            <Grafico part1={dados.porcentagem_com_foto} part2={dados.porcentagem_sem_foto} part3={dados.porcentagem_prioridade} tipoTela={1} id={"estudio"} />

                            <div className="div-sku" style={{ "height": "50vh" }}>
                              <div className="card-numero-pecas">
                                <div className="pecas-numero"> {dados.com_foto} </div>
                              </div>
                              <div className="circle-text">
                                <div className="circle-estudio circle-green" />
                                <div className="texto-foto"> Com fotos ({`${dados.porcentagem_com_foto}%`}) </div>
                              </div>
                              <div className="texto-receita"> Receita: <strong>{formatPrice(dados.com_foto_receita)}</strong></div>

                              <div className="white-space-estudio" />


                              <div className="card-numero-pecas">
                                <div className="pecas-numero"> {dados.sem_foto} </div>
                              </div>
                              <div className="circle-text">
                                <div className="circle-estudio circle-red" />
                                <div className="texto-foto">Sem foto ({`${dados.porcentagem_sem_foto}%`})</div>
                              </div>
                              <div className="texto-receita"> Receita: <strong>{formatPrice(dados.sem_foto_receita)}</strong></div>

                              <div className="white-space-estudio" />

                              <div className="card-numero-pecas">
                                <div className="pecas-numero"> {dados.prioridade}</div>
                              </div>
                              <div className="circle-text">
                                <div className="circle-estudio circle-pink" />
                                <div className="texto-foto"> Sem foto com prioridade ({`${dados.porcentagem_prioridade}%`})</div>
                                <ButtonModal titleModal={"Prioridade"} textModal={"Produtos sem foto, com estoque e já lançados "} />
                              </div>
                              <div className="texto-receita"> Receita: <strong>{formatPrice(dados.prioridade_receita)}</strong></div>
                            </div>
                          </div>
                        </Grid>
                      </>)}
                    </Grid>
                  </Grid>
                </div>

                <div className="box box-estudio">
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid xs={12} container>
                      <Grid container className="box-individual-estudio tamanho-box-estudio">
                        {isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={250} />) : (<>


                          <Grid direction="row" item xs={12}>
                            <div className="graph-pecas">
                              <p className="titulo-grafico2-estudio">Referências no Estudio</p>
                              <p className="total-card-grafico"> Total: {dados.produtos_no_estudio} </p>
                            </div>
                          </Grid>


                          <Grid direction="row" item xs={12}>
                            <div className="graph-estudio">
                              <Grafico part1={dados.porcentagem_agendados} part2={dados.porcentagem_nao_agendados} tipoTela={1} id={"estudio"} />

                              <div className="div-sku" style={{ "height": "50vh" }}>
                                <div className="card-numero-pecas">
                                  <div className="pecas-numero"> {dados.produtos_agendados_fotografia} </div>
                                </div>
                                <div className="circle-text">
                                  <div className="circle-estudio circle-green" />
                                  <div className="texto-foto">{windowWidth < 959 ? "Agendadas" : "Agendadas para foto"} ({`${dados.porcentagem_agendados}%`})</div>
                                </div>
                                <div className="texto-receita">Receita: <strong>{formatPrice(dados.produtos_agendados_fotografia_receita)}</strong></div>

                                <div className="white-space-estudio" />

                                <div className="card-numero-pecas">
                                  <div className="pecas-numero"> {dados.produtos_no_estudio - dados.produtos_agendados_fotografia > 0 ? dados.produtos_no_estudio - dados.produtos_agendados_fotografia : 0} </div>
                                </div>
                                <div className="circle-text">
                                  <div className="circle-estudio circle-red" />
                                  <div className="texto-foto"> {windowWidth < 959 ? "Não agendadas" : "Não agendadas para foto"} ({`${dados.porcentagem_nao_agendados}%`})</div>
                                </div>
                                <div className="texto-receita">Receita: <strong>{formatPrice(dados.produtos_no_estudio_receita)} </strong></div>
                              </div>
                            </div>

                          </Grid>
                        </>)}
                      </Grid>
                    </Grid>

                    <div className="white-space-1" />
                  </Grid>
                </div>
              </div>

              <div className="div-exportar">
                <span className="titulo-principal" style={{ width: "100%" }}> Pacotes Estúdio </span>
                <div className="div-btn-exportar-importar">
                  <BotaoExportar className="btn-exportar" onClick={exportCsv} title={"Exportar"} endIcon={<IosShareTwoToneIcon />}></BotaoExportar>
                  <ModalImportarDatas titleModal={"Importar arquivo de datas"} />
                </div>
              </div>

              <Grid item> <div className="divider-principal" /> </Grid>
              <div className="white-space-2" />
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} style={{ width: "100% + 18px" }}>
                {isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={250} />) : (<>
                  {dados.pacotes &&
                    dados.pacotes.map((iten) => {
                      return <CardGenericoEstudio iten={iten} />;
                    })}
                </>)}
              </Grid>
            </>) : (<>
              <TopoTabsTabela
                value={value}
                alternarTabs={alternarTabs}
                exportCsv={exportCsv}
                IosShareTwoToneIcon={IosShareTwoToneIcon}
                salvarDataEnvioEmail={salvarDataEnvioEmail}
                salvarDataSessaoFoto={salvarDataSessaoFoto}
                salvarComentario={salvarComentario}
                dados={dados}
                busca={busca}
              />

            </>)}

            {dados.todosProdutosOfertados && <CardOffer texto={"Todos os produtos estão ofertados"} />}
          </Container >
        </>
      }
    />
  </>
  );
}
