import React, { useState, useEffect } from "react";
import "./../style.css";
import Container from "@mui/material/Container";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ModalDetalheProduto from "../../../components/Fotos/ModalFotos/ModalDetalheProduto";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { handleClickTab, requestSomaStudio, montarTelaFotos, filtroErroSucesso } from "../../../utils/functions";
import semFotos from "../../../static/sem-fotos.png";
import FotosProduto from "../../../components/Fotos/FotosProduto/FotosProduto";
import { getSessionStorage, removeSessionStorage } from "../../../utils/sessionStorage";
import Diretorio from "../../../components/Fotos/Diretorio/Diretorio";

export default function Upload() {

    // let tipo_ecom = getSessionStorage("user").marcaSelecionada == "FARM GLOBAL" ? "Shopfy" : "VTEX";
    const diretorio = 'upload_erro'
    const [diretorioAtualSucesso, setdiretorioAtualSucesso] = useState(['upload_enviadas']);
    const [diretorioAtualErros, setdiretorioAtualErros] = useState(['upload_erros']);
    const [fotosUpload, setFotosUpload] = useState(false);
    const [diretoriosUpload, setDiretoriosUpload] = useState(false);
    const [fotosErro, setFotosErro] = useState(false);
    const [diretoriosErro, setDiretoriosErro] = useState(false);
    // const [todos, setTodos] = useState({ qtdReferencias: 0, qtdFotos: 0, imagens: [] });
    const [busca, setBusca] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [stateRefSelecionadas, setStateRefSelecionadas] = useState(false);
    const [open, setOpen] = useState(false);
    const [dadosSucesso, setDadosSucesso] = useState([]);
    const [dadoErros, setDadosErros] = useState([]);
    const [fotosModalDetalhe, setFotosModalDetalhe] = useState([]);
    const [modif, setModif] = useState(false);
    const marca = getSessionStorage("user").marcaSelecionada;
    const [pastasSelecionadasErros, setPastasSelecionadasErros] = useState([]);
    const [pastasSelecionadasSucesso, setPastasSelecionadasSucesso] = useState([]);
    const [valueTab, setValueTab] = useState(1);

    useEffect(async () => {
        await removeSessionStorage(`fotos_selecionadas_${marca}`);
        let ret = await requestSomaStudio("/pictures");

        const up = await filtroErroSucesso(ret.data, 'upload_enviadas')
        setDadosSucesso(up)
        await montarTelaFotos(up, diretorioAtualSucesso, setFotosUpload, setDiretoriosUpload);


        const err = await filtroErroSucesso(ret.data, 'upload_erros')
        setDadosErros(err)
        await montarTelaFotos(err, diretorioAtualErros, setFotosErro, setDiretoriosErro);

        setLoading(true)
    }, [modif]);

    const handlerOpenModalDetalheFoto = (e) => {
        let novo = [];
        if (valueTab == 1) {
            fotosUpload.imagens.map(item => { if (item.reference == e) { novo.push(item.imagens) } })
        } else {
            fotosErro.imagens.map(item => { if (item.reference == e) { novo.push(item.imagens) } })
        }
        setFotosModalDetalhe(novo)
        setOpen(true)
    };




    return (<>
        <MenuFarolDeskMobile title={<HeaderDesk setPastasSelecionadas={valueTab == 1 ? setPastasSelecionadasSucesso : setPastasSelecionadasErros} setDiretorioAtual={valueTab == 1 ? setdiretorioAtualSucesso : setdiretorioAtualErros} setModif={setModif} atualizado={''} setBusca={setBusca} setLoading={setLoading} tipoTela={false} title={valueTab == 1 ? diretorioAtualSucesso : diretorioAtualErros} enderecoVoltar={"/fotos/inicio"} />}

            body={<>
                {open && <ModalDetalheProduto fotos={fotosModalDetalhe} open={open} setOpen={setOpen} />}
                <Container maxWidth="sm" className="container">
                    <div className="divider-top" />
                    <InfoMenuMobile title={"Upload VTEX"} setBusca={setBusca} setLoading={setLoading} atualizado={''} tipoTela={true} enderecoVoltar={"/fotos/inicio"} />

                    <div className="p-topo">
                        <div className="card-fotos-generico">

                            <div className="tab-upload-fotos">
                                {/* <button name="todos" className="button-tab-upload-fotos tablinks active" onClick={(e) => handleClickTab(e)} > Todos ({todos.qtdReferencias}) </button> */}
                                <button name="enviados" className="button-tab-upload-fotos tablinks active" onClick={(e) => handleClickTab(e, setValueTab)} > Enviados ({fotosUpload.qtdReferencias ? fotosUpload.qtdReferencias : 0}) </button>
                                <button name="com_erros" className="button-tab-upload-fotos tablinks" onClick={(e) => handleClickTab(e, setValueTab)} > Com erro ({fotosErro.qtdReferencias ? fotosErro.qtdReferencias : 0}) </button>
                            </div>

                            <div className="tab-box">

                                <div id="enviados" className="tabcontent" style={{ display: "block" }} >
                                    {diretoriosUpload &&
                                        <Diretorio
                                            diretorios={diretoriosUpload}
                                            diretorioAtual={diretorioAtualSucesso}
                                            setDiretorioAtual={setdiretorioAtualSucesso}
                                            dados={dadosSucesso}
                                            setFotos={setFotosUpload}
                                            setDiretorios={setDiretoriosUpload}
                                            setPastasSelecionadas={setPastasSelecionadasSucesso}
                                            pastasSelecionadas={pastasSelecionadasSucesso}
                                            setModif={setModif}
                                        />
                                    }

                                    {diretoriosUpload && fotosUpload && <div className="card-fotos-generico-divider" />}

                                    <div className="box-fotos" >
                                        {!fotosUpload && !diretoriosUpload ? (<div className="container-sem-fotos"> <img className="img-sem-fotos" src={semFotos} /> <p className="text-center">Não há fotos Enviada</p></div>) : (<>

                                            {fotosUpload && <>
                                                {fotosUpload.imagens.map((item) => {
                                                    return <FotosProduto handlerOpenModalDetalheFoto={handlerOpenModalDetalheFoto} fotos={item} setStateRefSelecionadas={setStateRefSelecionadas} tela={'sem_tratamento'} classe={`todos_produtos_linha_${diretorio}`} />
                                                })}
                                            </>}

                                        </>)}
                                    </div>
                                </div>

                                <div id="com_erros" className="tabcontent" style={{ display: "none" }} >

                                    {diretoriosErro &&
                                        <Diretorio
                                            diretorios={diretoriosErro}
                                            diretorioAtual={diretorioAtualErros}
                                            setDiretorioAtual={setdiretorioAtualErros}
                                            dados={dadoErros}
                                            setFotos={setFotosErro}
                                            setDiretorios={setDiretoriosErro}
                                            setPastasSelecionadas={setPastasSelecionadasErros}
                                            pastasSelecionadas={pastasSelecionadasErros}
                                            setModif={setModif}
                                        />
                                    }

                                    {fotosErro && diretoriosErro && <div className="card-fotos-generico-divider" />}

                                    <div className="box-fotos" >
                                        {!fotosErro && !diretoriosErro ? (<div className="container-sem-fotos"> <img className="img-sem-fotos" src={semFotos} /><p className="text-center">Não há fotos com Erros de Upload</p> </div>) : (<>
                                            {fotosErro && <>
                                                {fotosErro.imagens.map((item) => {
                                                    return <FotosProduto handlerOpenModalDetalheFoto={handlerOpenModalDetalheFoto} fotos={item} setStateRefSelecionadas={setStateRefSelecionadas} tela={'upload_erros'} classe={`todos_produtos_linha_${diretorio}`} />
                                                })}
                                            </>}

                                        </>)}
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>

                </Container >
            </>} /></>);
}
