
import React from "react";
import "./index.css";
import { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import chart from "react-apexcharts";

export default function Grafico({ part1, part2, part3 = 0, tipoTela, id }) {

    if ((!part1 && part1 != 0) && (!part2 && part1 != 0))
        return false;

    let colors;
    switch (tipoTela) {
        case 2:
            colors = ['#F2A611', '#7666CA'];
            break;
        case 3:
            colors = ['#8B8B8B', '#1196EB'];
            break;
        default:
            colors = ['#148A6A', '#C52518', '#FFA49C'];
            break;
    }

    var options = {
        series: [parseFloat(part1), parseFloat(part2), parseFloat(part3)],
        colors: colors,
        chart: {
            type: 'donut',

        },

        stroke: {
            show: false,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0,
        },

        responsive: [{
            breakpoint: 100,
            options: {
                chart: {
                    width: 150,
                },
            },
        }],
        legend: false,
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
    };


    const chartRef = useRef(null);
    useEffect(() => {

        chartRef.current.innerHTML = '';
        const chart = new ApexCharts(chartRef.current, options);
        chart.render();

    }, [part1, part2]);

    return (
        <div className="containerDonut" >
            <div ref={chartRef} id={id} />
        </div >
    );
};
