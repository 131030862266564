import React from 'react';
import {
  alpha,
  ThemeProvider,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import "./InputMUI.css";
import { useState } from "react";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const CssTextField = withStyles({
  root: {
   

    '& label.Mui-focused': {
      color: '#8b8b8b',
      fontSize: "14px",
      fontWeight: "400",
    },
    '& .MuiInput-underline:after': {
      border: '0',
      borderBottomColor: '#cecece',
    },
    '& .MuiOutlinedInput-root': {
    
      '& fieldset': {
        border: '0',
        borderColor: 'red',
      },
      '&:hover fieldset': {
        border: '0',
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        border: '0',
        borderColor: 'red',
      },
    },
  },
})(TextField);



export default function InputMUI({type, label, variant, showPasswordIcon = false, id, error, field, setField,setValidClass}) {
  const errorClass = error ? 'errorClass' : ' ';
  const [showPassword, setShowPassword] = useState(false);
 

  const handleClickShowPassword = () => {
    if (showPasswordIcon) {
      setShowPassword(!showPassword);
      const campo = document.querySelector('#'+id)
      const atributo = campo.type.toLowerCase() == 'text' ? 'password' : 'text';
      campo.setAttribute('type', atributo) 
    } 
  };

  const handleChange = (event) => {
    setValidClass(false)
    setField(event.target.value);
  };


  return (
    <div className='input-mui'>
      <CssTextField 
        error={error}
        className={` ${errorClass}`}
        label={label} 
        value={field}
        variant={variant}
        id={id} 
        type={type}
        onChange={handleChange}
      />
      {showPasswordIcon &&
        <button className="div-visibility" type="submit" onClick={handleClickShowPassword}>
          {showPassword ? <VisibilityOutlinedIcon className="visibility"/> : <VisibilityOffOutlinedIcon className="visibility" />}
        </button>
      }
    </div>
    
  );
}
