import * as React from 'react';
import { format_data } from '../../../utils/functions';
import moment from "moment";

export default function RowAtrasoOfertados({ row }) {

  let a = moment(row.data_programada)
  let b = moment(row.data_ofertado ? row.data_ofertado : moment().format("YYYY-MM-DD"))
  const diferenca = b.diff(a, 'days');

  return (<>
    <tr>
      <td className=''> {row.referencia}</td>
      <td className=''> {row.colecao}</td>
      <td className=''> {format_data(row.data_programada)}</td>
      <td className=''> {format_data(row.data_ofertado)}</td>
      <td className=''> {diferenca} Dias</td>
    </tr>
  </>);
}
