import React, { useState } from "react";
import "./modalConfirmacao.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import imgInfo from '../../../static/ab14478bff6867c9aba629c9c2daf90d.png';


export default function ModalConfirmacao({ setFotosIguais, openModalFotosIguais, setOpenModalFotosIguais }) {

  const confirma = async () => { setFotosIguais(true); setOpenModalFotosIguais(false); };

  const rejeita = async () => {
    setFotosIguais(false);
    setOpenModalFotosIguais(false);
  };


  return (<>

    <Modal keepMounted open={openModalFotosIguais}  >
      <Box className="card-modal">

        <div className='body-modal-excluir'>
          <img className="img-modal-excluir" src={imgInfo} />
          <p className="msg-confirmacao" > Existem fotos com o mesmo nome no Farol. Deseja substituir as fotos existentes pelas fotos que está adicionando? </p>

          <div className='footer-btn-modal-excluir'>
            <div className="btn-modal-confirmacao btn-modal-cancelar" onClick={rejeita}>Manter existentes </div>
            <div className="btn-modal-confirmacao btn-modal-excluir" onClick={confirma}>substituir  </div>
          </div>
        </div>

      </Box>
    </Modal >
  </>
  );
}