import * as React from 'react';
import { format_data_full, formatPrice } from '../../../utils/functions';

export default function RowIntegracao({ row }) {
  return (<>
    <tr>
      <td className=''> {row.REFERENCIA}</td>
      <td className=''> {row.QTD}</td>
      <td className=''> {formatPrice(row.RECEITA)}</td>
      <td className=''> {row.USUARIO}</td>
      <td className=''> {format_data_full(row.CREATED_AT)}</td>
    </tr>
  </>);
}
