import React from "react";

import { Grid, Card } from "@material-ui/core";
import "./styles.css";
import ButtonModal from "../../../components/Gestao/Modal/ButtonModal";
import { useNavigate } from "react-router";
import { Skeleton } from "@mui/material";

export default function CardInicio({ item }) {
  const navigate = useNavigate();

  return (
    <>
      <Grid className="cursor" direction="row" item xs={12} md={4} >
        <Card className="card-fotos" variant="outlined">
          {!item.load ? (<Skeleton variant="rounded" sx={{ height: 'inherit' }} />) : (<>
            <div className="car-fotos-box-titulo">
              <p className="card-foto-sub-titulo" onClick={() => navigate(`/fotos${item.endereco}`)}>{item.tituloCard}</p>
              <ButtonModal titleModal={item.tituloCard} textModal={item.textoModal} />
            </div>
            <spam className="label-onClick" onClick={() => navigate(`/fotos${item.endereco}`)}>
              <div className="card-fotos-info">
                <p >{item.qtd.qtdReferencias}</p>
                <p className="m-l-5">Referencias</p>
              </div>
              <div className="card-fotos-info">
                <p >{item.qtd.qtdFotos}</p>
                <p className="m-l-5">Fotos</p>
              </div>
            </spam>
          </>)}
        </Card >
      </Grid >
    </>
  );
}
