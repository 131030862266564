import * as React from "react";
import { useState, useEffect } from "react";
import NovoMenuDesk from "./NovoMenuDesk";
import MenuMobile from "./MenuMobile";
import "./MenuMobile.css";
import "./NovoMenuDesk.css";


export default function MenuDeskMobile({title, body, gamefication = false}) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let component;
  switch (true) {
    case screenWidth < 959:
      component = <MenuMobile body={body} gamefication={gamefication}/>;
      break;
    default:
      component = <NovoMenuDesk title={title} body={body}/>;
      break;
  }

  return <div>{component}</div>;
}

