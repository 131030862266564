import React from "react";
import CardFoto from "../CardFoto/CardFoto";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { handleClickFotosProduto, getBucketName } from "../../../utils/functions";
import BasicBreadcrumbsBusca from "../../Breadcrumbs/BreadcrumbsBusca";
import { getSessionStorage } from "../../../utils/sessionStorage";


export default function FotosProduto({ retBusca, diretorios, fotos, setStateRefSelecionadas, handlerOpenModalDetalheFoto, chip, tela, classe }) {

  const referencia = fotos.reference;
  const name = `${referencia}_${tela ? tela : ''}`;
  const qtdFotos = fotos.imagens.length;
  const marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");

  if (retBusca) {
    diretorios = diretorios.replace(`https://storage.googleapis.com/${getBucketName(marca)}/`, "").split("/");
    diretorios.pop();
  }

  return (<>
    {retBusca &&
      <div className="box-breadcrumbs m-t-16">
        <BasicBreadcrumbsBusca diretorios={`${diretorios.join("/")}/`} />
      </div>
    }

    <div className="box-info-check-todas-refs" >
      {window.innerWidth > 959 &&
        <label className="m-0">
          <Checkbox value={referencia} name={name} qtdFotos={qtdFotos} click={handleClickFotosProduto} id={referencia} classe={classe} />
        </label>
      }
      <p className="m-l-5 f-14 card-fotos-generico-titulo" style={{ color: "#000" }}>{referencia}</p>
      <p className="m-l-5 card-fotos-generico-titulo text-gray">({qtdFotos} {qtdFotos > 1 ? "fotos" : "foto"})</p>
    </div>
    <div className="box-linha-fotos-ref">

      {fotos.imagens.map((item) => {
        return <CardFoto
          url={item.url}
          titulo={item.name}
          referencia={referencia}
          setStateRefSelecionadas={setStateRefSelecionadas}
          chip={chip ? chip : ''}
          handlerOpenModalDetalheFoto={handlerOpenModalDetalheFoto}
          name={name}
        />
      })}
    </div>
    <div className="m-t-16 card-fotos-generico-divider" />

  </>);
}

