import React from "react";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Grid } from "@mui/material";
import { defaultRequest, exportarCsv, } from "../../../utils/functions";
import { useState, useEffect } from "react";
import "../Acessos/acessos.css";
import Row from "./RowListarBackup";

export default function Acessos() {
    const [dados, setDados] = useState(false);
    const [load, setLoad] = useState(false);
    const route = "/listarDadosBackup";

    useEffect(() => {
        defaultRequest(route, {}).then(function (response) { setDados(response); });
    }, []);


    const handleClick = async (event) => {
        event.preventDefault();
        setLoad(true);
        await defaultRequest("/recuperarDados", { endereco: event.target.id });
        setLoad(false);

    };

    return (
        <>
            <MenuFarolDeskMobile
                title={''}
                body={
                    <div className="card-total">
                        <div className="box-geral">
                            <h2 className="titulo-acesso">RECUPERAR DADOS</h2>
                            {/* <button className="btn-exportar-log" onClick={exportar}>Exportar</button> */}
                        </div>
                        <div className="divider-pacote" />

                        {dados && (<>

                            <Grid container className="white-space-4" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela"> <th className="th" >ANIMALE</th> <th className="th" >DATAS</th> </tr>
                                    </thead>
                                    <tbody> <Row marca={"ANIMALE"} row={dados["ANIMALE"]} click={handleClick} load={load} /> </tbody>
                                </table>
                            </Grid>

                            <Grid container className="white-space-4" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela"> <th className="th" >FARM</th> <th className="th" >DATAS</th> </tr>
                                    </thead>
                                    <tbody> <Row marca={"FARM"} row={dados["FARM"]} click={handleClick} load={load} /> </tbody>
                                </table>
                            </Grid>

                            <Grid container className="white-space-4" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela"> <th className="th" >FOXTON</th> <th className="th" >DATAS</th> </tr>
                                    </thead>
                                    <tbody> <Row marca={"FOXTON"} row={dados["FOXTON"]} click={handleClick} load={load} /> </tbody>
                                </table>
                            </Grid>

                            <Grid container className="white-space-4" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela"> <th className="th" >FABULA</th> <th className="th" >DATAS</th> </tr>
                                    </thead>
                                    <tbody> <Row marca={"FABULA"} row={dados["FABULA"]} click={handleClick} load={load} /> </tbody>
                                </table>
                            </Grid>

                            <Grid container className="white-space-4" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela"> <th className="th" >BYNV</th> <th className="th" >DATAS</th> </tr>
                                    </thead>
                                    <tbody> <Row marca={"BYNV"} row={dados["BYNV"]} click={handleClick} load={load} /> </tbody>
                                </table>
                            </Grid>

                            <Grid container className="white-space-4" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela"> <th className="th" >CRIS BARROS</th> <th className="th" >DATAS</th> </tr>
                                    </thead>
                                    <tbody> <Row marca={"CRISBARROS"} row={dados["CRISBARROS"]} click={handleClick} load={load} /> </tbody>
                                </table>
                            </Grid>

                            <Grid container className="white-space-4" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela"> <th className="th" >MARIA FILO</th> <th className="th" >DATAS</th> </tr>
                                    </thead>
                                    <tbody> <Row marca={"MARIAFILO"} row={dados["MARIAFILO"]} click={handleClick} load={load} /> </tbody>
                                </table>
                            </Grid>

                            <Grid container className="white-space-4" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela"> <th className="th" >OFF</th> <th className="th" >DATAS</th> </tr>
                                    </thead>
                                    <tbody> <Row marca={"OFF"} row={dados["OFF"]} click={handleClick} load={load} /> </tbody>
                                </table>
                            </Grid>


                        </>)}

                    </div >
                }
            />
        </>
    );
}
