import React from "react";

import { Grid, Card } from "@material-ui/core";
import "../CardGenericoSearch/styles.css";
import { formatPrice } from "../../../utils/functions";


import Tooltip from "../Tooltip/Tooltip";

export default function CardDeflatores({ iten }) {

  let valorDeflatorSeller = iten.deflator ? iten.ESTOQUE * iten.PRECO_ECOMMERCE : (iten.ESTOQUE - iten.qtd_deflator_marca) * iten.PRECO_ECOMMERCE;
  let valorDeflatorMarca = iten.deflator ? 0 : iten.qtd_deflator_marca * iten.PRECO_ECOMMERCE;

  return (
    <>
      <Grid direction="row" item xs={12} sm={6} value={iten.REF} id={`elemento_${iten.REF}`}>
        <Card className="root-card2" variant="outlined">

          <Grid container direction="row" spacing={1} >
            <div className="tamanho-card">
              <div className="topo-card-generico">
                <div >
                  <p className="title-card-2">Referência</p>
                  <p className="value-1">{iten.REF}</p>
                </div>
              </div>

              <div className="white-space-3"></div>

              <div className="box-skus-1">
                <div className="box-skus-2">
                  <p className="title-2">Qtd. de Skus:</p>
                  <p className="m-l-5 value-1">{iten.qtd_skus}</p>
                </div>

                <div className="box-skus-2">
                  <p className="title-2">Qtd. no estoque:</p>
                  <p className="m-l-5 value-1">{iten.ESTOQUE}</p>
                </div>

                <div className="box-skus-2">
                  <p className="title-2">Receita total:</p>
                  <p style={{ width: "100%" }} className="m-l-5 value-1">{formatPrice(iten.valor_em_estoque)}</p>
                  {iten.COMENTARIO && <Tooltip texto={iten.COMENTARIO} />}
                </div>

                <div className="box-skus-2">
                  <p className="title-2">Receita deflator seller:</p>
                  <p style={{ width: "100%" }} className="m-l-5 value-1">{formatPrice(valorDeflatorSeller)}</p>

                </div>

                <div className="box-skus-2">
                  <p className="title-2">Receita deflator marca:</p>
                  <p style={{ width: "100%" }} className="m-l-5 value-1">{formatPrice(valorDeflatorMarca)}</p>

                </div>


              </div>
            </div>
          </Grid>

        </Card>
      </Grid>
    </>
  );
}
