import "./CardOffer.css";


export default function CardOffer({texto, id }) {

  return (
    <div id={id} className="containerCardOffer displayNone" >
      <div className="pageCardOffer">
        <section className="imageCardOffer" />
      
        <h2 className="texto-card-offer">{texto}</h2>
  

      </div>
    </div>
  );
}
