import React from "react";
import { useState, useEffect } from "react";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Grid, Pagination } from "@mui/material";
import { defaultRequest, exportarCsv } from "../../../utils/functions";
import "react-datepicker/dist/react-datepicker.css";
import "../Acessos/acessos.css";
import Row from "./RowAtrasoOfertados";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import ScrollSelect from "../../../components/Gestao/ScrollSelect/ScrollSelect";
import { Skeleton } from "@mui/material";

export default function AtrasoOfertados() {
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [dadosTable, setDadosTable] = useState([]);
  const [colecoes, setColecoes] = useState([]);
  const [params, setParams] = useState({ filtro_colecao: false, filtro_marca: false });
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  let pageCount = Math.floor(dadosTable.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    defaultRequest("/atrasoOfertados", {}).then((ret) => {
      setColecoes(ret.colecoes);
      setDadosOriginais(ret.dados);
      setDadosTable(ret.dados);
    });
  }, []);

  const exportar = async () => {
    exportarCsv(dadosTable, "ATRASO OFERTADOS");
  };

  return (
    <>
      <MenuFarolDeskMobile
        title={
          <HeaderDesk
            atualizado={dadosTable.atualizado}
            setBusca={false}
            setLoading={false}
            tipoTela={"log resumo diário"}
            title={"ATRASO OFERTADOS"}
            enderecoVoltar={"/gestao"}
          />
        }
        body={
          <div className="card-total box-card-total">
            {/* <div className="divider-pacote" /> */}

            <div className="box-geral resumo-diario">
              <ScrollSelect
                setLoading={setLoading}
                colecoes={colecoes}
                params={params}
                setParams={setParams}
                dadosOriginais={dadosOriginais}
                setDadosTable={setDadosTable}
                tela={"log resumo diário"}
              />
              <button className="btn-exportar-log" onClick={exportar}>
                Exportar
              </button>
            </div>

            {dadosTable.length > 0 ? (
              <>
                <Grid container className="">
                  <table className="table-border" cellspacing="0">
                    <thead>
                      <tr className="header-tabela">
                        <th className="th">Referência</th>
                        <th className="th">Coleção</th>
                        <th className="th">Data programada</th>
                        <th className="th">Data ofertada</th>
                        <th className="th">Diferença</th>
                      </tr>
                    </thead>

                    <tbody>
                      {dadosTable.length > 0 && dadosTable.length > 99
                        ? dadosTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((iten) => {
                            return <Row row={iten} />;
                          })
                        : dadosTable.map((iten) => {
                            return <Row row={iten} />;
                          })}
                    </tbody>
                  </table>
                </Grid>

                <div className="wrapper-pagination">
                  <Pagination
                    shape="rounded"
                    color="primary"
                    count={pageCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChange={handleChangePage}
                  />{" "}
                </div>
              </>
            ) : (
              <Skeleton className="skeleton-format" variant="rectangular" height={200} />
            )}
          </div>
        }
      />
    </>
  );
}
