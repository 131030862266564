import React from "react";

import { Grid, Card } from "@material-ui/core";
import "../CardGenericoSearch/styles.css";
import { formatPrice, } from "../../../utils/functions";

export default function CardTotalDeflatores({ texto, valor }) {


  return (
    <>
      <Grid direction="row" item xs={6} sm={6}>
        <Card className="root-card2 card-total-deflatores" variant="outlined">

          <Grid container direction="row" spacing={1} >
            <div className="tamanho-card">
              <div className="topo-card-generico">
                <div >
                  <p className="title-card-2">{texto}</p>
                  <p className="value-1 m-t-10 ">{formatPrice(valor)}</p>
                </div>
              </div>

            </div>
          </Grid>

        </Card>
      </Grid>
    </>
  );
}
