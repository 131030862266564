import "./TablePosition.css";

import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { BiSolidCircle } from 'react-icons/bi';

export default function TablePosition({ data }) {
    return (
        <>
            {data.length > 0 && data.map(item => {
                return (
                    <div key={item.position} className="boxTablePosition">
                        <div className="divider" />
                        <div className="principalBox">
                            <div className="contentBox">
                                {
                                    item.situation === "up" ?   <AiFillCaretUp className="tablePositionIcon"/> : 
                                    item.situation === "down" ? <AiFillCaretDown className="tablePositionIcon"/> : 
                                                                <BiSolidCircle size={13} className="tablePositionIcon"/>
                                }
                                
                                <div className={"titlePositionNumber"}>
                                    {`${item.position}º`}
                                </div>

                                <h6 className="titleTablePosition">
                                    {item.brand}
                                </h6>
                            </div>

                            <p className="percentage">
                                {`${item.percentage || item.percentage === 0 ? (item.percentage).toFixed(2) : '0.00'} %`}
                            </p>
                        </div>
                    </div>
                )
            })}
        </>
    );

}