import * as React from 'react';
import { formatPrice } from '../../../utils/functions';

export default function RowScraping({ row }) {

  return (<>
    <tr>
      <td className=''> {row.ref}</td>
      <td className=''> {row.colecao}</td>
      <td className=''> {formatPrice(row.receita)}</td>
      <td className=''> <a target="_blank" href={row.url} > {row.url}</a></td>
    </tr >
  </>);
}
