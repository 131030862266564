import * as React from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";

export default function TriggersTooltips({ texto }) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const toggleTooltip = () => {
        setOpen(open ? false : true);
    };

    //     const handleTooltipOpen = () => {
    //     setOpen(open ? false : true);
    // };


    return (
        <div>
            {/* <Grid container justifyContent="center">
                <Grid item> */}
                    {/* <ClickAwayListener onClickAway={handleTooltipClose}> */}
                        {/* <div> */}
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={texto}
                            >
                                <MessageOutlinedIcon onClick={toggleTooltip} className="cursor" />
                            </Tooltip>
                        {/* </div> */}
                    {/* </ClickAwayListener> */}
                {/* </Grid>
            </Grid> */}
        </div>
    );
}