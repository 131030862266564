import React, { useState } from 'react';
import './ModalDatapicker2.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import "date-fns";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { CalendarMonthOutlined } from "@mui/icons-material";
import { getSessionStorage } from "../../../utils/sessionStorage";

function ModalDatapicker2({ salvarDataSessaoFoto, tab }) {
  const [date, setDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState({ day: null, month: null, year: null });
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);

  // const handleOpen = () => setOpen(true);

  const handleOpen = () => {
    if (tab == 1) {
      let storage = getSessionStorage(`refSelecionadasPacote_${getSessionStorage("user").marcaSelecionada}`);
      if (storage) {
        setOpen(true);
      }
    }
  }




  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const getDaysArray = (year, month) => {
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);
    const daysArray = [];

    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push({ day: i, selected: year === selectedDay.year && month === selectedDay.month && i === selectedDay.day });
    }

    for (let i = 0; i < firstDayOfMonth; i++) {
      daysArray.unshift(null);
    }

    while (daysArray.length < 31) {
      daysArray.push(null);
    }

    return daysArray;
  };

  const handleCloseIcon = () => {
    setOpen(false);
  };


  const prevMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
  };

  const nextMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
  };

  const daysArray = getDaysArray(date.getFullYear(), date.getMonth());

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];

  return (
    <div className='espacamento'>
      <CalendarMonthOutlined onClick={tab == 1 ? handleOpen : false} />

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Box className="modalFormat-picker">
          <div className='calendar'>
            <div className="fechar">
              <CloseRoundedIcon onClick={handleCloseIcon} />
            </div>
            <div className='header'>
              <button onClick={prevMonth} className='botao-seta'><ArrowBackIosNewRoundedIcon /></button>
              <h2>
                {monthNames[date.getMonth()]} {date.getFullYear()}
              </h2>
              <button onClick={nextMonth} className='botao-seta'><ArrowForwardIosRoundedIcon /></button>
            </div>
            <div className='weekdays'>
              <div>Dom</div>
              <div>Seg</div>
              <div>Ter</div>
              <div>Qua</div>
              <div>Qui</div>
              <div>Sex</div>
              <div>Sáb</div>
            </div>
            <div className='days'>
              {daysArray.map((day, index) => (
                day ? (
                  <div
                    key={index}
                    className={`day ${day && day.selected ? 'selected diaSelecionado' : ''}`}
                    onClick={() => setSelectedDay({ year: date.getFullYear(), month: date.getMonth(), day: day.day })}
                    data={`${date.getFullYear()}-${date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth()}-${day.day < 10 ? '0' + day.day : day.day} `}
                  >
                    {day && day.day}
                  </div>
                ) : (
                  <div key={index} className="day emptyDay" />
                )
              ))}
            </div>
            <div className='OK'>
              <Button variant="outlined" onClick={() => { setOpen(false), salvarDataSessaoFoto() }}>OK</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalDatapicker2;
