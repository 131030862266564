import * as React from 'react';
import { formatPrice, converter_data, habilitarBotoes, desabilitarBotoes, formataBotoes, filiaisEstudio } from '../../../utils/functions';
import "./Tabela.css"
import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../../../utils/sessionStorage";
import { useEffect } from "react";
import ModalGenerico from '../ModalGenerico/ModalGenerico';

export default function Row({ row }) {
  useEffect(() => {
    const sel = getSessionStorage(`refSelecionadasPacote_${getSessionStorage("user").marcaSelecionada}`);
    if (sel) {
      if (sel.includes(row.REF)) {
        document.getElementById(row.REF).setAttribute('checked', true)
        habilitarBotoes(1)
        formataBotoes("adicionar-estilo")
      }
    } else {
      desabilitarBotoes();
    }
  }, []);

  const selRefPacote = (e) => {
    const marca = getSessionStorage("user").marcaSelecionada;
    const chave = `refSelecionadasPacote_${marca}`
    const element = e.target;

    const value = element.getAttribute("value");
    let ret = null;
    let storage = getSessionStorage(chave);

    if (storage) {
      if (element.checked) {
        storage.push(value);
      } else {
        let index = storage.indexOf(value);
        storage.splice(index, 1);
        element.checked = false;
      }
      ret = storage.length == 0 ? '' : storage;
    } else {
      ret = [value];
    }

    if (ret) {
      setSessionStorage(chave, ret);
      habilitarBotoes(1)
      formataBotoes("adicionar-estilo")
    } else {
      removeSessionStorage(chave);
      desabilitarBotoes()
      formataBotoes("remover-estilo")
    }

  };

  let classeFoto = "borda-horizontal "
  switch (row.FOTO) {
    case 'SEM TRATAMENTO':
      classeFoto += "texto-vermelho";
      break
    case 'PRIORIDADE':
      classeFoto += "texto-laranja-2";
      break
    case 'UPLOAD ERRO':
      classeFoto += "texto-laranja";
      break
    case 'UPLOAD ENVIADAS':
      classeFoto += "texto-verde";
      break;
    default:
      classeFoto += "texto-preto";
      break;
  }

  const filial_estudio = filiaisEstudio(row.MARCA);

  return (
    <>
      <tr key={`${row.REF}_${row.VALOR_ESTOQUE}`}>
        <td className='referencia-linha'>
          <label className="custom-checkbox">
            <input type="checkbox" value={row.REF} id={row.REF} onClick={selRefPacote} />
            <span className="checkmark"></span>
            <div className='referencia-numero'>{row.REF} {row.COMENTARIO && <ModalGenerico titulo={'COMENTÁRIO'} texto={row.COMENTARIO} />}</div>
          </label>
        </td>
        <td> <div className="borda-primeira">{formatPrice(row.VALOR_ESTOQUE)}</div> </td>
        <td> <div className="borda-horizontal">{row.CANAL_AJUSTADO}</div> </td>
        <td> <div className="borda-horizontal white-space-no">{row.FILIAL == filial_estudio ? "ESTÚDIO" : row.FILIAL}</div> </td>
        <td> <div className={classeFoto}>{row.FOTO}</div> </td>
        <td> <div className="borda-horizontal">{converter_data(row.DATA_AGENDAMENTO_SESSAO_FOTO)}</div> </td>
        <td> <div className="borda-horizontal">{converter_data(row.DATA_ENVIO_EMAIL)}</div> </td>
        <td> <div className="borda-horizontal">{converter_data(row.data_programada)}</div> </td>
      </tr>

    </>
  );
}
