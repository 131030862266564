import React, { Component } from 'react'
import { Container } from '@mui/material';
import UserInfo from '../UserInfo/UserInfo';
import FormControl from "@material-ui/core/FormControl";
import { ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "./../../../pages/Gestao/Principal/principal.css";
import { useNavigate } from "react-router";
import SearchButton from "../SearchButton/Search";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { getSessionStorage, setSessionStorage } from "../../../utils/sessionStorage";
import { createTheme } from "@material-ui/core";
import BasicBreadcrumbs from "../../Breadcrumbs/Breadcrumbs";


export default function HeaderDesk({ title, setBusca, tipoBusca, setLoading, atualizado, tipoTela, enderecoVoltar, setDiretorioAtual, setModif, setPastasSelecionadas }) {

  const navigate = useNavigate();
  const marcas = getSessionStorage("user").marcasPermitidas;

  const theme = createTheme({
    palette: {
      primary: {
        main: "#e5e5e5 !important",
      },

    },
  });

  const handleClickItems = (marca) => {
    sessionStorage.removeItem('params')
    let obj = getSessionStorage("user");
    obj.marcaSelecionada = marca;
    setSessionStorage("user", obj);
    window.location.href = "";
  };

  
  return (
    <Container maxWidth="sm" className="">
      <div className="box-top-not">
        <div className="info-top">
          {tipoTela != 'PRINCIPAL' && (
            <div className="icone-superior">
              <ArrowBackIosRoundedIcon className="iconeAvancar icone-avancar-not " onClick={() => navigate(enderecoVoltar)} />
            </div>
          )}

          <div className={"info-top-2"}>
            <div className="info-top-2-not">
              <BasicBreadcrumbs diretorios={title} setDiretorioAtual={setDiretorioAtual} setModif={setModif} />
              <h6 className="sub-titulo-principal">Última atualização: {atualizado}</h6>
            </div>
          </div>
          <div className="info-top-3">
            <SearchButton setBusca={setBusca} setLoading={setLoading} tipoBusca={tipoBusca} setPastasSelecionadas={setPastasSelecionadas} />
            <UserInfo />

            <div className="m-l-20">
              <FormControl variant="outlined" className="select select-1" >
                <ThemeProvider theme={theme} >
                  <Select displayEmpty name="marcas" value={getSessionStorage("user").marcaSelecionada.toUpperCase()} >
                    {marcas.map((item) => {
                      return (<MenuItem key={item} value={item} onClick={() => handleClickItems(item)}> {item} </MenuItem>);
                    })}
                  </Select>
                </ThemeProvider>
              </FormControl>
            </div>
          </div>
        </div>
      </div>

    </Container >
  );
}





