import { useState, useEffect } from "react";
import InputMUI from "../../components/Gestao/Input/InputMUI";
import ButtonGenerico from "../../components/Gestao/Button/ButtonGenerico";
import "./login.css";
import imglogin from '../../../src/static/login-novo-2.png';
import imglogo from '../../../src/static/Logo_Grupo_Soma_branco.svg';
import { defaultRequest } from "../../utils/functions";
import { useNavigate } from "react-router";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


export default function Recuperar() {
  const navigate = useNavigate();

  const [textError, setTextError] = useState("");
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState('');
  const [validClass, setValidClass] = useState('false');

  const handleBack = (e) => {
    navigate("/");
  }

  const handleClick = (e) => {

    const email = document.querySelector("#usuario").value;

    if (email === "") {
      setError(true)
      setTextError("E-mail inválido");
      return;
    }

    setLoad(true);
    setError(false);

    const params = { email: email };
    defaultRequest('/recConta', params).then((response) => {

      navigate("/solicSuccess");

    }).catch((error) => {
      console.log(error)
      setLoad(false)
      setError(true)
      setTextError("Houve um erro interno");
    });

  };

  useEffect(() => {
    setTimeout(() => {
      let usr = window.getComputedStyle(document.getElementById('usuario'), null).getPropertyValue('appearance') == 'menulist-button'
      setValidClass((usr) ? true : false);
    }, 1000);
  }, []);


  return (

    <div className="container-rec" >

      <div className="pageImage">
        <img src={imglogin} className="loginImage" />
      </div>

      <div className="loginPage" >

        <ArrowBackIosNewIcon className="iconeVoltar" onClick={handleBack} />

        <h1 className="h1format-rec">FAROL </h1>
        <section className="sectionPattern">

          <h2 className="h2format-rec">Recuperação de senha</h2>
          <h3 className="h3format-rec">
            Digite seu e-mail corporativo para que sua solicitação seja enviada
            para nosso time.
          </h3>
          <InputMUI
            type={"text"}
            id={"usuario"}
            label={"E-mail"}
            variant={"outlined"}
            showPasswordIcon={false}
            error={error}
            field={user}
            setField={setUser}
            setValidClass={setValidClass}
          />

          {error && <h6 className="textError"> {textError}</h6>}
          <ButtonGenerico texto={"ENVIAR"} error={error} click={handleClick} load={load} ativo={(validClass) || (user) ? true : false} id={'btnLogin'} />
          <a className="voltarformat" href="./">
            Voltar para o login
          </a>

        </section>

        <img src={imglogo} className="logo" />
      </div>
    </div>

  );
}
