import './ButtonGenerico.css';

export default function ButtonGenerico({ texto, click, load, ativo, id, error = false }) {

  let classe = 'button ';
  if (error) {
    classe += "buttonError "
  };
  if (ativo) {
    classe += "button-active"
  };

  return (
    <button className={classe} onClick={click} id={id} disabled={load}>
      {load == true ? (< div className="loader"></div>) : (texto)}
    </button >
  );
}

