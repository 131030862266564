import "./modalDetalheProduto.css"
import React, { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function ModalDetalheProduto({ fotos, open, setOpen }) {

  fotos = fotos[0];
  const [idFoto, setIdFoto] = useState(0);
  const [foto, setFoto] = useState(fotos[0]);
  const qtdFotos = fotos.length;
  
  const handleClose = () => setOpen(false);

  const handleChangeFoto = (tipo) => {
    const idFotoAtual = parseInt(document.getElementById("idFoto").getAttribute('value'));
    const novoIdFoto = tipo == 1 ? idFotoAtual - 1 : idFotoAtual + 1;

    if (fotos[novoIdFoto]) {
      setFoto(fotos[novoIdFoto])
      setIdFoto(novoIdFoto);
    }
  };


  // <div className={`chip-card-foto ${chip} `}>{chip == "sucesso" ? "Enviada" : chip == "erro" ? "Com erro" : ""}</div>

  return (
    <>
      <Modal keepMounted open={open} onClose={handleClose} >
        <Box className="modal-detalhe-produto">
          <CloseRoundedIcon onClick={handleClose} className="cursor closeIconFotos" />

          <div className="box-setas-foto">
            < ArrowBackIosIcon onClick={() => handleChangeFoto(1)} className={`cursor ${idFoto == 0 ? "colorWhite" : ''}`} />
            <img className="img-detalhe-foto" src={foto.url} id="idFoto" value={idFoto} />
            < ArrowForwardIosIcon onClick={() => handleChangeFoto(2)} className={`cursor ${idFoto == (qtdFotos - 1) ? "colorWhite" : ''}`} />
          </div>

          <p className="titulo-modal-detalhe-foto" >{foto.nome}</p>
        </Box>
      </Modal >
    </>
  );
}