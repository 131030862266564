import React from "react";

export default function LinearWithValueLabel({ progresso, part, total }) {

    progresso = `${progresso}%`;
    return (
        <div className="box-progresso">
            <div style={{ width: "100%" }}>
                <div style={{ backgroundColor: "#D9D9D9", borderRadius: "40px" }}>
                    <div id="myBar" style={{ height: "8px", width: progresso, backgroundColor: "#DCC6B7", borderRadius: "40px" }}></div>
                </div>
            </div>
            <div style={{ width: "15%", textAlign: "right" }}> {`${progresso} (${part}/${total})`}  </div>
        </div>
    );
}