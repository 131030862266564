import "./modalFotos.css"
import React, { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function ModalPastaFotoMobile({ openModalPastaFotoMobile, setOpenModalPastaFotoMobile, setOpenModalPasta, setOpenModalFotos }) {

  const handleClose = () => { setOpenModalPasta(false); setOpenModalFotos(false) }

  return (<>
    <Modal keepMounted open={openModalPastaFotoMobile} onClose={handleClose} >
      <Box className="card-modal">
        <div className='closeIcon'>
          <div className='title-modal'></div>
          <CloseRoundedIcon onClick={handleClose} className="cursor" />
        </div>

        <div className='body-modal-excluir'>
          <p className="msg-confirmacao m-t-16" > O que você deseja adicionar ? </p>

          <div className='footer-btn-modal-pasta-foto'>
            <a className='btn-modal-confirmacao btn-pasta-foto' onClick={() => { setOpenModalPasta(true); setOpenModalPastaFotoMobile(false) }}><CreateNewFolderOutlinedIcon style={{ marginRight: "4px" }} />Nova Pasta</a>
            <a className='btn-modal-confirmacao btn-pasta-foto' onClick={() => { setOpenModalFotos(true); setOpenModalPastaFotoMobile(false) }}><UploadFileOutlinedIcon style={{ marginRight: "4px" }} />Upload de Imagem</a>
          </div>
        </div>

      </Box>
    </Modal >
  </>);
}