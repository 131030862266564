import ButtonGenerico from "../../components/Gestao/Button/ButtonGenerico";
import "./login.css";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
import { Grid } from "@material-ui/core";
import imglogin from '../../../src/static/login-novo-2.png';
import imglogo from '../../../src/static/Logo_Grupo_Soma_branco.svg';
import * as React from "react";
import InputMUI from "../../components/Gestao/Input/InputMUI.js";



export default function Login() {
  const { login } = useContext(UserContext);
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [textError, setTextError] = useState("");
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);
  const [validClass, setValidClass] = useState('false');

  const handleClick = (e) => {
    login(user, pass, setError, setLoad, setTextError);
  };

  useEffect(() => {
    setTimeout(() => {
      let usr = window.getComputedStyle(document.getElementById('usuario'), null).getPropertyValue('appearance') == 'menulist-button'
      let psw = window.getComputedStyle(document.getElementById('senha'), null).getPropertyValue('appearance') == 'menulist-button'
      setValidClass((usr && psw) ? true : false);
    }, 1000);
  }, []);
  
   

  return (

    <div className="containerlogin" >

      <Grid container direction="row" justifyContent="flex-start" alignItems="center" >
        <Grid item md={6} className="pageImage" >
          <img src={imglogin} className="loginImage" />
        </Grid>

        <Grid item md={6} className="loginPage">
          <h1 className="h1format">FAROL </h1>
          <section className="sectionPattern">
            <div>
              <InputMUI
                type={"text"}
                id={"usuario"}
                label={"E-mail"}
                variant={"outlined"}
                showPasswordIcon={false}
                error={error}
                field={user}
                setField={setUser}
                setValidClass={setValidClass}
                />
              <InputMUI
                type={"password"}
                id={"senha"}
                label={"Senha"}
                variant={"outlined"}
                showPasswordIcon={true}
                error={error}
                field={pass}
                setField={setPass}
                setValidClass={setValidClass}
              />
            </div>
            {error && <div className="textError"> {textError}</div>}
            <ButtonGenerico texto={"ENTRAR"} error={error} click={handleClick} load={load} ativo={(validClass) || (user && pass) ? true : false} id={'btnLogin'} />
            
            <a className="aformat" href="./Reccount">
              Esqueci a Senha
            </a>
          </section>

          <img src={imglogo} className="logo" />

        </Grid>
      </Grid>

    </div>

  );
};
