import React, { useState } from "react";
import "./modalConfirmacao.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import imgConfirmacaoExcluir from '../../../static/confirmacao_excluir.png';
import { toast } from 'react-toastify';
import { getSessionStorage, removeSessionStorage } from "../../../utils/sessionStorage";
import {  id_marca, diretorioOrigem, buscaGeral } from "../../../utils/functions";
import axios from "axios";
import conf from "../../../utils/.params.js";

export default function ModalConfirmacao({ setModif, pastasSelecionadas, termo, setPastasSelecionadas, setBusca, setLoading, tipoBusca }) {

  const [open, setOpen] = useState(false);
  const user = getSessionStorage("user")
  const marca = (user.marcaSelecionada).replace(/ /g, "");

  const handleClose = () => setOpen(false);

  const validacaoAbrirModal = async () => {
    let fotos = getSessionStorage(`fotos_selecionadas_${marca}`)
    if (fotos && fotos.length > 0 || pastasSelecionadas.length > 0) {
      setOpen(true)
    }
  };
  
  const handlerExcluirFotos = async () => {
    try {

      let sucesso = [];
      let erro = [];
      setOpen(false)

      let fotos = getSessionStorage(`fotos_selecionadas_${marca}`)
      fotos = fotos ? fotos : [];

      // so pra garantir
      if ((!fotos || fotos.length == 0) && (pastasSelecionadas.length == 0)) { return; }

      toast.loading("Aguarde um pouco...")

      const itens = fotos.concat(pastasSelecionadas);

      for (let it of itens) {
        const status = await diretorioOrigem(it)
        it = it.hasOwnProperty('path') ? it.path : it;

        const rota = conf.url_fotos() + `/pictures/${await id_marca(marca)}?status=${status}`
        let config = { method: 'delete', url: rota, headers: { Authorization: "Bearer " + user.tokenFarol }, data: { "item": it } };
        await axios.request(config).then(result => {
          result ? sucesso.push(it) : erro.push(it);

        }).catch(error => { erro.push(it) });
      }

      toast.dismiss();
      if (sucesso.length > 0) {
        toast.success(`Itens deletados com sucesso!`);
      } else if (erro) {
        toast.warning(`Alguns itens não foram excluídos!`);
      }

      if (termo) {
        removeSessionStorage(`fotos_selecionadas_${marca}`)
        setPastasSelecionadas([])
        await buscaGeral(termo, setPastasSelecionadas, setBusca, setLoading, tipoBusca)
      } else {
        setModif(Math.random(9999))
      }

    } catch (error) {
      console.log(error.message)
      toast.error(`Houve um erro interno!`);
    }
  };

  return (<>
    <div className="cursor" onClick={validacaoAbrirModal}>
      <DeleteForeverOutlinedIcon className="cursor display-flex" />
    </div>
    <Modal keepMounted open={open} onClose={handleClose} >
      <Box className="card-modal">
        <div className='closeIcon'>
          <div className='title-modal'></div>
          <CloseRoundedIcon onClick={handleClose} className="cursor" />
        </div>

        <div className='body-modal-excluir'>
          <img className="img-modal-excluir" src={imgConfirmacaoExcluir} />
          <p className="msg-confirmacao" > Você tem certeza de que deseja excluir esses itens ? </p>

          <div className='footer-btn-modal-excluir'>
            <div className="btn-modal-confirmacao btn-modal-cancelar" onClick={handleClose}>Cancelar </div>
            <div className="btn-modal-confirmacao btn-modal-excluir" onClick={handlerExcluirFotos}>Excluir  </div>
          </div>
        </div>

      </Box>
    </Modal >
  </>
  );
}