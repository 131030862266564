import { useState } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid } from "@material-ui/core";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import "./ButtonModal.css"
import { selTodasReferencias, defaultRequest, toastRetorno, cardTodosProdutosOfertados, atualizarHtml } from "../../../utils/functions";
import CardGenericoSearch from "../CardGenericoSearch/CardGenericoSearch";
import CardDeflatores from "../CardDeflatores/CardDeflatores";
import { Skeleton } from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { getSessionStorage } from "../../../utils/sessionStorage";
import CardOffer from "../../../components/Gestao/CardOffer/CardOffer";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import CardTotalDeflatores from "../../../components/Gestao/CardTotalDeflatores/CardTotalDeflatores";


export default function ModalReferencias({ open, setReferencias, referencias, titleModal, setOpenModal, id, totalDeflatorMarca, totalDeflatorSeller }) {

  const useStyles = makeStyles({ root: { "&:hover": { backgroundColor: "#fff !important", }, }, default: { backgroundColor: "inherit !important", }, });
  const classes = useStyles();
  const [valorSelectOrdenacao, setValorSelectOrdenacao] = useState('');
  const [referenciasOriginal, setReferenciasOriginal] = useState(null);
  
  // PARA APAGAR REFERENCIAS SELECIONADAS NO MODAL DE NÃO VISIVEL QUANDO O MESMO FOR ABERTO
  sessionStorage.removeItem(`refSelecionadasVisivel_${getSessionStorage("user").marcaSelecionada}`)

  
  const handleClose = async () => {
    setOpenModal(false)
    setValorSelectOrdenacao('')
    document.getElementById("cardOffer").classList.add('displayNone');
    document.getElementById("modal_referencias").classList.remove('displayNone');
    const todasRefsEl = document.getElementById('checkbox-todas-referencias');

    if (todasRefsEl && todasRefsEl.checked) { todasRefsEl.click() }

  }

  const handdleChangeOrdenacao = async (e) => {
    e.preventDefault()

    if (e.target.value) {
      setReferencias(null)
      setValorSelectOrdenacao('')

      let foo = await referencias.sort(function (a, b) {
        let valorA = a.ESTOQUE * a.PRECO_ECOMMERCE;
        let valorB = b.ESTOQUE * b.PRECO_ECOMMERCE;

        if (e.target.value == 'maior') {
          return valorB - valorA;
        } else if (e.target.value == 'menor') {
          return valorA - valorB;
        }
      });

      setValorSelectOrdenacao(e.target.value)
      setReferencias(foo)
    }
  }

  const buscar = (termo) => {
    if (!referenciasOriginal) {
      setReferenciasOriginal(referencias)
    }
    let foo = referencias.filter((item) => {
      return item.REF == termo;
    })
    setReferencias(foo);
  }

  const handleSearch = (e) => {

    let termo = e.target.value;
    if (termo.length > 5) {
      setReferencias(false)
      buscar(termo)
    } else if (termo.length == 0) {
      setReferencias(referenciasOriginal)
    }
  };

  const tornarVisivel = (e) => {
    e.preventDefault()

    const user = getSessionStorage("user");
    const refs = sessionStorage.getItem(`refSelecionadasVisivel_${user.marcaSelecionada}`);

    if (refs) {

      const idToast = toast.loading("Aguarde um pouco...")

      defaultRequest('/gestao/integracao/produtosVisivel', { referencias: JSON.parse(refs), usuario: user.email }).then(function (response) {
        toast.dismiss(idToast);
        if ((JSON.parse(refs).length == referencias.length) && (response.error.length == 0)) {
          toast.success(`Referências aprovadas com sucesso!`);
          cardTodosProdutosOfertados();
          setReferencias([])
        } else {
          toastRetorno(response);
          atualizarHtml(referencias, setReferencias, response);
        }

        selTodasReferencias(false, 'input-check-visivel', 'refSelecionadasVisivel', 'btn-tornar-visivel')
        const btn_todas = document.getElementById(`checkbox-todas-referencias`);
        btn_todas.checked ? btn_todas.click() : false;

      }).catch((error) => {
        console.log(error.message)
        toast.update(idToast, { render: 'Houve um erro interno!', type: "error", isLoading: false, autoClose: 3000 });
      });
    }
  };


  return (
    <div>
      <Modal keepMounted open={open} onClose={handleClose} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description"  >
        <Box className="modalFormat modalFormat-referencias">
          <div className='top-box'>
            <div className="close-modal">
              <p className="ref-title"> {titleModal} </p>
              <CloseRoundedIcon onClick={handleClose} className="cursor" />
            </div>

            <div className="title-box-2">
              <div className='search-div'>
                <button type="submit" className="button-farol-ref" >
                  <SearchRoundedIcon id="searchIcon" className="search-icon-ref" />
                  <input type="text" className="input-farol-ref" name="search" placeholder="Digite a referência" id="inputBuscaModal" onKeyUp={handleSearch} />

                </button>
              </div>
              <div className="box-select-ord-modal">

                <div className="select-title">Ordenar por: </div>
                <FormControl variant="outlined" className="select" >
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handdleChangeOrdenacao}
                    name="ordenacao"
                    value={valorSelectOrdenacao}
                  >
                    <MenuItem className={classes.default} value=""> Sem Ordenação </MenuItem>
                    <MenuItem className={classes.root} value='maior'>Maior Valor</MenuItem>
                    <MenuItem className={classes.root} value='menor'>Menor Valor</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {titleModal == "Indisponibilidade devido a deflator" &&
              <>
                {!referencias ? (<Skeleton className="skeleton-format" variant='rounded' height={'80vh'} />) : (<>
                  <Grid className="m-t-5" container direction="row" justifyContent="flex-start" alignItems="center" id="modal_referencias" spacing={1}>
                    <CardTotalDeflatores texto={"Receita total deflator marca"} valor={totalDeflatorSeller} />
                    <CardTotalDeflatores texto={"Receita total deflator seller"} valor={totalDeflatorMarca} />
                  </Grid>

                </>)}
                <div class="diviver-deflatores"></div>
              </>
            }

            {titleModal == "Não visível" &&
              <>
                <div className="checkbox-todas-referencias">
                  <label className="custom-checkbox">
                    <input type="checkbox" id="checkbox-todas-referencias" onClick={(e) => { selTodasReferencias(e, 'input-check-visivel', 'refSelecionadasVisivel', 'btn-tornar-visivel') }} />
                    <span className="checkmark"></span>
                    <span className="texto-todas-referencias" id="total_itens" value={referencias ? referencias.length : 0}> ({referencias ? referencias.length : 0})</span>
                  </label>
                  <div style={{ width: "100%" }} >

                    <button onClick={tornarVisivel} className="btn-tornar-visivel btn-float-right" id="btn-tornar-visivel">TORNAR VISÍVEL</button>
                  </div>
                </div>

                <div className="divider-top-modal">
                </div>
              </>
            }
          </div>

          <CardOffer id={'cardOffer'} texto={"Não há referências com o status de não visível"} />

          <Grid container direction="row" justifyContent="flex-start" alignItems="center" id="modal_referencias" spacing={1}>
            {!referencias ? (<Skeleton className="skeleton-format" variant='rounded' height={'80vh'} />) : (<>
              {referencias.map((iten) => {

                if (titleModal == "Indisponibilidade devido a deflator") {
                  return <CardDeflatores iten={iten}  tipo={titleModal} />
                } else {
                  return <CardGenericoSearch iten={iten} isLoading={false} tipo={titleModal} />
                }

              })}
            </>)}
          </Grid>
        </Box>
      </Modal>

    </div >
  );
}