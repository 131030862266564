import React, { useState, useEffect } from "react";
import "./BuscaFotos.css"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


import FotosProduto from "../../../components/Fotos/FotosProduto/FotosProduto";
import { handleClickTodasFotos, handlerDonwloadFotos, buscaGeral, agruparProdutosFotos, qtdSelecionadas } from "../../../utils/functions";
import DiretorioBusca from "../../../components/Fotos/DiretorioBusca/DiretorioBusca";
import DropMoverFotos from "../DropMoverFotos/DropMoverFotos";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Skeleton } from "@mui/material";
import ModalConfirmacao from "../../../components/Fotos/ModalFotos/ModalConfirmacao";
import Checkbox from "../../../components/Checkbox/Checkbox";
import EditarNome from "../../../components/Fotos/EditarNome/EditarNome";

export default function BuscaFotos({ handlerOpenModalDetalheFoto, retBusca, setDiretorioAtual, setBusca, setLoading, isLoading }) {

  const [fotos, setFotos] = useState(false);
  const [diretorios, setDiretorios] = useState(false);
  const [stateRefSelecionadas, setStateRefSelecionadas] = useState(false);
  const [pastasSelecionadas, setPastasSelecionadas] = useState([]);
  const [modif, setModif] = useState(false);


  useEffect(async () => {
    // setDiretorioAtual([retBusca.termoBusca])
    setFotos(await agruparProdutosFotos(retBusca.dados.pictures))
    setDiretorios(retBusca.dados.paths);
    setLoading(true)
  }, []);

  return (<>

    <div className="card-fotos-generico-barra-acao">
      <div className={`p-l-0  card-fotos-generico-2 card-fotos-generico-3 ${stateRefSelecionadas || (stateRefSelecionadas > 0) || (pastasSelecionadas.length > 0) ? 'card-fotos-generico-ativo' : ''}`}>
        <div className={`cursor ${stateRefSelecionadas || pastasSelecionadas.length > 0 ? 'text-black display-flex' : 'displayNone'}`}>
          <CloseOutlinedIcon className="m-l-5" onClick={() => buscaGeral(retBusca.termoBusca, setPastasSelecionadas, setBusca, setLoading, "FOTOS")} />
          <p className="m-l-5 card-fotos-generico-titulo">{qtdSelecionadas(stateRefSelecionadas, pastasSelecionadas)}</p>
          <p className="m-l-5 card-fotos-generico-titulo">{qtdSelecionadas(stateRefSelecionadas, pastasSelecionadas) ? "Selecionados" : "Selecionado"}</p>
        </div>

        <div className={`card-fotos-generico-barra-acao-icones ${qtdSelecionadas(stateRefSelecionadas, pastasSelecionadas) > 0 ? 'text-black' : 'displayNone'}`}>
          <FileDownloadOutlinedIcon className="cursor" onClick={() => handlerDonwloadFotos(pastasSelecionadas)} />
          <DropMoverFotos termoBusca={retBusca.termoBusca}
            tipoTela={"Busca"}
            pastasSelecionadas={pastasSelecionadas}
            setPastasSelecionadas={setPastasSelecionadas}
            stateRefSelecionadas={stateRefSelecionadas}
            setStateRefSelecionadas={setStateRefSelecionadas}
            setModif={setModif}
            setBusca={setBusca}
            setLoading={setLoading}
          />

          <ModalConfirmacao
            setModif={setModif}
            pastasSelecionadas={pastasSelecionadas}
            termo={retBusca.termoBusca}
            setPastasSelecionadas={setPastasSelecionadas}
            setBusca={setBusca}
            setLoading={setLoading}
            tipoBusca={"FOTOS"}
          />
          <EditarNome busca={true} setStateRefSelecionadas={setStateRefSelecionadas} stateRefSelecionadas={stateRefSelecionadas} pastasSelecionadas={pastasSelecionadas} setPastasSelecionadas={setPastasSelecionadas} setModif={setModif} />
        </div>
      </div>

    </div>


    <div className="card-fotos-generico">

      {!isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={250} />) : (<>

        {diretorios.length > 0 &&
          <DiretorioBusca
            diretorios={diretorios}
            setPastasSelecionadas={setPastasSelecionadas}
            pastasSelecionadas={pastasSelecionadas}
          />
        }

        {fotos && diretorios.length > 0 && <div className="card-fotos-generico-divider" />}

        {fotos && <>
          {/* <label className="m-0 display-flex m-b-40"> <Checkbox click={handleClickTodasFotos} classe={"todas_fotos"} value={`todos_produtos_linha_busca`} />
              <p className="m-l-5 card-fotos-generico-titulo" >Todas:</p>
              <p className="m-l-5 card-fotos-generico-titulo text-gray">({fotos.qtdFotos ? fotos.qtdFotos : 0} {fotos.qtdFotos > 1 ? "fotos" : "foto"})</p>
            </label> */}
          {fotos.imagens.map((item) => {
            return <FotosProduto
              retBusca={retBusca}
              diretorios={item.url}
              fotos={item}
              setStateRefSelecionadas={setStateRefSelecionadas}
              tela={'busca'}
              classe={`todos_produtos_linha_busca`}
              handlerOpenModalDetalheFoto={handlerOpenModalDetalheFoto}
            />
          })}
        </>}

      </>)}
    </div>


  </>)
}

