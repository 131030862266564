import React from "react";
import { Grid, Card } from "@material-ui/core";
import "./styles.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../Gestao/Icon/CalendarIcon";
import { useNavigate } from "react-router";
import { formatPrice, converter_data } from "../../../utils/functions";

export default function CardGenericoEstudio({ iten }) {
  let iconeCalendario = true;
  let data = '';

  const navigate = useNavigate();
  let porcentagem = iten.sem_foto == 0 ? 0 : ((iten.sem_foto * 100) / iten.qtd);
  let pacote = iten.PACOTE;
  let nomePacote = pacote;

  if (pacote.indexOf("Pacote") != -1) {

    nomePacote = "Pacote";

  } else if (pacote.indexOf(" - ") != -1) {
    let foo = pacote.split(' ');
    nomePacote = `${foo[0]} - ${foo[2]}`;
    data = foo[3];
    iconeCalendario = false;
  } else {
    data = converter_data(iten.data_programada);
  }

  const avancarPacote = async (pacote) => {
    sessionStorage.setItem('pacote', pacote)
    navigate("/estudio/pacote");
  };

  return (

    <Grid direction="row" item xs={12} md={3} onClick={() => avancarPacote(pacote)} >
      < div className="card-pacote-total cursor" >
        <Card className="root-card-estudio" variant="outlined">
          <div className="grid-icon-estudio cursor" name={iten.PACOTE}  >

            <div className="title-card-estudio">
              <Card className="title-text">{nomePacote}</Card>
            </div>
            <div className="icone-estudio"> <ArrowForwardIosRoundedIcon style={{ fontSize: "medium" }} /> </div>
          </div>
          <div className="calendar-format">
            {iconeCalendario ? <CalendarIcon /> : ''}
            <div className="data-pacote">{data}</div>
            {iten.prioridade ? <div className="chip-prioridade">{iten.prioridade} {iten.prioridade > 1 ? "Prioridades" : "Prioridade"}</div> : ""}
          </div>

          <label name={iten.id} className="cursor" >
            <div className="card-divider-estudio">
              <div className="container-divider-estudio">
                <Grid item>
                  <div className="pecas">{iten.qtd} Peças</div>
                  <div className="valor-pecas">{formatPrice(iten.VALOR_ESTOQUE)}</div>
                </Grid>
                <Grid item>
                  <div className="divider-estudio" />
                </Grid>
                <div className="text-end">
                  <div>
                    <div className="pecas-no">{iten.sem_foto} Peças não fotografadas</div>
                    <div className="valor-pecas-no">{formatPrice(iten.sem_foto_receita)}</div>
                  </div>
                  <div className="chip-estudio chip-estudio-red chip-estudio-tamanho">{`${porcentagem.toFixed(2)}%`}</div>
                </div>
              </div>
            </div>
          </label>
        </Card>
      </div>
    </Grid >

  );
}
