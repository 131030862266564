import React from "react";

import PaginaFotos from "../../../components/Fotos/PaginaFotos/index";


export default function SemTratamento() {

    return (<> <PaginaFotos titulo={"Sem tratamento"} diretorio={"sem_tratamento"} /> </>);

}
