import React from "react";
import { useContext } from "react";
import ButtonModal from "../Modal/ButtonModal";
import { Grid, Card } from "@material-ui/core";
import { transformTextModal, transformTitleModal } from "../../../utils/functions";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Skeleton } from "@mui/material";
import "./styles.css";
import { useNavigate } from "react-router";
import { UserContext } from "../../../contexts/UserContext";
import SendGTAGEvent from "../../../utils/sendGTAGEvent";

export default function CardGenericoMarket({ id, refs, SKU, Income, isLoading, tamanho, acaoDoCard }) {
  const textModal = transformTextModal();
  const titletModal = transformTitleModal();
  const navigate = useNavigate();
  const { email } = useContext(UserContext);


  const acao_do_card = () => {
    if (acaoDoCard == 'tela_ofertados') {
      SendGTAGEvent("card_ofertado_marketplace", "principal", "click", email);
      navigate("/gestao/ofertados?id=marketplace")
    } else if (acaoDoCard == 'nao_ofertados_marketplace') {
      SendGTAGEvent("card_nao_ofertado_marketplace", "principal", "click", email);
      navigate("/gestao/produtosMarketplace")
    }
  };


  return (
    <>
      <Grid direction="row" item xs={12} md={tamanho}>
        <Card className="root-card-1 tamanho-2" variant="outlined">
          {isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={100} />) : (<>

            {/* <div className="grid-icon cursor" name={id} onClick={acao_do_card} ></div> */}

            <div className="section-2-card-generic-market">
              <div className='title2-section'>
                <section className="title2"> {titletModal[id]}</section>
                <ButtonModal
                  titleModal={titletModal[id]}
                  textModal={textModal[id]}
                />
              </div>

              <div className="iconeAvancar" onClick={acao_do_card}>
                <ArrowForwardIosRoundedIcon />
              </div>
            </div>

            <label onClick={acao_do_card} name={id} className="cursor footer-card"
            >
              <div className="card-divider">
                <div className="container-divider">
                  <Grid className="m-t-10" item xs={12} sm={5} md={4}>
                    <div className="title-card">Qtd. de Referências:</div>
                    <div className="value-card">{refs}</div>
                  </Grid>
                  <Grid item md={1}>
                    <div className="divider-3" />
                  </Grid>
                  <Grid className="m-t-10" item xs={12} sm={5} md={4}>
                    <div className="title-card">Qtd. de SKUs:</div>
                    <div className="value-card">{SKU}</div>
                  </Grid>
                  <Grid item md={1}>
                    <div className="divider-3" />
                  </Grid>
                  <Grid className="m-t-10" item xs={12} sm={5} md={7}>
                    <div className="title-card">Receita:</div>
                    <div className="value-card">{Income}</div>
                  </Grid>
                </div>
              </div>
            </label>
          </>)}
        </Card>
      </Grid>
    </>
  );
}
