import React, { useState } from "react";
import "./checkbox.css"
import CheckIcon from '@mui/icons-material/Check';


export default function Checkbox({ click, value, classe, name, id, url, tela = false, qtdFotos = false }) {
    const [checked, setChecked] = useState(false);
    value = value ? value : '';
    tela = tela ? tela : '';


    const selecionar = (e) => {
        setChecked(e.target.checked)
        click(e);
    }

    return (

        <label className="label-checkbox">
            <input type="checkbox" id={id} name={name} value={value} qtdFotos={qtdFotos ? qtdFotos : ''} className={classe ? classe : ''} url={url} onChange={(e) => selecionar(e)} />
            <span id={`${value}${tela}_not_check`} className={`${checked ? "displayNone" : ""} checkbox`}></span>
            <CheckIcon id={`${value}_check`} className={`${checked ? "" : "displayNone"} icone-check`} />
        </label>
    )
}

