import { useState, useEffect } from "react";
import * as React from "react";
import "./naoOfertados.css";
import CardGenerico from "../../../components/Gestao/CardGenerico/CardGenerico";
import { Typography, Grid } from "@material-ui/core";
import Container from "@mui/material/Container";
import Grafico from "../../../components/Gestao/Grafico/index";
import { defaultRequest, formatPrice, montarTelaNaoOfertados, handleClickTab } from "../../../utils/functions";
import { useNavigate } from "react-router";
import { Skeleton } from "@mui/material";
import ScrollSelect from "../../../components/Gestao/ScrollSelect/ScrollSelect";
import ModalReferencias from "../../../components/Gestao/Modal/ModalReferencias";
import MenuFarolDeskMobile from "./../../../components/Gestao/MenuFarol/MenuDeskMobile";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import CardSearch from "../../../components/Gestao/CardSearch/CardSearch";
import CardOfertadosNaoOfertados from "../../../components/Gestao/CardOfertadosNaoOfertados/CardOfertadosNaoOfertados";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";


export default function NaoOfertados() {
  // const textModal = transformTextModal();
  const [params, setParams] = useState({ filtro_lancados: false, filtro_colecao: false, });
  const [dados, setDados] = useState(false);
  const route = "/dadosGestao";
  const [isLoading, setLoading] = useState(true);
  const [referencias, setReferencias] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [busca, setBusca] = useState(false);
  const navigate = useNavigate();
  const [totalDeflatorSeller, setTotalDeflatorSeller] = useState(0);
  const [totalDeflatorMarca, setTotalDeflatorMarca] = useState(0);


  useEffect(() => {
    defaultRequest(route, params).then(function (response) {
      montarTelaNaoOfertados(response, setDados, setLoading);
    }).catch((error) => { });
  }, []);

  const handleOpenModal = (atributo, titulo) => {
    document.getElementById('inputBuscaModal').value = '';
    setTitleModal(titulo);
    setReferencias(false);
    setOpenModal(true);
    defaultRequest("/filtroProdutos", { atributo: atributo })
      .then(function (response) {
        setReferencias(response);
        setLoading(false);

        if (titulo == "Indisponibilidade devido a deflator") {
          let totalSeller = 0
          let totalMarca = 0

          response.forEach(element => {
            if (element.deflator_marca) {
              totalMarca += element.valor_em_estoque;
            } else {
              totalSeller += element.valor_em_estoque;
            }
          });

          setTotalDeflatorMarca(totalMarca)
          setTotalDeflatorSeller(totalSeller)
        }

      })
      .catch((error) => {
        console.log(error.message);
        return;
      });
  };

  return (
    <>
      <MenuFarolDeskMobile
        title={<HeaderDesk atualizado={dados.atualizado} setBusca={setBusca} tipoBusca={"GESTAO"} setLoading={setLoading} title={"Estoque não ofertado"} enderecoVoltar={"/gestao"} />}
        body={
          <Container maxWidth="sm" className="container">
            <InfoMenuMobile title={"Estoque não ofertado"} setBusca={setBusca} tipoBusca={"GESTAO"} setLoading={setLoading} atualizado={dados.atualizado} tipoTela={true} enderecoVoltar={"/gestao"} />
            <div className="divider-top" />

            {!busca ? (<>

              <div className="box-top">
                <ScrollSelect colecoes={dados.colecoes} params={params} setParams={setParams} setDados={setDados} setLoading={setLoading} tela={"naoOfertados"} route={route} pacotes={dados.pacotes} />
              </div>

              <div className="tab-box-total">
                <div className="box">
                  <Grid container >
                    <Grid xs={12} md={6} container>
                      <Grid container alignItems="center" className="box-individual box-individual-not"  >
                        {isLoading ? (<Skeleton className="skeleton-format" variant="rounded" sx={{ minHeight: 'inherit', Height: 'inherit' }} />) : (<>
                          <div style={{ display: "flex", flexDirection: "column", }} >
                            <Typography className="titulo-grafico2"> Total de estoque não ofertado </Typography>
                            <div className="graph-sku">
                              <Grafico part1={dados.catalogoPorcentagem} part2={dados.operacaoPorcentagem} tipoTela={2} />

                              <div className="div-sku">

                                <div className="white-space-2">
                                  <Typography className="sub-titulo-1"> Qtd. de Referências</Typography>
                                  <Typography className="sub-titulo-2"> {dados.qtdRefsTotal} </Typography>
                                </div>

                                <div className="white-space-2">
                                  <Typography className="sub-titulo-1"> Qtd. de SKU</Typography>
                                  <Typography className="sub-titulo-2"> {dados.qtdSkusTotal} </Typography>
                                </div>

                                <div className="white-space-2">
                                  <Typography className="sub-titulo-1"> Receita </Typography>
                                  <Typography className="sub-titulo-2"> {formatPrice(dados.receitaTotal)} </Typography>
                                </div>
                              </div>

                            </div>
                          </div>
                        </>
                        )}
                      </Grid>
                    </Grid>

                    <Grid xs={12} md={6} container>
                      <CardOfertadosNaoOfertados porcentagem={dados.catalogoPorcentagem} qtd={dados.catalogo} qtdRefs={dados.catalogoRefs} valor={dados.catalogoReceita} cor={"orange"} texto={"Catálogo"} isLoading={isLoading} />
                      <div className="divider-2" />
                      <CardOfertadosNaoOfertados porcentagem={dados.operacaoPorcentagem} qtd={dados.operacao} qtdRefs={dados.operacaoRefs} valor={dados.operacaoReceita} cor={"purple"} texto={"Operação"} isLoading={isLoading} />
                    </Grid>
                  </Grid>
                </div>

                <Grid container className="box-tab-pai" >
                  {!dados ? (<Skeleton className="skeleton-format skeleton-nao-ofertados" variant="rectangular" />) : (<>
                    <div className="tab">
                      <button name="todos" className="tablinks active" onClick={(e) => handleClickTab(e)} >
                        Todos ({dados.dadosOrdenados.length})
                      </button>
                      <button name="catalogo" className="tablinks" onClick={(e) => handleClickTab(e)} >
                        Catalogo ({dados.dadosOrdenadosCatalogo.length})
                      </button>
                      <button name="operacao" className="tablinks" onClick={(e) => handleClickTab(e)} >
                        Operação ({dados.dadosOrdenadosOperacao.length})
                      </button>
                    </div>

                    <div className="tab-box">
                      <div id="todos" className="tabcontent" style={{ display: "flex" }} >
                        <Grid container spacing={1} >
                          {dados.dadosOrdenados.map((iten) => {
                            return (
                              <CardGenerico
                                id={iten.nome}
                                titulo={iten.titulo}
                                refs={iten.qtdRef}
                                SKU={iten.qtd}
                                Income={formatPrice(iten.receita)}
                                badge={iten.badge}
                                badgeText={iten.badgeText}
                                isLoading={!dados}
                                handleOpenModal={handleOpenModal}
                              />
                            );
                          })}
                        </Grid>
                      </div>

                      <div id="catalogo" className="tabcontent" style={{ display: "none" }} >
                        <Grid container spacing={1}>
                          {dados.dadosOrdenadosCatalogo.map((iten) => {
                            return (
                              <CardGenerico
                                id={iten.nome}
                                titulo={iten.titulo}
                                refs={iten.qtdRef}
                                SKU={iten.qtd}
                                Income={formatPrice(iten.receita)}
                                badge={iten.badge}
                                badgeText={iten.badgeText}
                                isLoading={!dados}
                                handleOpenModal={handleOpenModal}
                              />
                            );
                          })}
                        </Grid>
                      </div>

                      <div id="operacao" className="tabcontent" style={{ display: "none" }} >
                        <Grid container spacing={1} >
                          {dados.dadosOrdenadosOperacao.map((iten) => {
                            return (
                              <CardGenerico
                                id={iten.nome}
                                titulo={iten.titulo}
                                refs={iten.qtdRef}
                                SKU={iten.qtd}
                                Income={formatPrice(iten.receita)}
                                badge={iten.badge}
                                badgeText={iten.badgeText}
                                handleOpenModal={handleOpenModal}
                                isLoading={!dados}
                              />
                            );
                          })}
                        </Grid>
                      </div>
                    </div>
                  </>
                  )}
                </Grid>
              </div>
            </>) : (<>
              <div className="white-space-2" />
              <Grid container spacing={1} >
                {busca.map((iten) => { return (<CardSearch iten={iten} isLoading={!dados} />); })}
              </Grid>
            </>
            )}
          </Container>
        }
      />

      <ModalReferencias
        open={openModal}
        referencias={referencias}
        setReferencias={setReferencias}
        titleModal={titleModal}
        setOpenModal={setOpenModal}
        setLoading={setLoading}
        isLoading={!dados}
        totalDeflatorSeller={totalDeflatorSeller}
        totalDeflatorMarca={totalDeflatorMarca}
      />
    </>
  );
}
