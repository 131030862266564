import React, { useState, useEffect } from "react";
import "./dropPastaFotos.css"
// import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
// import { toast } from 'react-toastify';
// import "react-toastify/dist/ReactToastify.css";
// import { getSessionStorage, removeSessionStorage } from "../../../utils/sessionStorage";
// import { nomePasta, id_marca, requestSomaStudio, agruparProdutosFotos } from "../../../utils/functions";
// import axios from "axios";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

export default function DropPastaFotos({ openModalPastaFotoMobile, setOpenModalPastaFotoMobile, setOpenModalFotos, setOpenModalPasta, pastasSelecionadas, stateRefSelecionadas }) {
  const [open, setOpen] = useState(false);

  window.onclick = function (event) {
    if (open) {
      if (event.target.id != "dropPastaFotos") {
        setOpen(false)
      }
    }
  }

  const handleClick = () => {
    if (window.innerWidth < 959) {
      setOpenModalPastaFotoMobile(openModalPastaFotoMobile ? false : true);
    } else {
      setOpen(open ? false : true);
    }
  }


  return (<>
    < div class="dropdown"  >

      {pastasSelecionadas.length == 0 && !stateRefSelecionadas &&
        <div className="box-btn-add-foto-modal">
          <Button id="dropPastaFotos" onClick={handleClick} className="btn-add-foto" variant="outlined" startIcon={<AddIcon />}> ADICIONAR </Button>
        </div>
      }

      {open &&
        <div class="dropdown-content" >
          <a className='iten-drop-mover-fotos' onClick={() => setOpenModalPasta(true)}><CreateNewFolderOutlinedIcon style={{ marginRight: "4px" }} />Nova Pasta</a>
          <a className='iten-drop-mover-fotos' onClick={() => setOpenModalFotos(true)}><UploadFileOutlinedIcon style={{ marginRight: "4px" }} />Upload de Imagem</a>
        </div>
      }
    </div>
  </>);
};



