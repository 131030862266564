import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { getSessionStorage, removeSessionStorage } from "../../../utils/sessionStorage";
import { id_marca, getBucketName, qtdSelecionadas, validInputAtivo } from "../../../utils/functions";
import axios from "axios";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ButtonGenerico from "../../Gestao/Button/ButtonGenerico.js";
import conf from "../../../utils/.params.js";
import TextField from '@mui/material/TextField';


export default function EditarNome({ busca, pastasSelecionadas, setModif, setPastasSelecionadas, stateRefSelecionadas, setStateRefSelecionadas }) {
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [ativo, setAtivo] = useState(false);
  const [nomeAntigo, setNomeAntigo] = useState(false);
  const [validIgual, setValidIgual] = useState(false);
  const user = getSessionStorage("user")
  const marca = (user.marcaSelecionada).replace(/ /g, "");

  const handleClose = () => { setOpen(false); }

  const abrirModal = async () => {
    let el = document.getElementsByClassName('cor-selecionada');
    let foo;
    if (el[0]) {
      foo = (el[0]).getAttribute('path');
    } else {
      const chave = `fotos_selecionadas_${marca}`
      let fotos = getSessionStorage(chave);
      foo = fotos[0].path;
    }
    
    setNomeAntigo(foo)
    setOpen(true);
  }

  const renomear = async () => {


    let element = document.getElementById('input_renomear_pasta')
    let nome = element.value.trim();

    // so pra garantir
    if (!nome) { return; }

    setLoad(true)
    setValidIgual(false);
    const idToast = toast.loading("Aguarde um pouco...")

    
    const idMarca = await id_marca(marca);

    const oldName = nomeAntigo.replace(`https://storage.googleapis.com/${await getBucketName(marca)}/`, "");
    let status = oldName.split("/").shift();

    const rota = conf.url_fotos() + `/pictures/rename/${idMarca}?status=${status}`
    let config = { method: 'put', url: rota, headers: { Authorization: "Bearer " + user.tokenFarol }, data: { "oldName": oldName, "newName": nome } };

    await axios.request(config).then(result => {
      setLoad(false)
      if (!result.data) {
        toast.dismiss(idToast);
        setValidIgual(true);
      } else {
        toast.update(idToast, { render: "Renomeada com sucesso!", type: "success", isLoading: false, autoClose: 3000 });
        removeSessionStorage(`fotos_selecionadas_${marca}`)
        setValidIgual(false);
        setOpen(false)
        setModif(Math.random(9999));
        setPastasSelecionadas([])
        setStateRefSelecionadas(false)
      }

      if (busca) {
        let elem = document.getElementById(nomeAntigo.slice(0, -1));

        if (elem) {
          let el = elem.classList;
          el.add('cor-normal');
          el.remove('cor-selecionada');

          // APENAS PARA SUBISTITUIR NO PATH O NOME ANTIGO PELO NOVO
          // let novo = oldName.split("/");
          // novo = (novo.slice(0, -2)).join("/");
          // novo = `${novo}/${nome}`;
          // elem.setAttribute('id', novo);
          // elem.setAttribute('path', novo + "/");
          // elem.setAttribute('nome', nome);

          elem.innerHTML = nome;
        }
      }

    }).catch(error => {
      console.log(error.message)
      setLoad(false)
      toast.update(idToast, { render: "Erro ao renomear!", type: "error", isLoading: false, autoClose: 3000 });
    });

  };

  return (<>

    <div className={`box-btn-add-foto-modal ${qtdSelecionadas(stateRefSelecionadas, pastasSelecionadas) == 1 ? 'displayBlock' : 'displayNone'}`} >
      <EditOutlinedIcon className="cursor display-flex" onClick={abrirModal} />
    </div>

    {open &&
      <Modal open={open} onClose={handleClose} >
        <Box className="modal-fotos modal-nova-pasta">
          <div className='closeIconFotos'> <CloseRoundedIcon onClick={handleClose} className="cursor" /> </div>
          <br />
          <p className='title-modal-fotos'> Renomear </p>

          <div className="box-input-nova-pasta">
            {validIgual ? (
              <TextField onChange={() => validInputAtivo("input_renomear_pasta", setAtivo)} margin="normal" error variant="outlined" id="input_renomear_pasta" label="Nome da pasta" helperText={validIgual ? "Esse nome já está sendo utilizado neste local" : ""} />
            ) : (
              <TextField onChange={() => validInputAtivo("input_renomear_pasta", setAtivo)} variant="outlined" id="input_renomear_pasta" label="Nome da pasta" />
            )}
          </div>

          <div className="box-btn-add-foto-modal-2">
            <ButtonGenerico texto={"OK"} click={renomear} load={load} ativo={ativo} id={'btn_renomear_pasta'} />
          </div>
        </Box>

      </Modal >
    }

  </>);
};



