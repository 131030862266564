import React from "react";
import { Grid, Card } from "@material-ui/core";
import ButtonModal from "../Modal/ButtonModal";
import "./styles.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import {
  transformTextModal,
  transformTitleModal,
  sendGTAGEventByID,
  formatPrice,
} from "../../../utils/functions";

export default function CardGenerico({ id, SKU, refs, Income, badge, badgeText, handleOpenModal, emailUser = undefined}) {
  const textModal = transformTextModal();
  const titletModal = transformTitleModal();

  let cardGenerico = badge == "catalogo" ? "card-generico catalogo" : "card-generico operacao";

  return (
    <>
      <Grid direction="row" item xs={12} md={4}>
        <Card className="root-card-1" variant="outlined">
          <div className="grid-icon cursor" name={id} onClick={() => {
              handleOpenModal(id, titletModal[id]);
              sendGTAGEventByID(id, emailUser);
            }}
          >
            <div className="nao-ofertados">
              <Card className={cardGenerico}>{badgeText}</Card>
              <div className="iconeAvancar">
                <ArrowForwardIosRoundedIcon />
              </div>
            </div>
          </div>

          <div className="section-2-card-generic">
            <section className="title2"> {titletModal[id]}</section>
            <ButtonModal
              titleModal={titletModal[id]}
              textModal={textModal[id]}
            />
          </div>
          <label
            onClick={() => {
              handleOpenModal(id, titletModal[id]);
              sendGTAGEventByID(id, emailUser);
            }}
            name={id}
            className="cursor footer-card"
          >
            <div className="card-divider">
              <div className="container-divider">
                <Grid className="m-t-10" item xs={12} sm={5} md={3} style={{ paddingLeft: "0px" }}>
                  <div className="title-card">Qtd. de Refs</div>
                  <div className="value-card">{refs}</div>
                </Grid>
                <Grid item md={1}> <div className="divider-3" /> </Grid>
                <Grid className="m-t-10" item xs={12} sm={5} md={3} style={{ paddingLeft: "0px" }}>
                  <div className="title-card">Qtd. de SKUs</div>
                  <div className="value-card">{SKU}</div>
                </Grid>
                <Grid item md={1}> <div className="divider-3" /> </Grid>
                <Grid className="m-t-10" item xs={12} sm={5} md={4}>
                  <div className="title-card">Receita</div>
                  <div className="value-card">{formatPrice(Income)}</div>
                </Grid>
              </div>
            </div>
          </label>
        </Card>
      </Grid>
    </>
  );
}
