import * as React from 'react';
import { formatPrice, selRefs } from '../../../utils/functions';
import "./TabelaProdutosMarketplace"


export default function Row({ row }) {

  return (
    <>
      <tr id={`elemento_${row.REF}`}>
        {row.status == "Pending" &&
          <td className=''>
            <label className="custom-checkbox">
              <input type="checkbox" value={row.REF} className={`${row.REF} checkboxMarketplace`} onClick={(el) => { selRefs(row.REF, "refSelecionadasMarketplace", el.target.checked, 'btn_aprovar_marketplace') }} />
              <span className="checkmark">{row.REF}</span>
            </label>
          </td>
        }
        {row.status != "Pending" &&
          <td> <div className="horizontal">{row.REF}</div> </td>
        }
        <td> <div className="horizontal">{row.qtd_skus}</div> </td>
        <td> <div className="horizontal">{row.COLECAO}</div> </td>
        <td> <div className="horizontal">{formatPrice(row.ESTOQUE * row.PRECO_ECOMMERCE)}</div> </td>
      </tr>

    </>
  );
}
