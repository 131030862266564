import * as React from 'react';
import { format_data, formatPrice } from '../../../utils/functions';

export default function RowResumoDiario({ row }) {
  const receita_estoque_deflator = row.estoque_deflator ? formatPrice(parseFloat(row.estoque_deflator) * parseFloat(row.PRECO_ECOMMERCE)) : formatPrice(parseFloat(row.ESTOQUE) * parseFloat(row.PRECO_ECOMMERCE));
  const receita_estoque_deflator_marca = row.estoque_deflator_marca ? formatPrice(parseFloat(row.estoque_deflator_marca) * parseFloat(row.PRECO_ECOMMERCE)) : row.estoque_deflator ? receita_estoque_deflator : formatPrice(parseFloat(row.ESTOQUE) * parseFloat(row.PRECO_ECOMMERCE));


  return (<>
    <tr>
      <td className='td'> {row.REFID}</td>
      <td className=''> {row.COLECAO}</td>
      <td className=''> {formatPrice(parseFloat(row.ESTOQUE) * parseFloat(row.PRECO_ECOMMERCE))}</td>
      <td className=''> {row.ESTOQUE}</td>
      <td className=''> {receita_estoque_deflator}</td>
      <td className=''> {row.hasOwnProperty('estoque_deflator_seller') ? row.estoque_deflator_seller : row.ESTOQUE}</td>
      <td className=''> {receita_estoque_deflator_marca}</td>
      <td className=''> {row.hasOwnProperty('estoque_deflator_marca') ? row.estoque_deflator_marca : row.hasOwnProperty('estoque_deflator_seller') ? row.estoque_deflator_seller : row.ESTOQUE}</td>
    </tr>
  </>);
}
