import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

export default function BasicBreadcrumbsBusca({ diretorios }) {

  if (typeof diretorios == 'string') { diretorios = [diretorios] }

  return (
    <div role="presentation">

      <Breadcrumbs aria-label="breadcrumb" >
        {diretorios.map((item) => {
          if (item) {
            var titulo = item.replace("_", " ").replaceAll("//", "/").replaceAll("/", " / ");
            return <Typography color="text.primary" className="titile-first-letter">{titulo}</Typography>
          }
        })}

      </Breadcrumbs>
    </div>
  );
}