import "./modalFotos.css"
import React, { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ButtonGenerico from "../../Gestao/Button/ButtonGenerico";
import {  nomePasta, id_marca, validInputAtivo } from "../../../utils/functions";
import { getSessionStorage } from "../../../utils/sessionStorage";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import conf from "../../../utils/.params.js";
import axios from "axios";

export default function ModalNovaPasta({ titleModal, diretorioAtual, openModalPasta, setOpenModalPasta, setModif }) {

  const [load, setLoad] = useState(false);
  const [ativo, setAtivo] = useState(false);
  const user = getSessionStorage("user")
  const marca = (user.marcaSelecionada).replace(/ /g, "");

  const handleClose = () => { setOpenModalPasta(false); }

  const criarPasta = async () => {
    var input = document.getElementById('input_nova_pasta').value;

    if (!input) {
      return false;
    }

    const marca = getSessionStorage("user").marcaSelecionada;
    let pasta = (window.location.pathname).split("/").reverse();
    pasta = await nomePasta(pasta[0]);

    setLoad(true)
    const idToast = toast.loading("Aguarde um pouco...")

    
    var requestOptions = {
      method: 'post',
      url: conf.url_fotos() + `/pictures/newPath/${await id_marca(marca)}?status=${pasta}`,
      headers: { Authorization: "Bearer " + user.tokenFarol },
      data: { pathName: input, path: diretorioAtual.join("/") },
    };

    await axios(requestOptions).then(result => {
      setOpenModalPasta(false)
      toast.update(idToast, { render: `${result.data}`, type: "success", isLoading: false, autoClose: 5000 });
      document.getElementById("input_nova_pasta").value = '';
      setModif(Math.random(9999));
    }).catch(error => {
      console.log(error.message)
      toast.update(idToast, { render: `Erro: ${error.message}`, type: "error", isLoading: false, autoClose: 5000 });
    });
    setLoad(false)

  };

  return (<>

    <Modal keepMounted open={openModalPasta} onClose={handleClose} >
      <Box className="modal-fotos modal-nova-pasta">
        <div className='closeIconFotos'> <CloseRoundedIcon onClick={handleClose} className="cursor" /> </div>
        <br />
        <p className='title-modal-fotos'> {titleModal} </p>

        <div className="box-input-nova-pasta">
          <input className="imput-nova-pasta" type="text" placeholder="Nome da pasta" id={'input_nova_pasta'} onChange={() => validInputAtivo("input_nova_pasta", setAtivo)} />
        </div>

        <div className="box-btn-add-foto-modal-2">
          <ButtonGenerico texto={"CRIAR"} click={criarPasta} load={load} ativo={ativo} id={'btn_nova_pasta'} />
        </div>
      </Box>

    </Modal >
  </>);
}