import { forwardRef } from "react";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import "./ButtonSnackbar.css";


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ButtonSnackbar({ funcaoClick, textoIcone, abrirToast, tipo, msgRetorno, setAbrirToast, id, Icon }) {
  

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAbrirToast(false);
  };

  return (
    <Stack spacing={2} direction="row">
      <IconButton aria-label="email" onClick={funcaoClick} id={id} >
      {Icon}
      {textoIcone}
      </IconButton>
    
      <Snackbar open={abrirToast} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity={tipo} sx={{ width: "100%" }}>
            {msgRetorno}
          </Alert>
      </Snackbar>
    </Stack>
  );
}
