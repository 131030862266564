import React from "react";
import MenuFarolDeskMobile from "./../../../components/Gestao/MenuFarol/MenuDeskMobile";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import CardPequeno from "../../../components/Estudio/CardPequeno/CardPequeno";
import { Grid } from "@mui/material";
import IosShareTwoToneIcon from "@mui/icons-material/IosShareTwoTone";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { defaultRequest, formataBotoes, objToCSV, } from "../../../utils/functions";

import { useNavigate } from "react-router";
import { getSessionStorage } from "../../../utils/sessionStorage";
import "./pacote.css";
import ButtonSnackbar from "../../../components/Estudio/ButtonSnackbar/ButtonSnackbar.js";
import { desabilitarBotoes } from "../../../utils/functions";
import BotaoExportar from "../../../components/Estudio/BotaoExportar/BotaoExportar.js";
import TopoTabsTabela from "../../../components/Estudio/TopoTabsTabelas/TopoTabsTabela";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";

export default function Pacote() {
  const [titulo, setTitulo] = useState("");
  const [value, setValue] = React.useState("1");
  const [open, setOpen] = useState(false);
  const [msgRetorno, setMsgRetorno] = useState(false);
  const [tipo, setTipo] = useState("success");
  const [dados, setDados] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [busca, setBusca] = useState(false);
  const navigate = useNavigate();
  const route = "/estudio/detalhePacote";
  registerLocale("pt-BR", ptBR);
  setDefaultLocale("pt-BR");
  const pacote = sessionStorage.getItem("pacote");
  const user = getSessionStorage("user");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTitulo(pacote);
    setLoading(false);
    defaultRequest(route, { pacote: pacote })
      .then(function (response) {
        setDados(response);
      })
      .catch((error) => {
        console.log(error.message);
        return;
      });

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => { setWindowWidth(window.innerWidth); };

  const alternarTabs = (event, newValue) => {
    let [btnEmail] = document.getElementsByClassName("btn-enviarEmail");
    let [btnData] = document.getElementsByClassName("btn-dataPricker");
    let [btnComentario] = document.getElementsByClassName("btn-comentario");

    desabilitarBotoes();
    formataBotoes("adicionar-estilo");
    formataBotoes("remover-estilo");

    // valor 2 = aba de upload
    if (newValue == 2) {
      if (getSessionStorage(`refSelecionadasPacoteUpload_${user.marcaSelecionada}`)) {
        btnComentario.classList.remove("icones-cinza");
      }
    } else {
      if (getSessionStorage(`refSelecionadasPacote_${user.marcaSelecionada}`)) {
        btnEmail.classList.remove("icones-cinza");
        btnData.classList.remove("icones-cinza");
        btnComentario.classList.remove("icones-cinza");
      }
    }
    setValue(newValue);
  };

  const exportCsv = async () => {
    defaultRequest("/estudio/exportarUploadCsv", { pacote: pacote }).then((response) => {
      if (response.length == 0) {
        setMsgRetorno("Houve um erro interno");
        setTipo("error");
        setOpen(true);
        return;
      } else {
        objToCSV(response);
        setMsgRetorno("Arquivo exportado com sucesso");
        setTipo("success");
        setOpen(true);
      }
    })
      .catch((error) => {
        console.log(error);
        setMsgRetorno("Houve um erro interno");
        setTipo("error");
        setOpen(true);
      });
  };

  const salvarDataSessaoFoto = async (e) => {
    if (value == 2) return;

    const el = document.getElementsByClassName("diaSelecionado");
    if (el.length == 0) {
      setMsgRetorno("Escolha uma data.");
      setTipo("warning");
      setOpen(true);
      return false;
    }

    const refs = getSessionStorage(
      `refSelecionadasPacote_${user.marcaSelecionada}`
    );
    if (!refs || refs.length == 0) {
      return false;
    }

    setDados(false);

    let params = sessionStorage.getItem("params")
      ? JSON.parse(sessionStorage.getItem("params"))
      : {};

    params.referencias = refs;
    params.usuario = user.id_usuario;
    params.pacote = pacote;
    params.data = el[0].getAttribute("data");

    defaultRequest("/estudio/salvarDataSessaoFotos", params)
      .then((response) => {
        setMsgRetorno("Data salva com sucesso");
        setTipo("success");
        setOpen(true);
        sessionStorage.removeItem(
          `refSelecionadasPacote_${user.marcaSelecionada}`
        );
        setDados(response);
        desabilitarBotoes();
      })
      .catch((error) => {
        console.log(error);
        setMsgRetorno("Houve um erro interno 1!");
        setTipo("error");
        setOpen(true);
        return;
      });
  };

  const salvarDataEnvioEmail = async (e) => {
    if (value == 2) return;

    const refs = getSessionStorage(
      `refSelecionadasPacote_${user.marcaSelecionada}`
    );
    if (!refs || refs.length == 0) {
      return false;
    }
    setDados(false);

    let params = sessionStorage.getItem("params")
      ? JSON.parse(sessionStorage.getItem("params"))
      : {};
    params.referencias = refs;
    params.usuario = user.id_usuario;
    params.pacote = pacote;

    defaultRequest("/estudio/salvarDataEnvioEmail", params)
      .then((response) => {
        setMsgRetorno("Email enviado com sucesso");
        setTipo("success");
        setOpen(true);
        sessionStorage.removeItem(
          `refSelecionadasPacote_${user.marcaSelecionada}`
        );
        setDados(response);
        desabilitarBotoes();
      })
      .catch((error) => {
        console.log(error);
        setMsgRetorno("Houve um erro interno 1!");
        setTipo("error");
        setOpen(true);
        return;
      });
  };

  const salvarComentario = (e) => {
    const marca = getSessionStorage("user").marcaSelecionada;

    const comentario = document.querySelector(
      'input[name="radio-buttons-group"]:checked'
    );
    if (comentario == null) {
      return;
    }

    let referencias =
      value == 1
        ? getSessionStorage(`refSelecionadasPacote_${marca}`)
        : getSessionStorage(`refSelecionadasPacoteUpload_${marca}`);
    if (!referencias || referencias.length == 0) {
      return;
    }

    setDados(false);
    let params = sessionStorage.getItem("params")
      ? JSON.parse(sessionStorage.getItem("params"))
      : {};
    params.referencias = referencias;
    params.usuario = user.id_usuario;
    params.comentario = comentario.value;
    params.pacote = pacote;

    defaultRequest("/estudio/salvarComentario", params)
      .then((response) => {
        setMsgRetorno("Comentário salvo com sucesso");
        setTipo("success");
        setOpen(true);
        value == 1
          ? sessionStorage.removeItem(`refSelecionadasPacote_${marca}`)
          : sessionStorage.removeItem(`refSelecionadasPacoteUpload_${marca}`);
        setDados(response);
        desabilitarBotoes();
      })
      .catch((error) => {
        console.log(error);
        setMsgRetorno("Houve um erro interno 2!");
        setTipo("error");
        setOpen(true);
      });
  };

  return (
    <>
      <MenuFarolDeskMobile
        title={
          <HeaderDesk atualizado={dados.atualizado} setBusca={setBusca} tipoBusca={"ESTUDIO"} setLoading={setLoading} title={titulo} enderecoVoltar={"/estudio"} />
        }
        body={
          <div className="card-total">
            <ButtonSnackbar funcaoClick={false} texto={"Enviar email"} abrirToast={open} msgRetorno={msgRetorno} tipo={tipo} setAbrirToast={setOpen} id={""}
            />
            <InfoMenuMobile title={"Planejamento"} setBusca={setBusca} tipoBusca={"ESTUDIO"} setLoading={setLoading} atualizado={dados.atualizado} tipoTela={true} enderecoVoltar={"/estudio"} />
            <div className="divider-pacote" />

            {!busca ? (
              <>
                <div className="card-pequeno">
                  {dados && (
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={6} md={2}>
                          <CardPequeno pecas={dados.produtosEstudio.indicadores.total} valorPecas={"Total Referências"} />
                        </Grid>

                        <Grid xs={6} md={2} item>
                          <CardPequeno
                            pecas={dados.produtosEstudio.indicadores.com_fotos}
                            chipGreen={((dados.produtosEstudio.indicadores.com_fotos * 100) / dados.produtosEstudio.indicadores.total).toFixed(1) + "%"}
                            valorPecas={"Fotografadas"}
                          />
                        </Grid>

                        <Grid xs={6} md={2} item>
                          <CardPequeno
                            pecas={dados.produtosEstudio.indicadores.sem_fotos}
                            chipRed={((dados.produtosEstudio.indicadores.sem_fotos * 100) / dados.produtosEstudio.indicadores.total).toFixed(1) + "%"}
                            valorPecas={"Não fotografadas"}
                          />
                        </Grid>

                        <Grid xs={6} md={2} item>
                          <CardPequeno pecas={dados.produtosEstudio.indicadores.disponivel_foto} valorPecas={"Disponível para foto"} />
                        </Grid>

                        <Grid xs={6} md={2} item>
                          <CardPequeno pecas={dados.produtosEstudio.indicadores.agendadas} valorPecas={"Agendadas para foto"} />
                        </Grid>

                        <Grid xs={6} md={2} item>
                          <CardPequeno pecas={dados.produtosEstudio.indicadores.em_conferencia} valorPecas={"Em conferência"} />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>

                <div
                  className={windowWidth < 959 ? "divider" : "divider-pacote"}
                />

                <Grid item className="btn-exportar-csv-estudio">
                  <BotaoExportar
                    className="btn-exportar"
                    onClick={exportCsv}
                    title={"Exportar"}
                    endIcon={<IosShareTwoToneIcon />}
                  ></BotaoExportar>
                </Grid>

                <TopoTabsTabela
                  value={value}
                  alternarTabs={alternarTabs}
                  exportCsv={exportCsv}
                  IosShareTwoToneIcon={IosShareTwoToneIcon}
                  salvarDataEnvioEmail={salvarDataEnvioEmail}
                  salvarDataSessaoFoto={salvarDataSessaoFoto}
                  salvarComentario={salvarComentario}
                  dados={dados}
                  busca={busca}
                />


              </>
            ) : (
              <>
                {busca.buscaEstudio.length > 0 && (

                  <TopoTabsTabela
                    value={value}
                    alternarTabs={alternarTabs}
                    exportCsv={exportCsv}
                    IosShareTwoToneIcon={IosShareTwoToneIcon}
                    salvarDataEnvioEmail={salvarDataEnvioEmail}
                    salvarDataSessaoFoto={salvarDataSessaoFoto}
                    salvarComentario={salvarComentario}
                    dados={dados}
                    busca={busca}
                  />
                )}
              </>
            )}
          </div>
        }
      />
    </>
  );
}
