import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

export default function BasicBreadcrumbs({ diretorios, setDiretorioAtual, setModif }) {

  if (typeof diretorios == 'string') { diretorios = [diretorios] }

  if (!diretorios) {
    return <></>
  }


  const handleClick = async (event) => {
    event.preventDefault();
    const id = event.target.id;
    if (id) {
      let key = diretorios.indexOf(id);
      let sliced = diretorios.slice(0, key + 1);
      setDiretorioAtual(sliced)

      if (setModif) {
        setModif(Math.random(9999));
      }
    }
  };

  const ultimo = diretorios[diretorios.length - 1];
  const primeiro = diretorios[0];

  return (
    <div role="presentation" onClick={handleClick} >

      <Breadcrumbs aria-label="breadcrumb" >
        {diretorios.map((item) => {

          if (item) {
            var titulo = item.replace("_", " ")
            if (ultimo == item && primeiro != item) {
              return <Typography color="text.primary" >{titulo}</Typography>
            } else {
              const modStr = titulo[0].toUpperCase() + titulo.slice(1);
              return <Link underline="hover" color="inherit" id={item} className={`cursor ${item == primeiro ? 'titile-first-letter' : 'lowerCase'}`} > {modStr} </Link>
            }

          }
        })}

      </Breadcrumbs>
    </div>
  );
}