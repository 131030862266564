const globalTheme = {
  colors: {
    primary: "#DBC7B8",
    secondary: "#333333",
    error: "#D66157",
    warning: "#E3CC6C",
    success: "#148A6A",
    gray: "#808080",
  },
};

export default globalTheme;
