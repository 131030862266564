import React, { useState, useEffect } from "react";
import "./../style.css";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Container from "@mui/material/Container";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import semFotos from "../../../static/sem-fotos.png";
import FotosProduto from "../../../components/Fotos/FotosProduto/FotosProduto";
import { handleClickTodasFotos, handlerDonwloadFotos, requestSomaStudio, montarTelaFotosBackup, qtdSelecionadas, getBucketName } from "../../../utils/functions";
import { removeSessionStorage, getSessionStorage } from "../../../utils/sessionStorage";
import ModalDetalheProduto from "../../../components/Fotos/ModalFotos/ModalDetalheProduto";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Skeleton } from "@mui/material";
import DiretorioBackup from "../../../components/Fotos/DiretorioBackup/Diretorio";

export default function Backup() {
    const user = getSessionStorage("user");
    const marca = (user.marcaSelecionada).replace(/ /g, "");
    
    const [diretorioAtual, setDiretorioAtual] = useState([getBucketName(marca)]);
    const [fotos, setFotos] = useState(false);
    const [diretorios, setDiretorios] = useState(false);
    const [dados, setDados] = useState(false);
    const [busca, setBusca] = useState(false);
    const [retBusca, setRetBusca] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [stateRefSelecionadas, setStateRefSelecionadas] = useState(false);
    const [open, setOpen] = useState(false);
    const [fotosModalDetalhe, setFotosModalDetalhe] = useState([]);
    const [modif, setModif] = useState(false);
    const [pastasSelecionadas, setPastasSelecionadas] = useState([]);

    const handlerOpenModalDetalheFoto = (e) => {
        let novo = [];
        fotos.imagens.map(item => { if (item.reference == e) { novo.push(item.imagens) } })
        setFotosModalDetalhe(novo)
        setOpen(true)
    };

    // console.log([getBucketName(marca)])
    useEffect(async () => {
        setLoading(false)
        setDados(false)
        await removeSessionStorage(`fotos_selecionadas_${marca}`);
        let ret = await requestSomaStudio("/pictures");
        setDados(ret.data);
        await montarTelaFotosBackup(ret.data, diretorioAtual, setFotos, setDiretorios);
        setLoading(true)

    }, [modif]);


    return (<>
        <MenuFarolDeskMobile title={<HeaderDesk setPastasSelecionadas={setPastasSelecionadas} setDiretorioAtual={setDiretorioAtual} setModif={setModif} atualizado={dados.atualizado} setBusca={setBusca} tipoBusca={"FOTOS"} setLoading={setLoading} tipoTela={false} title={diretorioAtual} enderecoVoltar={"/fotos/inicio"} />}
            body={<>
                <Container maxWidth="sm" className="container">
                    {open && <ModalDetalheProduto fotos={fotosModalDetalhe} open={open} setOpen={setOpen} />}
                    <InfoMenuMobile titulo={"Backup"} setBusca={setBusca} setLoading={setLoading} atualizado={dados.atualizado} tipoTela={true} enderecoVoltar={"/fotos/inicio"} />
                    <div className="divider-top" />
                    <div className="card-fotos-generico-barra-acao">
                        <div className={`p-l-0  card-fotos-generico-2 card-fotos-generico-3 ${stateRefSelecionadas || (stateRefSelecionadas > 0) || (pastasSelecionadas.length > 0) ? 'card-fotos-generico-ativo' : ''}`}>
                            {/* <div className={`cursor ${stateRefSelecionadas || pastasSelecionadas.length > 0 ? 'text-black display-flex' : 'displayNone'}`}>
                                <CloseOutlinedIcon className="m-l-5" onClick={() => buscaGeral(retBusca.termoBusca, setPastasSelecionadas, setBusca, setLoading, "FOTOS")} />
                                <p className="m-l-5 card-fotos-generico-titulo">{qtdSelecionadas(stateRefSelecionadas, pastasSelecionadas)}</p>
                                <p className="m-l-5 card-fotos-generico-titulo">{qtdSelecionadas(stateRefSelecionadas, pastasSelecionadas) ? "Selecionados" : "Selecionado"}</p>
                            </div> */}

                            <div className={`card-fotos-generico-barra-acao-icones ${stateRefSelecionadas ? 'text-black' : 'displayNone'}`}>
                                <FileDownloadOutlinedIcon className="cursor" onClick={handlerDonwloadFotos} />
                            </div>
                        </div>
                    </div>

                    <div className="card-fotos-generico">
                        {!isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={250} />) : (<>
                            {diretorios.length > 0 &&
                                <DiretorioBackup
                                    diretorios={diretorios}
                                    diretorioAtual={diretorioAtual}
                                    setDiretorioAtual={setDiretorioAtual}
                                    dados={dados}
                                    setFotos={setFotos}
                                    setDiretorios={setDiretorios}
                                    setPastasSelecionadas={setPastasSelecionadas}
                                    pastasSelecionadas={pastasSelecionadas}
                                    setModif={setModif}
                                />
                            }

                            {fotos && diretorios.length > 0 && <div className="card-fotos-generico-divider" />}

                            <div className="box-fotos" >

                                {!fotos && diretorios.length == 0 ? (<div className="container-sem-fotos"> <img className="img-sem-fotos" src={semFotos} /> <p className="text-center">Não há fotos em backup</p></div>) : (<>
                                    {fotos && <>
                                        <div className="m-0 display-flex"> <Checkbox click={handleClickTodasFotos} classe={"todas_fotos"} value={`todos_produtos_linha_backup`} />
                                            <p className="m-l-5 card-fotos-generico-titulo" >Todas:</p>
                                            <p className="m-l-5 card-fotos-generico-titulo text-gray">({fotos.qtdFotos ? fotos.qtdFotos : 0} {fotos.qtdFotos > 1 ? "fotos" : "foto"})</p>
                                        </div>
                                        {fotos.imagens.map((item) => {
                                            return <FotosProduto handlerOpenModalDetalheFoto={handlerOpenModalDetalheFoto} fotos={item} setStateRefSelecionadas={setStateRefSelecionadas} tela={'sem_tratamento'} classe={`todos_produtos_linha_backup`} />
                                        })}
                                    </>}
                                </>)}
                            </div>
                        </>)}
                    </div>

                </Container >
            </>} /></>);
}
