import ButtonGenerico from "../../components/Gestao/Button/ButtonGenerico";
import "./login.css";
import imglogin from '../../../src/static/login-novo-2.png';
import imglogo from '../../../src/static/Logo_Grupo_Soma_branco.svg';
import { useNavigate } from "react-router";


export default function Sucesso() {

  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate("/");
  };

  return (
    <div className="container-rec" >
      <div className="pageImage">
      <img src={imglogin} className="loginImage" />
      </div>
      <div className="loginPage">
        <section className="solicSuccess" />
        <section className="sectionPattern">
          <h2 className="h2format-suc">Solicitação enviada com sucesso!</h2>
          <h3 className="h3format">
            Em breve alguém do nosso time entrará em contato com você.
          </h3>
          <ButtonGenerico ativo={true} error={true} texto={"VOLTAR PARA O LOGIN"} click={handleClick} />
        </section>

        <img src={imglogo} className="logo" /> 
      </div>
    </div>
  );
}
