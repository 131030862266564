import React from "react";

import { Grid, Card } from "@material-ui/core";
import "./styles.css";
import ButtonModal from "../../Gestao/Modal/ButtonModal";
import { useNavigate } from "react-router";
import { Skeleton } from "@mui/material";

export default function CardInicio({ item }) {
  const navigate = useNavigate();

  return (
    <>
      <Grid className="cursor" direction="row" item xs={12} md={4} >
        <Card className="card-fotos" variant="outlined">
          {!item.load ? (<Skeleton variant="rounded" sx={{ height: 'inherit' }} />) : (<>
            <div className="car-fotos-box-titulo">
              <p className="card-foto-sub-titulo" onClick={() => navigate(`/fotos${item.endereco}`)}>{item.tituloCard}</p>
              <ButtonModal titleModal={item.tituloCard} textModal={item.textoModal} />
            </div>
            <label className="label-onClick" onClick={() => navigate(`/fotos${item.endereco}`)}>

              <div className="card-fotos-info">
                <div className="titulo-enviadas-erros">
                  <div className="circle green" />
                  <p className="titulo-fotos-info">Enviadas</p>
                </div>
              </div>
              <div className="card-fotos-info">
                <p className="titulo-fotos-info-2 ">{item.qtd.upload.qtdReferencias} Referências / {item.qtd.upload.qtdFotos} Fotos</p>
              </div>

              <div className="card-fotos-info">
                <div className="titulo-enviadas-erros">
                  <div className="circle red" />
                  <p className="titulo-fotos-info">Com erro</p>
                </div>
              </div>
              <div className="card-fotos-info">
                <p className="titulo-fotos-info-2 ">{item.qtd.erros.qtdReferencias} Referências / {item.qtd.erros.qtdFotos} Fotos</p>
              </div>

            </label>
          </>)}
        </Card >
      </Grid >
    </>
  );
}

