import * as React from 'react';
import { useContext } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import InfoIcon from '../Icon/InfoIcon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import "./ButtonModal.css"
import SendGTAGEvent from '../../../utils/sendGTAGEvent';
import { UserContext } from "../../../contexts/UserContext";

export default function ButtonModal({ titleModal, textModal }) {
  const { email } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleCloseIcon = () => setOpen(false);

  return (
    <>
      <div className="infoFormat cursor" onClick={() => { setOpen(true); SendGTAGEvent('action_info', titleModal, 'click', email); }}>
        <InfoIcon />
      </div>
      <Modal keepMounted open={open} onClose={handleClose} >
        <Box className="modalFormat">
          <div className='closeIcon'>
            <div className='title-modal'> {titleModal} </div>
            <CloseRoundedIcon onClick={handleCloseIcon} className="cursor" />
          </div>
          <Typography sx={{ mt: 2 }} className="texto-modal" > {textModal} </Typography>
        </Box>
      </Modal>
    </>
  );
}