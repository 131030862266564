
const url = () => {
  // return "http://localhost:4001";
  return "https://app-farol-backend.somalabs.com.br";
};
  
const url_fotos = () => {
  // return "http://localhost:8080";
  return "https://estudio-online-api-zqshxhdkqa-uc.a.run.app";
};



export default { url, url_fotos };
