import * as React from 'react';
import { useContext } from "react";
import "./Search.css";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { buscaGeral } from "../../../utils/functions";
import SendGTAGEvent from '../../../utils/sendGTAGEvent';
import { UserContext } from "../../../contexts/UserContext";

export default function SearchButton({ setPastasSelecionadas, setBusca, setLoading, tipoBusca }) {
  const { email } = useContext(UserContext);

  const handleOpenInput = () => {
    let inputBusca = document.getElementById('inputBusca')
    if (inputBusca.getAttribute('class').indexOf('input-farol-aberto') != -1) {
      buscaGeral(inputBusca.value, setPastasSelecionadas, setBusca, setLoading, tipoBusca)
    } else {
      inputBusca.classList.add('input-farol-aberto');
      let closeBusca = document.getElementById('closeBusca')
      closeBusca.setAttribute('style', 'display:block')

      document.querySelector('.info-top-2').classList.add('esconder-info-top-2');
    }

    inputBusca.removeAttribute('disabled')
    document.querySelector('.box-busca-topo').setAttribute('style', 'justify-content: start;');

    SendGTAGEvent('action_search', 'search', 'click', email);
  };

  const handleCloseInput = () => {
    setLoading(false)
    let inputBusca = document.getElementById('inputBusca')
    inputBusca.classList.remove('input-farol-aberto');

    let closeBusca = document.getElementById('closeBusca')
    closeBusca.setAttribute('style', 'display:none')

    document.querySelector('.info-top-2').classList.remove('esconder-info-top-2');
    setBusca(false)
    setLoading(true)

    document.querySelector('.box-busca-topo').setAttribute('style', 'justify-content: end;');
    inputBusca.setAttribute('disabled', true);
    inputBusca.innerHTML = '';
    inputBusca.value = '';
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      let termo = e.target.value;
      buscaGeral(termo, setPastasSelecionadas, setBusca, setLoading, tipoBusca)
    }
  };

  return (
    <div className="box-busca-topo" id="campoBuscaGeral">
      <SearchRoundedIcon id="searchIcon" className="searchIcon" onClick={handleOpenInput} />
      <input type="text" className="inputFarol" name="search" placeholder="" id="inputBusca" onKeyUp={handleSearch} autoComplete="off" disabled />
      <CloseRoundedIcon onClick={handleCloseInput} id="closeBusca" className="cursor" style={{ display: 'none' }} />
    </div>
  );
}
