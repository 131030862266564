import React from "react";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Grid, Pagination } from "@mui/material";
import { defaultRequest, exportarCsv } from "../../../utils/functions";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import "../Acessos/acessos.css";
import Row from "./RowIntegracao";
import { Skeleton } from "@mui/material";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";

export default function Marketplace() {
  const [dados, setDados] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const route = "/log";

  let pageCount = Math.floor(dados.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    defaultRequest(route, { tipo: "ASSOCIAÇÃO MARKETPLACE" })
      .then(function (response) {
        setDados(response);
      })
      .catch((error) => {
        console.log(error.message);
        return;
      });
  }, []);

  const exportar = async () => {
    if (dados.length > 0) {
      exportarCsv(dados, "LOG_MARKETPLACE");
    }
  };

  return (
    <>
      <MenuFarolDeskMobile
        title={
          <HeaderDesk
            atualizado={"all the time"}
            setBusca={false}
            setLoading={false}
            tipoTela={"LOG"}
            title={"LOG INTEGRAÇÃO MARKETPLACE"}
            enderecoVoltar={"/gestao"}
          />
        }
        body={
          <div className="card-total box-card-total">
            {/* <div className="divider-pacote" /> */}
            <div className="box-geral">
              <button className="btn-exportar-log" onClick={exportar} style={{ marginBottom: "2%" }}>
                Exportar
              </button>
            </div>
            {!dados ? (
              <Skeleton className="skeleton-format" variant="rectangular" height={300} />
            ) : (
              <>
                <Grid container className="">
                  <table className="table-border" cellspacing="0">
                    <thead>
                      <tr className="header-tabela">
                        <th className="th">SKU</th>
                        <th className="th">Qtd.</th>
                        <th className="th">Receita</th>
                        <th className="th">Usuário</th>
                        <th className="th">Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {dados.length > 0 && dados.length > 99
                        ? dados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((iten) => {
                            return <Row row={iten} />;
                          })
                        : dados.map((iten) => {
                            return <Row row={iten} />;
                          })}
                    </tbody>
                  </table>
                </Grid>

                <div className="wrapper-pagination">
                  <Pagination
                    shape="rounded"
                    color="primary"
                    count={pageCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChange={handleChangePage}
                  />{" "}
                </div>
              </>
            )}
          </div>
        }
      />
    </>
  );
}
