import * as React from "react";
import { useState, useEffect } from "react";

//Bibliotecas
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

//CSS
import "./gamefication.css";

//Material UI
import { Skeleton } from "@material-ui/lab";
import Container from "@mui/material/Container";

//Utils
import { getRequest } from "../../utils/functions";

//Components
import SearchButton from "../../components/Gestao/SearchButton/Search";
import UserInfo from "../../components/Gestao/UserInfo/UserInfo";
import MenuFarolDeskMobile from "../../components/Gestao/MenuFarol/MenuDeskMobile";
import HeaderDesk from "../../components/Gestao/HeaderDesk/HeaderDesk";
import PodiumCircle from '../../components/Gamefication/Podium/Podium';
import TablePosition from "../../components/Gamefication/TablePositions/TablePosition";


export default function gamefication() {
  const [isLoading, setLoading] = useState(true);
  const [busca, setBusca] = useState(false);
  const [GameficationLoading, setGameficationLoading] = useState(true);
  const [Atualizado, setAtualizado] = useState("");
  const [BrandsArray, setBrandsArray] = useState([]);
  const [EstudioBrandsArray, setEstudioBrandsArray] = useState([]);
  const [MarketplaceBrandsArray, setMarketplaceBrandsArray] = useState([]);
  const route = "/gameficacao";

  const dataConsolided = (response) => {
    const array = []

    const today = response[0].dados;
    const yesterday = response[1].dados;

    for (let position = 0; position < today.length; position++) {
      const todaybrand = today[position] ? today[position].MARCA : null;
      const yesterdaybrand = yesterday[position] ? yesterday[position].MARCA : null;
      let item = {
        position: position + 1,
        brand: todaybrand,
        percentage: today[position].PORCENTAGEM,
        situation: null
      }

      let yesterdayPosition = null;

      yesterday.forEach((item, index) => {
        if (item.MARCA === todaybrand) {
          yesterdayPosition = index + 1
        }
      })

      if (todaybrand === yesterdaybrand) {
        item.situation = "same";
      } else if (yesterdayPosition < position + 1) {
        item.situation = "down";
      } else if (yesterdayPosition > position + 1) {
        item.situation = "up";
      }

      array.push(item)
    }

    return array;
  }


  useEffect(() => {
    setGameficationLoading(true);
    getRequest(route)
      .then(function (response) {
        try {
          const gestao = dataConsolided(response.dados.gestao);
          const marketplace = dataConsolided(response.dados.marketplace);
          const estudio = dataConsolided(response.dados.estudio);

          setBrandsArray(gestao);
          setMarketplaceBrandsArray(marketplace);
          setEstudioBrandsArray(estudio);

          setAtualizado(moment(gestao.data).format("DD/MM/YYYY hh:mm"));
        } catch (err) {
          toast.error(`Ocorreu um erro no processamento dos dados.`);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      }).finally(() => {
        setGameficationLoading(false);
      })
  }, []);

  return (
    <>
      {/* <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" /> */}

      <MenuFarolDeskMobile
        gamefication={true}
        title={
          <HeaderDesk
            atualizado={Atualizado}
            setBusca={setBusca}
            setLoading={setLoading}
            title={"Performance"}
            tipoTela={'PRINCIPAL'}
            enderecoVoltar={"/performance"}
            gamefication={true}
          />
        }

        body={
          <Container maxWidth="sm" className="containerGamefication">
            <div className="divider-top" />
            {/* APENAS PARA MOBILE */}
            <div className="box-top-mob">
              <div className="info-top">
                <div className="info-top-2">
                  <h1 className="titulo-principal">Performance</h1>
                  <h6 className="sub-titulo-principal">
                    Última atualização: {Atualizado}
                  </h6>
                </div>
                <div className="info-top-3-mob">
                  <SearchButton setBusca={setBusca} setLoading={setLoading} />
                  <div className="info-top-4">
                    <UserInfo />
                  </div>
                </div>
              </div>
              <div className="divider" />
            </div>
            {/* APENAS PARA MOBILE */}


            <div className="gameficationGeralContainer">
              {GameficationLoading ?
                <>
                  <Skeleton variant="rounded" width={327} height={600} />
                  <Skeleton variant="rounded" width={327} height={600} />
                  <Skeleton variant="rounded" width={327} height={600} />
                </>
                :
                <>
                  <div className="gameficationOutsideBox">
                    <h6 className="gameficationTitle">Receita Ofertada</h6>

                    <PodiumCircle data={BrandsArray[0]} />
                    <div className="podiumSecondAndThird">
                      <PodiumCircle data={BrandsArray[1]} />
                      <PodiumCircle data={BrandsArray[2]} />
                    </div>

                    <TablePosition data={BrandsArray.filter(item => item.position !== 1 && item.position !== 2 && item.position !== 3)} />
                  </div>

                  <div className="gameficationOutsideBox">
                    <h6 className="gameficationTitle">Receita Ofertada Marketplace</h6>

                    <PodiumCircle data={MarketplaceBrandsArray[0]} />
                    <div className="podiumSecondAndThird">
                      <PodiumCircle data={MarketplaceBrandsArray[1]} />
                      <PodiumCircle data={MarketplaceBrandsArray[2]} />
                    </div>

                    <TablePosition data={MarketplaceBrandsArray.filter(item => item.position !== 1 && item.position !== 2 && item.position !== 3)} />
                  </div>

                  <div className="gameficationOutsideBox">
                    <h6 className="gameficationTitle">Referências fotografadas</h6>

                    <PodiumCircle data={EstudioBrandsArray[0]} />
                    <div className="podiumSecondAndThird">
                      <PodiumCircle data={EstudioBrandsArray[1]} />
                      <PodiumCircle data={EstudioBrandsArray[2]} />
                    </div>

                    <TablePosition data={EstudioBrandsArray.filter(item => item.position !== 1 && item.position !== 2 && item.position !== 3)} />
                  </div>
                </>
              }
            </div>
          </Container >
        }
      />
    </>
  );
}