import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { styled, useTheme } from '@mui/material/styles';
import CameraOutlinedIcon from "@mui/icons-material/CameraOutlined";
import { ExpandLess, ExpandMore, } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import LogoutIcon from "@mui/icons-material/Logout";
import { Grid, Collapse } from '@mui/material';
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import { UserContext } from "../../../contexts/UserContext";
import { useContext } from "react";
import { useNavigate } from "react-router";
import Typography from '@mui/material/Typography';
import "./MenuMobile.css";
import "./MenuDesk.css";
import Toolbar from "@mui/material/Toolbar";
import UserInfo from "./../../../components/Gestao/UserInfo/UserInfo";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FormControl from "@material-ui/core/FormControl";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getSessionStorage, setSessionStorage, } from "../../../utils/sessionStorage";
import { useState, useEffect } from "react";
import imgAnimale from '../../../static/image61.svg';
import imgFarm from '../../../static/farm-rio-logo.png';
import imgSoma from '../../../static/Logo_Grupo_Soma_branco.svg';
import imgFabula from '../../../static/fabula-637456359398197697.png';
import imgFoxton from '../../../static/Foxton-Preta.png';
import imgMaria from '../../../static/mf-logo-400px.png';
import imgCris from '../../../static/logo-cris-barros-RGB.png';
import imgNV from '../../../static/Logo-nv-png-agosto.png';
import imgOFF from '../../../static/off-logo.svg';
import SendGTAGEvent from '../../../utils/sendGTAGEvent';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';

const theme = createTheme({
  palette: {
    primary: {
      main: "#e5e5e5 !important",
    },

  },
});

export default function TemporaryDrawer({ title, body, gamefication }) {

  const marcas = getSessionStorage("user").marcasPermitidas;
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [params, setParams] = useState({ filtro_lancados: false, filtro_colecao: false });
  const [open, setOpen] = React.useState(false);
  const [openLog, setOpenLog] = React.useState(false);
  // const [openDropdownGest, setOpenDropdownGest] = React.useState(false);
  const { email, userLogout } = useContext(UserContext);
  const marcaSelecionadaTotal = getSessionStorage("user").marcaSelecionada.toUpperCase();
  const navigate = useNavigate();
  const [user, setUser] = useState(getSessionStorage("user"));

  const handleDrawerOpenLog = () => { setOpenLog(!openLog); };
  const handleDrawerOpen = () => { setOpen(true); };

  const ItemIcon = styled(ListItemIcon)(({ theme }) => ({ minWidth: 45, color: "theme.palette.secondary.main" }));

  const [state, setState] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

    if (open) {
      SendGTAGEvent('expandir_menu', 'menu', 'click', email);
    }
  };

  const handleChangeMarcas = async (e) => {
    e.preventDefault()
    setLoading(true)

    if (e.target.name.toLowerCase(marcas)) {
      params.filtro_marca = e.target.value;
      setValorSelectMarca(e.target.value);
    }

    setParams(params)
    sessionStorage.setItem('params', JSON.stringify(params));
  };

  const handleClickItems = (marca) => {
    sessionStorage.removeItem('params')
    let obj = getSessionStorage("user");
    obj.marcaSelecionada = marca;
    setSessionStorage("user", obj);
    // setAnchorEl(null);
    window.location.href = "";
  };



  useEffect(() => {

    // if ([34, 258, 213, 20].includes(user.id_usuario)) {

    //   let doc = document.getElementById('menu_admin_mobile')
    //   doc ? doc.classList.remove('displayNone') : false;
    // }

    // if (user.id_usuario == '258') {
    //   let doc2 = document.getElementById('menu_atualizacoes_mobile')
    //   doc2 ? doc2.classList.remove('displayNone') : false;
    // }

  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }} >
        <div>{title}</div>

        <Toolbar style={{ backgroundColor: "#fff", height: "100px !important", width: "1000px !important" }}>
          <Button onClick={toggleDrawer('', true)} style={{ paddingRight: "0 !important" }}> {''}
            <div > <MenuRoundedIcon style={{ color: "#424242" }} /></div>
          </Button>
        </Toolbar>


        <Drawer anchor='left' open={state['']} onClose={toggleDrawer('', false)}>

          <Box sx={{ width: 250 }} role="presentation" >
            <div className="closed-icon">
              <CloseRoundedIcon onClick={toggleDrawer('', false)} />
            </div>
            <div className="user-info"> <UserInfo /> </div>
            <List >

              {/* PERFORMANCE */}
              <ListItemButton onClick={() => navigate("/performance")} sx={{ justifyContent: 'center', px: 2.5, }} >
                <ItemIcon className="camera-box-mobile" onClick={handleDrawerOpen} sx={{ backgroundColor: "fff" }}> <EmojiEventsOutlinedIcon /> </ItemIcon>
                <ListItemText primary={"Performance"} />
              </ListItemButton>

              {/* GESTAO */}
              <ListItemButton onClick={() => { navigate("/gestao"); SendGTAGEvent('abrir_sub_menu_resumo_diario', 'menu', 'click', email); }} sx={{ justifyContent: 'center', px: 2.5, }} >
                <ItemIcon className="camera-box-mobile" onClick={handleDrawerOpen} sx={{ backgroundColor: "fff" }}> <InsertChartOutlinedIcon /> </ItemIcon>
                <ListItemText primary={"Gestão"} />
              </ListItemButton>

              {/* ESTUDIO */}
              <ListItemButton onClick={() => navigate("/estudio")} sx={{ justifyContent: 'center', px: 2.5, }} >
                <ItemIcon className="camera-box-mobile" onClick={handleDrawerOpen} sx={{ backgroundColor: "fff" }}> <CameraOutlinedIcon /> </ItemIcon>
                <ListItemText primary={"Estúdio"} />
              </ListItemButton>


              {/* FOTOS */}
              <ListItemButton onClick={() => navigate("/fotos/inicio")} sx={{ justifyContent: 'center', px: 2.5, }} >
                <ItemIcon className="camera-box-mobile" onClick={handleDrawerOpen} sx={{ backgroundColor: "fff" }}> <PhotoLibraryOutlinedIcon /> </ItemIcon>
                <ListItemText primary={"Fotos"} />
              </ListItemButton>


              {/*========================= LOG ========================= */}
              <ListItemButton onClick={handleDrawerOpenLog}
                sx={{ "&.MuiButtonBase-root": { bgcolor: openLog ? "#DCC6B7" : 'transparent' }, "&.MuiButtonBase-root:hover": { bgcolor: "#DCC6B7" }, minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                <ItemIcon className="camera-box-mobile" onClick={handleDrawerOpenLog} sx={{ backgroundColor: "fff" }}> <ArticleOutlinedIcon /> </ItemIcon>
                <ListItemText primary={"Log"} />
                {openLog ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>


              <Collapse in={openLog} timeout="auto" unmountOnExit>
                <List component="div" style={{ paddingLeft: "20%" }} dense >

                  {[34, 258, 213, 20].includes(user.id_usuario) &&
                    <ListItemButton key={"Acessos"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA" }, }} >
                      <ListItemText primary={"Acessos"} onClick={() => navigate("/log/acessos")} />
                    </ListItemButton>
                  }

                  {user.id_usuario == '258' &&
                    <ListItemButton key={"Atualizações"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA" }, }} >
                      <ListItemText primary={"Atualizações"} onClick={() => navigate("/log/atualizacoes")} />
                    </ListItemButton>
                  }

                  <ListItemButton key={"Visíveis"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA" }, }} >
                    <ListItemText primary={"Visíveis"} onClick={() => navigate("/log/visiveis")} />
                  </ListItemButton>

                  <ListItemButton key={"Marketplace"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA" }, }} >
                    <ListItemText primary={"Marketplace"} onClick={() => navigate("/log/marketplace")} />
                  </ListItemButton>

                  <ListItemButton key={"Resumo diários"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA" }, }} >
                    <ListItemText primary={"Resumo diários"} onClick={() => navigate("/log/resumo-diario")} />
                  </ListItemButton>

                  <ListItemButton key={"Atraso ofertados"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA" }, }} >
                    <ListItemText primary={"atraso ofertados"} onClick={() => navigate("/log/atraso-ofertados")} />
                  </ListItemButton>

                  <ListItemButton key={"Scraping"} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "#F4EEEA" }, }} >
                    <ListItemText primary={"Scraping"} onClick={() => navigate("/log/scraping")} />
                  </ListItemButton>

                </List>
              </Collapse>


              <div className="footerDrawer">
                <hr />

                <ListItemButton key={"Sair"} className="logout">
                  <ItemIcon sx={{
                    minWidth: "fit-content !important"
                  }}>
                    <LogoutIcon />
                  </ItemIcon>
                  <ListItemText primary={"sair"} onClick={userLogout} />
                </ListItemButton>
              </div>

            </List>
          </Box>

        </Drawer>
        {/* </React.Fragment>
        ))} */}
        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: "#fff" }}>
          <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#fff", alignItems: "center", gap: "5%" }}>
            <Typography style={{ color: "#212424", fontWeight: "700", fontSize: "20px" }} >FAROL</Typography>



            {gamefication === false && marcas.length > 1 ?
              (<div>
                <FormControl variant="outlined" className="select select-1">
                  <ThemeProvider theme={theme} >
                    <Select
                      displayEmpty
                      onChange={handleChangeMarcas}
                      name="marcas"
                      value={marcaSelecionadaTotal ? marcaSelecionadaTotal : ''}
                    >

                      {marcas.map((item) => {
                        return (
                          <MenuItem key={item} value={item} onClick={() => handleClickItems(item)}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </ThemeProvider>
                </FormControl>
              </div>) :
              (<div>
                {user.marcaSelecionada == 'Animale' && <img src={imgAnimale} />}
                {user.marcaSelecionada == 'FARM' && <img width="60" src={imgFarm} />}
                {user.marcaSelecionada == 'FOXTON' && <img width="80" src={imgFoxton} />}
                {user.marcaSelecionada == 'FABULA' && <img width="60" src={imgFabula} />}
                {user.marcaSelecionada == 'MARIA FILO' && <img width="60" src={imgMaria} />}
                {user.marcaSelecionada == 'CRIS BARROS' && <img width="60" src={imgCris} />}
                {user.marcaSelecionada == 'BYNV' && <img width="70" src={imgNV} />}
                {user.marcaSelecionada == 'OFF' && <img width="70" src={imgOFF} />}
                {marcas.length > 1 && <img width="60" src={imgSoma} />} </div>)}
          </Grid>

        </Box>
      </Box >
      <Box >
        <div open={open}>
          {body}
        </div>
      </Box>
    </div >
  );
}