import * as React from 'react';
import { format_data_full } from '../../../utils/functions';

export default function RowAcessos({ row }) {
  return (<>
    <tr>
      <td className=''> {row.usuario}</td>
      <td className=''> {row.sistema}</td>
      <td className=''> {format_data_full(row.created_at)}</td>
    </tr>
  </>);
}
