import React from "react";

import { Grid, Card } from "@material-ui/core";
import "./styles.css";
import { formatPrice, selRefs } from "../../../utils/functions";

import Tooltip from "../Tooltip/Tooltip";

export default function CardGenericoSearch({ iten, tipo }) {

  let valor = iten.ESTOQUE * iten.PRECO_ECOMMERCE;
  valor = formatPrice(valor);

  return (
    <>
      <Grid direction="row" item xs={12} sm={4} value={iten.REF} id={`elemento_${iten.REF}`}>
        <Card className="root-card2" variant="outlined">

          <Grid container direction="row" spacing={1} >
            <div className="tamanho-card">
              <div className="topo-card-generico">
                <div >
                  <p className="title-card-2">Referência</p>
                  <p className="value-1">{iten.REF}</p>
                </div>
                <div>

                  {tipo == "Não visível" &&
                    <label className="custom-checkbox">
                      <input className="input-check-visivel" type="checkbox" value={iten.REF} id={iten.REF} onClick={(el) => { selRefs(iten.REF, "refSelecionadasVisivel", el.target.checked, 'btn-tornar-visivel') }} />
                      <span className="checkmark"></span>
                    </label>
                  }

                </div>
              </div>

              <div className="white-space-3"></div>

              <div className="box-skus-1">
                <div className="box-skus-2">
                  <p className="title-2">Qtd. de Skus:</p>
                  <p className="value-2">{iten.qtd_skus}</p>
                </div>

                <div className="box-skus-2">
                  <p className="title-2">Qtd. no estoque:</p>
                  <p className="value-2">{iten.ESTOQUE}</p>
                </div>

                <div className="box-skus-2">
                  <p className="title-2">Receita:</p>
                  <p style={{ width: "100%" }} className="value-2">{valor}</p>
                  {iten.COMENTARIO && <Tooltip texto={iten.COMENTARIO} />}
                </div>


              </div>
            </div>
          </Grid>

        </Card>
      </Grid>
    </>
  );
}
