import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import "./ModalGenerico.css"


export default function ModalGenerico({ titulo, texto }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <>
      <MessageOutlinedIcon onClick={handleOpen} className="cursor" />
      <Modal keepMounted open={open} onClose={handleClose}  >
        <Box className="modal-generico">

          <div className="icone-fechar"> <CloseRoundedIcon onClick={handleClose} /> </div>
          <p className='titulo-modal-generico'>{titulo}</p>
          <p className='texto-modal-generico'>{texto}</p>
        </Box>

      </Modal>
    </>
  );
}