import React, { useState, useEffect } from "react";
import "./dropMoverFotos.css"
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { getSessionStorage, removeSessionStorage } from "../../../utils/sessionStorage";
import { getBucketName, id_marca, buscaGeral } from "../../../utils/functions";
import axios from "axios";
import conf from "../../../utils/.params.js";

export default function DropMoverFotos({ termoBusca, tipoTela, pastasSelecionadas, setPastasSelecionadas, stateRefSelecionadas, setStateRefSelecionadas, setModif, setBusca, setLoading }) {

  const user = getSessionStorage("user");
  const [open, setOpen] = useState(false);
  const marca = (user.marcaSelecionada).replace(/ /g, "");
  const diretorios_origem = ['sem_tratamento', 'em_tratamento', 'tratadas', 'upload_pendentes', 'upload_enviadas', 'upload_erros'];
  const [destino, setDestino] = useState(false);
  const [itens, setItens] = useState(false);


  useEffect(async () => {
    setOpen(false)

    let fotos = getSessionStorage(`fotos_selecionadas_${marca}`)
    fotos = fotos ? fotos : [];
    const itens = fotos.concat(pastasSelecionadas);

    if (!itens || itens.length == 0) { return; }
    setItens(itens)
    let foo = itens[0].hasOwnProperty('path') ? itens[0].path : itens[0];
    foo = String(foo).split("/");
    foo.shift();
    const ultimo = foo.pop();
    if (!ultimo) { foo.pop() }

    setDestino(foo.join("/"))
  }, [pastasSelecionadas, stateRefSelecionadas]);



  const handlerMoverFotos = async (destino) => {


    // PARA VREMOVER ESPAÇOS ENTRES AS BARRAS QUE FORMAM O PATH
    destino = destino.replace(/ /g, "");

    // so pra garantir
    if (!itens || itens.length == 0) { return; }

    const idToast = toast.loading("Aguarde um pouco...")

    const idMarca = await id_marca(marca);
    let sucesso = false;
    let erro = false;


    for (let el of itens) {
      el = el.hasOwnProperty('path') ? el.path : el;
      const pathOrigem = el.replace(`https://storage.googleapis.com/${getBucketName(marca)}/`, "")

      let config = { method: 'put', url: `${conf.url_fotos()}/pictures/move/${idMarca}`, headers: { Authorization: "Bearer " + user.tokenFarol }, data: { "pathOrigem": pathOrigem, "pathDestino": destino } };
      await axios.request(config).then(result => { sucesso = true; }).catch(error => { erro = true; console.log(error); });
    };


    if (sucesso) {

      if (termoBusca) {
        buscaGeral(termoBusca, setPastasSelecionadas, setBusca, setLoading, "FOTOS")
      } else {
        removeSessionStorage(`fotos_selecionadas_${marca}`);
        setPastasSelecionadas([])
        setStateRefSelecionadas(false)
        setModif(Math.random(9999))
      }
      toast.update(idToast, { render: "Itens movidos com sucesso!", type: "success", isLoading: false, autoClose: 3000 });
    }

    if (erro) {
      toast.update(idToast, { render: "Erro ao mover!", type: "error", isLoading: false, autoClose: 5000 });
    }


  }





  return (<>
    < div class="dropdown" >
      <DriveFileMoveOutlinedIcon onClick={() => setOpen(open ? false : true)} className="cursor" />
      {open &&
        <div class="dropdown-content" >
          <p className='titulo-drop-mover-fotos'>Mover para:</p>
          {diretorios_origem.map((item) => {
            if (tipoTela != item) {

              let foo = `${item}/${destino}/`
              foo = foo.replaceAll("//", "/").replaceAll("/", " / ");

              return <a className='iten-drop-mover-fotos' onClick={() => handlerMoverFotos(foo)}><FolderOutlinedIcon className="m-r-4" />{foo}</a>
            }
          })}

        </div>
      }
    </div >

  </>);
};



