import { useState, useEffect, useContext } from "react";
import * as React from "react";
import "./principal.css";
import CardGenerico from "../../../components/Gestao/CardGenerico/CardGenerico";
import CardGenericoMarket from "../../../components/Gestao/CardGenericoMarket/CardGenericoMarket";
import { Typography, Grid, Box, Modal } from "@material-ui/core";
import Container from "@mui/material/Container";
import Grafico from "../../../components/Gestao/Grafico";
import { defaultRequest, formatPrice, montarTelaPrincialGestao } from "../../../utils/functions";
import CardOffer from "../../../components/Gestao/CardOffer/CardOffer";

import { Skeleton } from "@mui/material";
import ScrollSelect from "../../../components/Gestao/ScrollSelect/ScrollSelect";
import ModalReferencias from "../../../components/Gestao/Modal/ModalReferencias";

import ModalPageNew from "./../../../components/Gestao/ModalPageNew/ModalPageNew";
import MenuFarolDeskMobile from "./../../../components/Gestao/MenuFarol/MenuDeskMobile";
import CardSearch from "../../../components/Gestao/CardSearch/CardSearch";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import { UserContext } from "../../../contexts/UserContext";
import ButtonModal from "../../../components/Gestao/Modal/ButtonModal";
import CardOfertadosNaoOfertados from "../../../components/Gestao/CardOfertadosNaoOfertados/CardOfertadosNaoOfertados";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";

export default function GestaoPrincipal() {

  const { email } = useContext(UserContext);
  const [params, setParams] = useState({ filtro_lancados: false, filtro_colecao: false, filtro_marca: false });
  const route = "/dadosGestao";
  const [dados, setDados] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [busca, setBusca] = useState(false);
  const [referencias, setReferencias] = useState(false);
  const [totalDeflatorSeller, setTotalDeflatorSeller] = useState(0);
  const [totalDeflatorMarca, setTotalDeflatorMarca] = useState(0);

  useEffect(() => {
    sessionStorage.removeItem('params')
    defaultRequest(route, params).then(function (response) {
      montarTelaPrincialGestao(response, setDados, setLoading);
    }).catch((error) => {
      console.log(error.message);
      return;
    });
  }, []);


  const handleOpenModal = (atributo, titulo) => {

    document.getElementById('inputBuscaModal').value = '';

    setTitleModal(titulo);
    setReferencias(false);
    setOpenModal(true);
    defaultRequest("/filtroProdutos", { atributo: atributo }).then(function (response) {
      setReferencias(response);
      setLoading(false);

      if (titulo == "Indisponibilidade devido a deflator") {
        let totalSeller = 0
        let totalMarca = 0

        response.forEach(element => {
          if (element.deflator_marca) {
            totalMarca += element.valor_em_estoque;
          } else {
            totalSeller += element.valor_em_estoque;
          }
        });

        setTotalDeflatorMarca(totalMarca)
        setTotalDeflatorSeller(totalSeller)
      }

    })
      .catch((error) => {
        console.log(error.message);
        return;
      });
  };


  return (
    <>
      <MenuFarolDeskMobile
        title={<HeaderDesk atualizado={dados.atualizado} setBusca={setBusca} tipoBusca={"GESTAO"} setLoading={setLoading} tipoTela={'PRINCIPAL'} title={"Resumo Diário"} enderecoVoltar={"/gestao"} />}

        body={<>
          <ModalPageNew />
          <Container maxWidth="sm" className="container">
            <InfoMenuMobile title={"Estoque ofertado"} setBusca={setBusca} tipoBusca={"GESTAO"} setLoading={setLoading} atualizado={dados.atualizado} tipoTela={true} enderecoVoltar={"/gestao"} />
            <div className="divider-top" />

            {!busca ? (<>
              <div className="box-top">
                <ScrollSelect colecoes={dados.colecoes} params={params} setParams={setParams} setDados={setDados} setLoading={setLoading} tela={'principal'} route={route} pacotes={dados.pacotes} />
              </div>

              <div className="box">
                <Grid container alignItems="center" >
                  <Grid xs={12} md={6} container >
                    <Grid container className="box-individual">
                      {isLoading ? (<Skeleton className="skeleton-format" variant="rounded" sx={{ height: 'inherit' }} />) : (<>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography className="titulo-grafico"> Estoque total da marca</Typography>

                          <div className="graph-sku">
                            <Grafico part1={dados.ofertadosPorcentagem} part2={dados.naoOfertadosPorcentagem} tipoTela={1} id={'principal'} />

                            <div className="div-sku">

                              <div className="white-space-2">
                                <Typography className="sub-titulo-1"> Qtd. de Referências</Typography>
                                <Typography className="sub-titulo-2"> {dados.qtdRefsTotal} </Typography>
                              </div>

                              <div className="white-space-2">
                                <Typography className="sub-titulo-1"> Qtd. de SKU</Typography>
                                <Typography className="sub-titulo-2"> {dados.qtdSkusTotal} </Typography>
                              </div>

                              <div className="white-space-2">
                                <Typography className="sub-titulo-1"> Receita </Typography>
                                <Typography className="sub-titulo-2"> {formatPrice(dados.receitaTotal)} </Typography>
                              </div>
                            </div>

                          </div>
                        </div>
                      </>)}
                    </Grid>
                  </Grid>

                  <div className="white-space-1" />

                  <Grid xs={12} md={6} container>
                    <CardOfertadosNaoOfertados
                      email={email}
                      porcentagem={dados.naoOfertadosPorcentagem}
                      qtd={dados.naoOfertados}
                      qtdRefs={dados.naoOfertadosQtdRefs}
                      valor={dados.naoOfertadosReceita}
                      cor={"red"}
                      texto={"Não ofertados"}
                      isLoading={isLoading}
                    />
                    <div className="divider-2" />
                    <CardOfertadosNaoOfertados
                      email={email}
                      porcentagem={dados.ofertadosPorcentagem}
                      qtdRefs={dados.ofertadosRefs}
                      qtd={dados.ofertados}
                      valor={dados.ofertadosReceita}
                      cor={"green"}
                      texto={"Ofertados"}
                      isLoading={isLoading}
                    />

                  </Grid >

                </Grid >
              </div >

              <div className="white-space-1" />

              <div className="titulo-principal-gestao"> Estoque total do Marketplace
                <ButtonModal titleModal={"Estoque total do Marketplace"} textModal={"Estoque total para venda que está disponível nos sellers do marketplace."} />
              </div>

              <div className="box-individual-market">
                {isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={250} />) : (<>
                  <div className="box-principal-market">
                    <div className="box-skus-market">
                      <div className="titulo-market"> Qtd. de SKUs:  </div>
                      <div className="titulo-receita-market"> {dados.totalMarketplace} </div>
                    </div>
                    <div className="box-skus-market">
                      <div component="h2" className="titulo-market"> Receita: </div>
                      <div className="titulo-receita-market"> {formatPrice(dados.totalMarketplaceReceita)} </div>
                    </div>
                  </div>
                </>)}
              </div>

              <Grid container spacing={1} >

                <CardGenericoMarket
                  id={'nao_ofertados_marketplace'}
                  refs={dados.naoOfertadosMarketplaceRefs}
                  SKU={dados.naoOfertadosMarketplace}
                  Income={formatPrice(dados.naoOfertadosMarketplaceReceita)}
                  tamanho={6}
                  isLoading={isLoading}
                  acaoDoCard={'nao_ofertados_marketplace'}
                />

                <CardGenericoMarket
                  id={'ofertados_marketplace'}
                  refs={dados.ofertadosMarketplaceRefs}
                  SKU={dados.ofertadosMarketplace}
                  Income={formatPrice(dados.ofertadosMarketplaceReceita)}
                  tamanho={6}
                  isLoading={isLoading}
                  acaoDoCard={'tela_ofertados'}
                />
              </Grid>

              <div className="white-space-2" />

              {dados.todosProdutosOfertados ? (
                <CardOffer texto={"Todos os produtos estão ofertados"} />) : (<>
                  <span className="titulo-principal"> Principais atributos não ofertados por receita </span>
                  <div className="white-space-2" />
                  <Grid container spacing={1} >
                    {isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={250} />) : (<>

                      {dados.top_6.map((iten) => {
                        return (
                          <CardGenerico
                            id={iten.nome}
                            titulo={iten.titulo}
                            SKU={iten.qtd}
                            refs={iten.qtdRef}
                            Income={iten.receita}
                            badge={iten.badge}
                            badgeText={iten.badgeText}
                            handleOpenModal={handleOpenModal}
                            isLoading={isLoading}
                          />
                        );
                      })}
                    </>)}
                  </Grid>
                </>)}


            </>) : (<>
              <div className="white-space-2" />
              <Grid container spacing={1} >
                {busca.map((iten) => { return (<CardSearch iten={iten} />); })}
              </Grid>
            </>)}
          </Container >
        </>} />

      < ModalReferencias 
      open={openModal} 
      referencias={referencias} 
      setReferencias={setReferencias} 
      titleModal={titleModal} 
      setOpenModal={setOpenModal} 
      setLoading={setLoading} 
      isLoading={isLoading} 
      totalDeflatorSeller={totalDeflatorSeller} 
      totalDeflatorMarca={totalDeflatorMarca} 
      />
    </>);
}
