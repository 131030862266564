import * as React from 'react';
import { formatPrice, habilitarBotoes, desabilitarBotoes, formataBotoes } from '../../../utils/functions';
import "./Tabela.css"
import { getSessionStorage, setSessionStorage, removeSessionStorage } from "../../../utils/sessionStorage";
import { useEffect } from "react";
import ModalGenerico from '../ModalGenerico/ModalGenerico';

export default function Row({ row, checkedState, handleChange }) {

  let foto = row.images_ecom ? "OK" : row.FOTO ? row.FOTO : "NÃO"
  let chip_categoria = row.category ? "chip-upload chipSuccess" : "chip-upload chipError"
  let chip_foto = foto == "OK" ? "chip-upload chipSuccess" : foto == "NÃO" ? "chip-upload chipError" : "chip-upload chipWarning"
  let chip_estoque = row.availablequantity > 0 ? "chip-upload chipSuccess" : "chip-upload chipError";
  let chip_ativo = row.active ? "chip-upload chipSuccess" : "chip-upload chipError";
  let chip_visivel = row.visible ? "chip-upload chipSuccess" : "chip-upload chipError";


  useEffect(() => {
    const sel = getSessionStorage(`refSelecionadasPacoteUpload_${getSessionStorage("user").marcaSelecionada}`);
    if (sel) {
      if (sel.includes(row.REFID)) {
        document.getElementById(row.REFID).setAttribute('checked', true)
        formataBotoes("adicionar-estilo")
        habilitarBotoes(2)
      }
    }
  }, []);


  const selRefPacote = (e) => {
    const marca = getSessionStorage("user").marcaSelecionada;
    const chave = `refSelecionadasPacoteUpload_${marca}`
    const element = e.target;

    const value = element.getAttribute("value");
    let ret = null;
    let storage = getSessionStorage(chave);

    if (storage) {
      if (element.checked) {
        storage.push(value);
      } else {
        let index = storage.indexOf(value);
        storage.splice(index, 1);
        element.checked = false;
      }
      ret = storage.length == 0 ? '' : storage;
    } else {
      ret = [value];
    }

    if (ret) {
      setSessionStorage(chave, ret);
      habilitarBotoes(2)
      formataBotoes("adicionar-estilo")
    } else {
      removeSessionStorage(chave);
      desabilitarBotoes()
      formataBotoes("remover-estilo")
    }

  };


  return (<>
    <tr key={`${row.REF}_${row.ESTOQUE}`}>
      <td className='referencia-linha'>
        <label className="custom-checkbox">
          <input type="checkbox" value={row.REFID} id={row.REFID} onClick={selRefPacote} checked={checkedState} onChange={handleChange} />
          <span className="checkmark"></span>
          <div className='referencia-numero'>{row.REF} {row.COMENTARIO && <ModalGenerico titulo={'COMENTÁRIO'} texto={row.COMENTARIO} />}</div>
        </label>
      </td>
      <td> <div className="borda-primeira">{formatPrice(row.PRECO_ECOMMERCE * row.ESTOQUE)}</div> </td>
      <td> <div className="borda-horizontal">{row.COLECAO}</div> </td>
      <td> <div className='borda-horizontal'><span className={chip_categoria} >{row.category ? 'OK' : 'NÃO'}</span></div> </td>
      <td> <div className="borda-horizontal"><span className={chip_foto} >{foto}</span></div> </td>
      <td> <div className="borda-horizontal"><span className={chip_estoque} >{row.availablequantity ? "OK" : "NÃO"}</span></div></td>
      <td> <div className="borda-horizontal"><span className={chip_ativo} >{row.active ? 'OK' : 'NÃO'}</span></div></td>
      <td> <div className="borda-horizontal"><span className={chip_visivel} >{row.visible ? 'OK' : 'NÃO'}</span></div></td>
    </tr>
  </>);

}
