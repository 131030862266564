import "./Podium.css";

import { chooseLogoByBrand } from '../../../utils/functions';
// import { chooseLogoByBrand } from '../../../utils/functions';

export default function gamefication({ data }) {
    const brand = data ? data.brand : null;
    const percentage = data ? data.percentage : null;
    const position = data ? data.position : 0;

    const Logo = chooseLogoByBrand(brand);

    return (
        <div className="principalCircle">
            <img src={Logo} alt="logo" className={position === 1 ? "imageFirst" : "image"}/>

            <div className={position === 1 ? "circleFirst" : position === 2 ? "circleSecond" : "circleThird"}>
                {`${position}º`}
            </div>

            <h6 className="title">
                {brand}
            </h6>

            <div className="percentage">
                {`${percentage ? (percentage).toFixed(2) : '0.00'} %`}
            </div>
        </div>
    );

}