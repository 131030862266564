import './ButtonGenerico.css';
import './ButtonBackup.css';

export default function ButtonGenerico({ texto, click, load, ativo, id }) {

  return (
    <button className="button button-backup" onClick={click} id={id} disabled={load}>
      {load == true ? (< div className="loader"></div>) : (texto)}
    </button >
  );
}

