import "./modalFotos.css";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import FotoUpload from "../../../components/Fotos/FotoUpload/FotoUpload";
import LinearProgress from "../../../components/LinearProgress/LinearProgress";
import { Grid } from "@mui/material";
import ButtonGenerico from "../../../components/Gestao/Button/ButtonGenerico";
import { id_marca, limparNomeFoto, resizeFile } from "../../../utils/functions";
import { getSessionStorage } from "../../../utils/sessionStorage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import conf from "../../../utils/.params.js";
import axios from "axios";
import ModalFotosIguais from "../../../components/Fotos/ModalFotos/ModalFotosIguais";

export default function ModalFotos({ ref_fotos, titleModal, setModif, diretorio, openModalFotos, setOpenModalFotos }) {

  const [progresso, setProgresso] = useState(0);
  const [uploadFotos, setUploadFotos] = useState([]);
  const [qtdFotos, setQtdFotos] = useState(0);
  const [partQtdFotos, setPartQtdFotos] = useState(0);
  const [load, setLoad] = useState(false);
  const [validNomeFotos, setValidNomeFotos] = useState(false);
  const [fotosIguais, setFotosIguais] = useState(false);
  const [openModalFotosIguais, setOpenModalFotosIguais] = useState(false);
  const user = getSessionStorage("user");
  const marca = user.marcaSelecionada.replace(/ /g, "");

  const handleClose = () => {
    setOpenModalFotos(false);
    let list = new DataTransfer();
    document.getElementById("selecao-arquivo").files = list.files;
    document.getElementById("selecao-arquivo").value = "";
    setUploadFotos([]);
    setQtdFotos(0);
    setPartQtdFotos(0);
    setProgresso(0);
    setValidNomeFotos(false);
    setFotosIguais(false);
    setOpenModalFotosIguais(false);
  };

  const handleChangeInputFotos = async () => {
    setUploadFotos([]);
    const selectedFile = document.getElementById("selecao-arquivo").files;
    setQtdFotos(selectedFile.length);

    setProgresso(25);
    let arr = [];

    for (let img of selectedFile) {
      let nomeFoto = await limparNomeFoto(img.name);

      let foo = nomeFoto.split(".");
      foo.pop();
      foo = foo.join(".");

      const found = ref_fotos.includes(foo);

      let flag = false;
      if (!fotosIguais && found) {
        setFotosIguais(true);
        setOpenModalFotosIguais(true);
        flag = true;
      }

      arr.push({
        url: URL.createObjectURL(img),
        name: nomeFoto,
        flag: flag,
        file: img,
      });
    }

    arr = uploadFotos.concat(arr);
    setUploadFotos(arr);

    setProgresso(100);
    setQtdFotos(arr.length);
    setPartQtdFotos(arr.length);
  };

  const handleRemoveItem = (e) => {
    let list = new DataTransfer();
    document.getElementById("selecao-arquivo").value = "";

    let foo = uploadFotos.filter((item) => {
      if (item.url != e) {
        let file = new File(["content"], item.url);
        list.items.add(file);
        return item;
      }
    });

    document.getElementById("selecao-arquivo").files = list.files;

    setUploadFotos(foo);
    setQtdFotos(foo.length);
    setPartQtdFotos(foo.length);
    setProgresso(foo.length > 0 ? 100 : 0);
  };

  const handleRemoverFotosNomeIncorreto = () => {
    let list = new DataTransfer();
    document.getElementById("selecao-arquivo").value = "";

    let foo = uploadFotos.filter((item) => {
      if (item.validation) {
        let file = new File(["content"], item.url);
        list.items.add(file);
        return item;
      }
    });
    document.getElementById("selecao-arquivo").files = list.files;

    setUploadFotos(foo);
    setQtdFotos(foo.length);
    setPartQtdFotos(foo.length);
    setProgresso(foo.length > 0 ? 100 : 0);
    setValidNomeFotos(false);
  };

  const handleEnviarFotos = async () => {
    if (uploadFotos.length == 0) {
      return;
    }

    setProgresso(0);
    setPartQtdFotos(0);
    setQtdFotos(uploadFotos.length);
    // const valor_progresso = parseInt((100 / parseInt(uploadFotos.length)).toFixed(0));

    const user = getSessionStorage("user");
    let config = {
      method: "post",
      url:
        conf.url_fotos() +
        `/pictures/newFile/${await id_marca(marca)}?path=${diretorio}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + user.tokenFarol,
      },
    };

    setLoad(true);
    const idToast = toast.loading("Aguarde um pouco...");

    var formData = new FormData();

    var contador = 0;
    let ret;
    console.time("teste")

    for (let obj of uploadFotos) {
      // PARA VALIDAR SE O USUÁRIO QUER MANTER AS FOTOS OU SUBSTITUI-LAS NO SERVIDOR
      if (obj.flag && !fotosIguais) {
        continue;
      } else {
        contador++;

        // // const image = obj.file;
        // // let nomeFoto = await limparNomeFoto(image.name);
        
        const image = await resizeFile(obj.file, 3200);
        let nomeFoto = await limparNomeFoto(image.name);

        formData.append("files", image, nomeFoto);

        if (contador % 5 === 0 || contador == uploadFotos.length) {
          config.data = formData;

          ret = await axios.request(config);

          formData = new FormData();
          setPartQtdFotos(contador);
          setProgresso(parseInt((100 / parseFloat(uploadFotos.length)).toFixed(2)) * contador);
        }
      }
    }
    console.timeEnd("teste")
    setPartQtdFotos(uploadFotos.length);
    setProgresso(100);

    toast.dismiss(idToast);
    if ((!ret) || ret.data.success) {
      toast.success(`Fotos adicionadas com sucesso!`);
      setProgresso(100);
      setModif(Math.random(9999));
    } else {
      toast.warning(`Algumas fotos não foram importadas!`);
    }
    setLoad(false);
    handleClose();
  };

  return (
    <>
      <Modal keepMounted open={openModalFotos} onClose={handleClose}>
        <Box className="modal-fotos">
          <div className="closeIconFotos">
            {" "}
            <CloseRoundedIcon onClick={handleClose} className="cursor" />{" "}
          </div>
          <br />
          <p className="title-modal-fotos"> {titleModal} </p>

          <div className="scroll" style={{ height: "65vh" }}>
            <div className="box-upload-fotos">
              <label className="label-input-file" for="selecao-arquivo">
                <input
                  onChange={handleChangeInputFotos}
                  id="selecao-arquivo"
                  type="file"
                  multiple
                  draggable="true"
                  accept="image/*"
                />
                <spam className="box-upload-fotos-2">
                  <p className="texto-upload-fotos">
                    <div>
                      {" "}
                      <DriveFolderUploadRoundedIcon className="icone-upload-fotos" />{" "}
                    </div>
                    <strong>Clique aqui </strong>
                    para selecionar fotos
                    <br />
                    <p className="texto-upload-fotos-2">
                      {" "}
                      formatos aceitáveis: .png, .jpg, .jpeg{" "}
                    </p>
                  </p>
                </spam>
              </label>
            </div>

            <LinearProgress
              progresso={progresso}
              part={partQtdFotos}
              total={qtdFotos}
            />
            {validNomeFotos && (
              <div className="box-nome-incorreto">
                <span className="w-100">
                  Uma ou mais fotos estão nomeadas incorretamente{" "}
                </span>
                <span
                  className="remover-fotos-nome-incorreto"
                  onClick={handleRemoverFotosNomeIncorreto}
                >
                  Remover
                </span>
              </div>
            )}

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              justifyContent="flex-start"
              alignItems="center"
              className="box-fotos"
              id="box-fotos"
            >
              {uploadFotos &&
                uploadFotos.map((item) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2}
                      className="p-card-foto m-box-15"
                    >
                      <FotoUpload
                        handleRemoveItem={handleRemoveItem}
                        item={item}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </div>

          <div className="box-btn-add-foto-modal-2">
            <ButtonGenerico
              texto={"ADICIONAR FOTOS"}
              click={handleEnviarFotos}
              load={load}
              ativo={uploadFotos.length == 0 ? false : true}
              id={"btn_evniar_fotos"}
            />
          </div>
        </Box>
      </Modal>

      <ModalFotosIguais
        fotosIguais={fotosIguais}
        setFotosIguais={setFotosIguais}
        openModalFotosIguais={openModalFotosIguais}
        setOpenModalFotosIguais={setOpenModalFotosIguais}
      />
    </>
  );
}
