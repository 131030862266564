import React, { useState, useEffect } from "react";
import "./../../../pages/Fotos/style.css";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";
import ModalConfirmacao from "../../../components/Fotos/ModalFotos/ModalConfirmacao";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Container from "@mui/material/Container";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import semFotos from "../../../static/sem-fotos.png";
import ModalFotos from "../../../components/Fotos/ModalFotos/ModalFotos";
import ModalNovaPasta from "../../../components/Fotos/ModalFotos/ModalNovaPasta";
import ModalPastaFotoMobile from "../../../components/Fotos/ModalFotos/ModalPastaFotoMobile";
import BuscaFotos from "../../../components/Fotos/Busca/BuscaFotos";
import DropPastaFotos from "../../../components/Fotos/DropPastaFotos/DropPastaFotos";
import FotosProduto from "../../../components/Fotos/FotosProduto/FotosProduto";
import { handleClickTodasFotos, handlerDonwloadFotos, requestSomaStudio, montarTelaFotos, qtdSelecionadas, diretorioOrigem } from "../../../utils/functions";
import { removeSessionStorage, getSessionStorage } from "../../../utils/sessionStorage";
import ModalDetalheProduto from "../../../components/Fotos/ModalFotos/ModalDetalheProduto";
import Diretorio from "../../../components/Fotos/Diretorio/Diretorio";
import DropMoverFotos from "../DropMoverFotos/DropMoverFotos";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Skeleton } from "@mui/material";
import EditarNome from "../../../components/Fotos/EditarNome/EditarNome";


export default function PaginaFotos({ titulo, diretorio }) {

    const params = new URLSearchParams(window.location.search);
    let diretorioBusca = params.get('diretorioBusca');
    params.delete("diretorioBusca");
    const [diretorioAtual, setDiretorioAtual] = useState(diretorioBusca ? diretorioBusca.split('/') : [diretorio]);


    // const [diretorioAtual, setDiretorioAtual] = useState([diretorio]);

    const [fotos, setFotos] = useState(false);
    const [diretorios, setDiretorios] = useState(false);
    const [dados, setDados] = useState(false);
    const [ref_fotos, setRef_fotos] = useState(false);
    const [busca, setBusca] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [stateRefSelecionadas, setStateRefSelecionadas] = useState(false);
    const [open, setOpen] = useState(false);
    const [openModalFotos, setOpenModalFotos] = useState(false);
    const [openModalPasta, setOpenModalPasta] = useState(false);
    // const [openModalEditarNome, setOpenModalEditarNome] = useState(false);
    const [openModalPastaFotoMobile, setOpenModalPastaFotoMobile] = useState(false);
    const [fotosModalDetalhe, setFotosModalDetalhe] = useState([]);
    const [modif, setModif] = useState(false);
    const [pastasSelecionadas, setPastasSelecionadas] = useState([]);

    const marca = getSessionStorage("user").marcaSelecionada;
    const tipoBusca = "FOTOS"



    const handlerOpenModalDetalheFoto = (e) => {
        let novo = [];
        fotos.imagens.map(item => { if (item.reference == e) { novo.push(item.imagens) } })
        setFotosModalDetalhe(novo)
        setOpen(true)
    };

    useEffect(async () => {
        setLoading(false)
        setDados(false);
        setPastasSelecionadas([])
        setStateRefSelecionadas(false)
        setFotos(false)
        await removeSessionStorage(`fotos_selecionadas_${marca}`);
        let ret = await requestSomaStudio("/pictures");
        setDados(ret.data);
        setRef_fotos(ret.ref_fotos);
        await montarTelaFotos(ret.data, diretorioAtual, setFotos, setDiretorios);
        setLoading(true)
    }, [modif]);


    return (<>
        <MenuFarolDeskMobile title={<HeaderDesk setPastasSelecionadas={setPastasSelecionadas} setDiretorioAtual={setDiretorioAtual} setModif={setModif} atualizado={dados.atualizado} setBusca={setBusca} tipoBusca={tipoBusca} setLoading={setLoading} tipoTela={false} title={busca ? "Resultado da busca" : diretorioAtual} enderecoVoltar={"/fotos/inicio"} />}
            body={<>
                <Container maxWidth="sm" className="container">

                    <InfoMenuMobile setPastasSelecionadas={setPastasSelecionadas} title={busca ? "Resultado da busca" : diretorioAtual} setBusca={setBusca} tipoBusca={"FOTOS"} setLoading={setLoading} atualizado={""} setDiretorioAtual={setDiretorioAtual} setModif={setModif} tipoTela={true} enderecoVoltar={"/fotos/inicio"} />

                    <div className="divider-top" />
                    {!busca ? (<>
                        <div className="card-fotos-generico-barra-acao">
                            <div className={`p-l-0  card-fotos-generico-2 card-fotos-generico-3 ${stateRefSelecionadas || (stateRefSelecionadas > 0) || (pastasSelecionadas.length > 0) ? 'card-fotos-generico-ativo' : ''}`}>
                                <div className={`cursor ${stateRefSelecionadas || pastasSelecionadas.length > 0 ? 'text-black display-flex' : 'displayNone'}`}>
                                    <CloseOutlinedIcon className="m-l-5" onClick={() => setModif(Math.random(9999))} />
                                    <p className="m-l-5 card-fotos-generico-titulo">{qtdSelecionadas(stateRefSelecionadas, pastasSelecionadas)}</p>
                                    <p className="m-l-5 card-fotos-generico-titulo">{qtdSelecionadas(stateRefSelecionadas, pastasSelecionadas) ? "Selecionados" : "Selecionado"}</p>
                                </div>

                                <div className={`card-fotos-generico-barra-acao-icones ${qtdSelecionadas(stateRefSelecionadas, pastasSelecionadas) > 0 ? 'text-black' : 'displayNone'}`}>
                                    <FileDownloadOutlinedIcon className="cursor" onClick={() => handlerDonwloadFotos(pastasSelecionadas)} />
                                    <DropMoverFotos tipoTela={diretorio} pastasSelecionadas={pastasSelecionadas} setPastasSelecionadas={setPastasSelecionadas} stateRefSelecionadas={stateRefSelecionadas} setStateRefSelecionadas={setStateRefSelecionadas} setModif={setModif} />
                                    <ModalConfirmacao setModif={setModif} textModal={`Você tem certeza de que deseja excluir esses itens ?`} path={diretorioAtual.join("/")} pastasSelecionadas={pastasSelecionadas} />
                                    <EditarNome setStateRefSelecionadas={setStateRefSelecionadas} stateRefSelecionadas={stateRefSelecionadas} pastasSelecionadas={pastasSelecionadas} setPastasSelecionadas={setPastasSelecionadas} setModif={setModif} />
                                </div>
                            </div>

                            <DropPastaFotos openModalPastaFotoMobile={openModalPastaFotoMobile} setOpenModalPastaFotoMobile={setOpenModalPastaFotoMobile} setOpenModalFotos={setOpenModalFotos} setOpenModalPasta={setOpenModalPasta} pastasSelecionadas={pastasSelecionadas} stateRefSelecionadas={stateRefSelecionadas} />

                        </div>

                        <div className="card-fotos-generico">
                            {!isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={250} />) : (<>
                                {diretorios.length > 0 &&
                                    <Diretorio
                                        diretorios={diretorios}
                                        diretorioAtual={diretorioAtual}
                                        setDiretorioAtual={setDiretorioAtual}
                                        dados={dados}
                                        setFotos={setFotos}
                                        setDiretorios={setDiretorios}
                                        setPastasSelecionadas={setPastasSelecionadas}
                                        pastasSelecionadas={pastasSelecionadas}
                                        setModif={setModif}
                                    />
                                }
                                {fotos && diretorios.length > 0 && <div className="card-fotos-generico-divider" />}
                                <div className="box-fotos" >
                                    {!fotos && diretorios.length == 0 ? (<div className="container-sem-fotos"> <img className="img-sem-fotos" src={semFotos} /> <p className="text-center">Não há fotos {titulo.toLowerCase()}</p></div>) : (<>
                                        {fotos && <>
                                            <div className="m-0 display-flex">
                                                {window.innerWidth > 959 && <Checkbox click={handleClickTodasFotos} classe={"todas_fotos"} value={`todos_produtos_linha_${diretorio}`} />}
                                                <p className="m-l-5 card-fotos-generico-titulo" >Todas:</p>
                                                <p className="m-l-5 card-fotos-generico-titulo text-gray">({fotos.qtdFotos ? fotos.qtdFotos : 0} {fotos.qtdFotos > 1 ? "fotos" : "foto"})</p>
                                            </div>
                                            {fotos.imagens.map((item) => {
                                                return <FotosProduto handlerOpenModalDetalheFoto={handlerOpenModalDetalheFoto} fotos={item} setStateRefSelecionadas={setStateRefSelecionadas} tela={'sem_tratamento'} classe={`todos_produtos_linha_${diretorio}`} />
                                            })}
                                        </>}
                                    </>)}
                                </div>

                            </>)}
                        </div>


                    </>) : (<>
                        <BuscaFotos handlerOpenModalDetalheFoto={handlerOpenModalDetalheFoto} isLoading={isLoading} setLoading={setLoading} retBusca={busca} setDiretorioAtual={setDiretorioAtual} diretorioAtual={diretorioAtual} setBusca={setBusca} />
                    </>)}

                </Container >

                <ModalFotos ref_fotos={ref_fotos} pastasSelecionadas={pastasSelecionadas} titleModal={"Adicionar fotos sem tratamento"} setModif={setModif} diretorio={diretorioAtual.join("/")} openModalFotos={openModalFotos} setOpenModalFotos={setOpenModalFotos} />
                <ModalNovaPasta setModif={setModif} titleModal={"Nova pasta"} diretorioAtual={diretorioAtual} openModalPasta={openModalPasta} setOpenModalPasta={setOpenModalPasta} />
                <ModalPastaFotoMobile openModalPastaFotoMobile={openModalPastaFotoMobile} setOpenModalPastaFotoMobile={setOpenModalPastaFotoMobile} setOpenModalPasta={setOpenModalPasta} setOpenModalFotos={setOpenModalFotos} />
                {open && <ModalDetalheProduto fotos={fotosModalDetalhe} open={open} setOpen={setOpen} />}
            </>} /></>);
}
