import React from "react";
import { Grid, Card } from "@material-ui/core";
import "./CardPequeno.css";
import "react-datepicker/dist/react-datepicker.css";

import "./CardPequeno.css";

export default function CardPequeno({ pecas, chipRed = false, chipGreen = false, valorPecas }) {

  return (
    <>
      <div>
        <Card className="root-card-pequeno" variant="outlined">
          <div>
            <div className="text-top-pequeno">
              <div className="pecas-numero-pac">{pecas}</div>

              {chipRed && <div className="chip-pacote chip-pacote-red">{chipRed}</div>}
              {chipGreen && <div className="chip-pacote chip-pacote-green">{chipGreen}</div>}

            </div>
            <div className="valor-pecas-pac">{valorPecas}</div>
          </div>
        </Card>
      </div>

    </>
  );
}
