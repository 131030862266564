import React, { useState, useEffect } from "react";
import "./diretorio.css"
import { Grid } from "@material-ui/core";
import Container from "@mui/material/Container";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { montarTelaFotos, allowDrop, drag, drop } from "../../../utils/functions";


export default function Diretorio({ setModif, diretorios, diretorioAtual, setDiretorioAtual, dados, setFotos, setDiretorios, setPastasSelecionadas, pastasSelecionadas }) {

  let prevent = 0;
  let timer = 0;

  const handleClick = (path, id) => {

    if (window.innerWidth < 959) {
      handleDoubleClick(id)

    } else {

      timer = setTimeout(() => {

        if (!prevent) {
          let el = document.getElementById(id);
          if (el) {
            el = el.classList;
            if (Object.values(el).includes('cor-normal')) {
              el.remove('cor-normal');
              el.add('cor-selecionada');

              setPastasSelecionadas(prevArray => [...prevArray, path]);
            } else {
              el.add('cor-normal');
              el.remove('cor-selecionada');

              let foo = pastasSelecionadas.filter(item => {
                return item != path;
              })
              setPastasSelecionadas(foo)

            }
          }
        }
        prevent = false;
      }, 150);
    };

  };

  const handleDoubleClick = async (e) => {
    prevent = true;
    let novoArray = [...diretorioAtual, e];
    setDiretorioAtual(novoArray);
    await montarTelaFotos(dados, novoArray, setFotos, setDiretorios);
    clearTimeout(timer);
    setPastasSelecionadas([])
    removerSelecaoPasta();
  }

  const removerSelecaoPasta = async () => {
    let elementos = document.getElementsByClassName('cor-selecionada');
    if (elementos.length > 0) {
      for (let index = 0; index < elementos.length; index++) {
        elementos[index].classList.add('cor-normal')
        elementos[index].classList.remove('cor-selecionada')
      }
    }
  }

  return (<>

    <Container maxWidth="sm" className="container p-l-0">
      <Grid container justifyContent="flex-start" alignItems="center" spacing={4}>

        {diretorios && diretorios.map((item) => {
          const path = `${diretorioAtual.join("/")}/${item}/`;
          return <Grid item xs={12} sm={6} md={2} className="cursor-diretorio" onClick={() => handleClick(path, item)} onDoubleClick={() => handleDoubleClick(item)} >
            <div className="card-diretorio cor-normal" id={item} name="pastas" path={path} draggable="true" onDragStart={(e) => drag(e)} onDrop={(e) => drop(e, setModif)} onDragOver={(e) => allowDrop(e)}>
              <div className="width-100 alingamento-card-diretorio" path={path}>
                <FolderOutlinedIcon path={path} />
                <p className="p-card-diretorio" path={path}>{item}</p>
              </div>
            </div>
          </Grid >
        })}
      </Grid >

    </Container >
  </>);
}

