import React from "react";
import { useState, useContext, useEffect } from "react";
import "./ScrollSelect.css";
import Select from "@material-ui/core/Select";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { getSessionStorage } from "../../../utils/sessionStorage";
import { createTheme } from "@material-ui/core";
import SendGTAGEvent from "../../../utils/sendGTAGEvent";
import { UserContext } from "../../../contexts/UserContext";
import { changeParams, changeSelectScrol } from "../../../utils/functions";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import { Skeleton } from "@mui/material";
// import OutlinedInput from '@mui/material/OutlinedInput';

const useStyles = makeStyles({ root: { "&:hover": { backgroundColor: "#fff !important", }, }, default: { backgroundColor: "inherit !important" } });

const theme = createTheme({
  palette: { primary: { main: "#e5e5e5 !important" } }
});

export default function ScrollSelect({ colecoes, params, setParams, setDados, setLoading, tela, route, pacotes, setDadosTable, dadosOriginais }) {
  const classes = useStyles();
  const { email } = useContext(UserContext);
  const [colecoesSelecionadas, setColecoesSelecionadas] = useState(["Todas"]);
  const [pacotesSelecionados, setPacotesSelecionados] = useState(["Todos"]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let cache = JSON.parse(sessionStorage.getItem("params"));
    if (cache) {
      if (cache.filtro_colecao) {
        setColecoesSelecionadas(cache.filtro_colecao);
      }
      if (cache.filtro_pacotes) {
        setPacotesSelecionados(cache.filtro_pacotes);
      }
    }
  }, []);

  const handleFilter = () => {
    if (colecoesSelecionadas.length === 0 || colecoesSelecionadas.includes("Todas")) {
      setDadosTable(dadosOriginais);
    } else {

      const filteredDados = dadosOriginais.filter(dado => {
        let colecao = dado.COLECAO ? dado.COLECAO : dado.colecao;
        return colecoesSelecionadas.includes(colecao.toUpperCase())
      });

      setDadosTable(filteredDados);
    }
  };

  const handleChangeColecoesTable = (event) => {
    const { target: { value } } = event;

    if (value.indexOf("OK") > -1) { return; }

    if (value.length > 1 && value[0] === "Todas") { value.shift(); }

    setColecoesSelecionadas(value.length === 0 ? ["Todas"] : value);
  };

  const handleChange = async (e) => {
    setLoading(true);
    await changeParams(e, params, setParams, false);
    await changeSelectScrol(setDados, setLoading, route, params, tela);
  };

  const handleClick = async (tipo) => {
    setDados(false);
    setLoading(true);
    await changeParams(false, params, setParams, tipo, tipo == "filtro_colecao" ? colecoesSelecionadas : tipo == "filtro_pacotes" ? pacotesSelecionados : false);
    await changeSelectScrol(setDados, setLoading, route, params, tela);
  };

  const handleChangeColecoes = async (event) => {
    const { target: { value } } = event;

    if (value.indexOf("OK") > -1) { return; }

    if (value.length > 1 && value[0] == "Todas") { value.shift(); }

    let colects = value.length == 0 ? ["Todas"] : value;
    setColecoesSelecionadas(colects);
    await changeParams(false, params, setParams, "filtro_colecao", colects);

    if (value.length == 0) {
      setLoading(true);
      await changeSelectScrol(setDados, setLoading, route, params, tela);
    }
  };

  const handleChangePacotes = async (event) => {
    const { target: { value } } = event;

    if (value.indexOf("OK") > -1) { return; }

    if (value.length > 1 && value[0] == "Todos") { value.shift(); }

    let pacotes = value.length == 0 ? ["Todos"] : value;
    setPacotesSelecionados(pacotes);
    await changeParams(false, params, setParams, "filtro_pacotes", pacotes);

    if (value.length == 0) {
      setLoading(true);
      await changeSelectScrol(setDados, setLoading, route, params, tela);
    }
  };

  return (
    <div className="scroll-menu">
      {!colecoes ? (
        <div className="campo-skeleton">

          <Skeleton className="skeleton-format" variant="rectangular" height={50} />
        </div>) : (<>

          <div>
            <Typography component="h2" className="title-box-select title-box-select-1" > Coleção </Typography>
            <FormControl variant="outlined" className="select select-1 select-scroll-lateral" >
              <InputLabel id="colecoes-multiple-checkbox-label"></InputLabel>
              <Select
                name="filtro_colecao"
                labelId="colecoes-multiple-checkbox-label"
                multiple
                value={colecoesSelecionadas}
                onChange={tela == "scraping" || tela == "atraso_ofertados" ? handleChangeColecoesTable : handleChangeColecoes}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="OK">
                  <button id="btn_sel_colecoes_select" className="btn-sel-colecoes-select" onClick={() => { tela == "log resumo diário" ? handleFilter() : handleClick("filtro_colecao"); }} >
                    Consultar
                  </button>
                </MenuItem>
                {colecoes &&
                  colecoes.map((colecao) => (
                    <MenuItem className={classes.root} key={colecao} value={colecao} >
                      <Checkbox checked={colecoesSelecionadas.indexOf(colecao) > -1} />
                      <ListItemText primary={colecao} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          {tela !== "log resumo diário" && (
            <div>
              <Typography component="h2" className="title-box-select"> Status de lançamento </Typography>
              <FormControl variant="outlined" className="select select-scroll-lateral"  >
                <ThemeProvider theme={theme}>
                  <Select displayEmpty onChange={handleChange} onClick={() => { SendGTAGEvent("resumo_diario_lancados", "filter", "click", email); }}
                    name="filtro_lancados"
                    value={params.filtro_lancados ? params.filtro_lancados : ""}
                  >
                    <MenuItem className={classes.default} value=""> Todos </MenuItem>
                    <MenuItem className={classes.root} value="lancados"> Lançados </MenuItem>
                    <MenuItem className={classes.root} value="naolancados"> Não Lançados </MenuItem>
                  </Select>
                </ThemeProvider>
              </FormControl>
            </div>
          )}

          {tela.toUpperCase() == "ESTUDIO" && (
            <div>
              <Typography component="h2" className="title-box-select">
                Canal{" "}
              </Typography>
              <FormControl variant="outlined" className="select select-scroll-lateral" >
                <ThemeProvider theme={theme}>
                  <Select displayEmpty onChange={handleChange} name="filtro_canal" value={params.filtro_canal ? params.filtro_canal : ""} >
                    <MenuItem className={classes.default} value=""> Todos </MenuItem>
                    <MenuItem className={classes.root} value="ATACADO">Atacado</MenuItem>
                    <MenuItem className={classes.root} value="VAREJO"> Varejo </MenuItem>
                    <MenuItem className={classes.root} value="OUTRO"> Outros </MenuItem>
                  </Select>
                </ThemeProvider>
              </FormControl>
            </div>
          )}

          {getSessionStorage("user").marcaSelecionada == "OFF" && (
            <div>
              <Typography component="h2" className="title-box-select"> Marcas </Typography>
              <FormControl variant="outlined" className="select">
                <Select displayEmpty onChange={handleChange} name="filtro_marca" value={params.filtro_marca ? params.filtro_marca : ""} >
                  <MenuItem className={classes.default} value=""> Todas </MenuItem>
                  <MenuItem className={classes.default} value="ANIMALE"> ANIMALE </MenuItem>
                  <MenuItem className={classes.default} value="ANIMALE JEANS"> ANIMALE JEANS </MenuItem>
                  <MenuItem className={classes.default} value="BYNV"> BYNV </MenuItem>
                  <MenuItem className={classes.default} value="CRIS BARROS"> CRIS BARROS </MenuItem>
                  <MenuItem className={classes.default} value="FABULA"> FABULA </MenuItem>
                  <MenuItem className={classes.default} value="FARM"> FARM </MenuItem>
                  <MenuItem className={classes.default} value="FOXTON"> FOXTON </MenuItem>
                  <MenuItem className={classes.default} value="MARIA FILO"> MARIA FILO </MenuItem>
                  <MenuItem className={classes.default} value="OFF"> OFF </MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          {(tela.toUpperCase() == "PRINCIPAL" ||
            tela.toUpperCase() == "NAOOFERTADOS") && (
              <div>
                <Typography
                  component="h2"
                  className="title-box-select title-box-select-1"
                >
                  {" "}
                  Pacotes
                </Typography>
                <FormControl
                  variant="outlined"
                  className="select select-1 select-scroll-lateral"
                >
                  <InputLabel id="pacotes-multiple-checkbox-label"></InputLabel>
                  <Select
                    name="filtro_pacotes"
                    labelId="pacotes-multiple-checkbox-label"
                    multiple
                    value={pacotesSelecionados}
                    onChange={handleChangePacotes}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    <MenuItem value="OK">
                      {" "}
                      <button
                        id="btn_sel_pacotes_select"
                        className="btn-sel-colecoes-select"
                        onClick={() => handleClick("filtro_pacotes")}
                      >
                        {" "}
                        Consultar{" "}
                      </button>
                    </MenuItem>
                    {pacotes &&
                      pacotes.map((pacote) => (
                        <MenuItem
                          className={classes.root}
                          key={pacote}
                          value={pacote}
                        >
                          <Checkbox
                            checked={pacotesSelecionados.indexOf(pacote) > -1}
                          />
                          <ListItemText primary={pacote} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            )}
        </>
      )}
    </div>
  );
}
