import "./modalImportarDatas.css"
import React, { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';

import ButtonGenerico from "../../../components/Gestao/Button/ButtonGenerico";
import BotaoExportar from "../../../components/Estudio/BotaoExportar/BotaoExportar";
import { defaultRequest } from "../../../utils/functions";
import { getSessionStorage } from "../../../utils/sessionStorage";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Papa from 'papaparse';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function ModalImportarDatas({ titleModal }) {
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [btnAtivo, setBtnAtivo] = useState(false);
    const [btnHabilitado, setBtnHabilitado] = useState(true);
    const [nomeArquivo, setNomeArquivo] = useState('');


    const handleClose = () => {
        setOpen(false);
        document.getElementById("selecao-arquivo").value = '';
        setBtnAtivo(false);
        setNomeArquivo('')
        document.getElementById("selecao-arquivo").value = '';
    }

    const handleChangeInput = () => {
        setBtnAtivo(true);
        const selectedFile = document.getElementById("selecao-arquivo").files;
        setNomeArquivo(selectedFile[0].name)
    }

    const importarCsv = async (e) => {

        const arquivo = document.getElementById("selecao-arquivo").files[0];
        if (!btnHabilitado || !arquivo) {
            return
        }

        setLoad(true)
        setBtnHabilitado(false)
        const idToast = toast.loading("Aguarde um pouco...")

        // Usar a biblioteca para analisar o arquivo
        await Papa.parse(arquivo, {
            header: true, // Define se a primeira linha do CSV é um cabeçalho
            skipEmptyLines: true,
            delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
            complete: (result) => { // Acessar complete após realizar a leitura do CSV

                defaultRequest("/estudio/importar/dataLancamento", { dados: result.data, usuario: getSessionStorage("user").email }).then((value) => {
                    if (value.erro) {
                        toast.update(idToast, { render: value.msg, type: "warning", isLoading: false, autoClose: 10000 });
                    } else {
                        toast.update(idToast, { render: value.msg, type: "success", isLoading: false, autoClose: 3000 });
                    }
                    setBtnHabilitado(true);
                    setLoad(false);
                    handleClose();
                }).catch((err) => {
                    toast.update(idToast, { render: `Erro ao importar CSV: ${err.message}`, type: "error", isLoading: false, autoClose: 10000 });
                    setBtnHabilitado(true)
                    setLoad(false)
                })
            }, error: (error) => { // Acessar error se houver erro na leitura do CSV
                toast.update(idToast, { render: `Erro ao analisar o CSV: ${error.message}`, type: "error", isLoading: false, autoClose: 10000 });
                setBtnHabilitado(true)
                setLoad(false)
            },
        });
    };


    return (<>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />

        <div className="box-btn-modal">
            <BotaoExportar className="btn-exportar" onClick={() => { setOpen(true) }} title={"Importar"} endIcon={<UploadFileIcon />}></BotaoExportar>
        </div>

        <Modal keepMounted open={open} onClose={handleClose} >
            <Box className="modal-datas">
                <div className='closeIconFotos'> <CloseRoundedIcon onClick={handleClose} className="cursor" /> </div>
                <br />
                <p className='title-modal-datas'> {titleModal} </p>

                <div className="box-upload-datas">
                    <label className="label-input-file" for='selecao-arquivo'>
                        <input onChange={handleChangeInput} id='selecao-arquivo' type='file' draggable="true" accept=".csv" />
                        <spam className="box-upload-datas-2">
                            <p className="texto-upload-datas">
                                <div> <DriveFolderUploadRoundedIcon className="icone-upload-datas" /> </div>
                                <strong>Clique aqui </strong>
                                para selecionar arquivo .csv<br />
                                <p className="texto-upload-datas-2"> formatos aceitáveis: .csv </p>
                            </p>
                        </spam>
                    </label>
                </div>
                <p className="texto-upload-data">{nomeArquivo}</p>

                <div className="box-btn-add-foto-modal-2">
                    <ButtonGenerico texto={"ADICIONAR DATAS"} click={importarCsv} load={load} ativo={btnAtivo} id={'btn_evniar_datas'} />
                </div>
            </Box>



        </Modal >
    </>
    );
}