import React from "react";
import MenuFarolDeskMobile from "./../../../components/Gestao/MenuFarol/MenuDeskMobile";
import { Grid } from "@mui/material";
import { defaultRequest, exportarCsv, } from "../../../utils/functions";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import "./acessos.css";
import Row from "./RowAcessos";

export default function Acessos() {
    const [dados, setDados] = useState(false);
    const route = "/log";

    useEffect(() => {
        defaultRequest(route, { tipo: "acessos" }).then(function (response) { setDados(response); }).catch((error) => { console.log(error.message); return; });
    }, []);

    const exportar = async () => { exportarCsv(dados, "LOG_ACESSOS_FAROL") };

    return (
        <>
            <MenuFarolDeskMobile
                title={''}
                body={
                    <div className="card-total">
                        <div className="box-geral">
                            <h2 className="titulo-acesso">LOG DE ACESSOS</h2>
                            <button className="btn-exportar-log" onClick={exportar}>Exportar</button>
                        </div>
                        <div className="divider-pacote" />

                        {dados && (<>
                            <Grid container className="" >
                                <table>
                                    <thead>
                                        <tr className="header-tabela">
                                            <th className="th" >Usuário</th>
                                            <th className="th" >Sistema</th>
                                            <th className="th" >Data</th>
                                        </tr>
                                    </thead>
                                    <tbody> {dados && dados.map((iten) => { return <Row row={iten} />; })}
                                    </tbody>
                                </table>

                            </Grid>

                        </>)}

                    </div >
                }
            />
        </>
    );
}
