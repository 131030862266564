import { createTheme } from "@material-ui/core/styles";
import globalTheme from "./globalTheme";

const { colors } = globalTheme;

const theme = createTheme({
  palette: {
    primary: {
      main: `${colors.primary}`,
      contrastText: `${colors.secondary}`,
    },
    secondary: {
      main: `${colors.secondary}`,
    },
    error: {
      main: `${colors.error}`,
    },
    warning: {
      main: `${colors.warning}`,
    },
    success: {
      main: `${colors.success}`,
    },
  },
});

export default theme;
