import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import img from '../../../static/modalNovidade-new.svg';
import { useState, useEffect } from "react";
import "./ModalPageNew.css"


export default function ModalPageNew() {

  const [openModalPageNew, setOpenModalPageNew] = useState(false);
  const handleCloseModalPageNew = () => setOpenModalPageNew(false);
  const handleCloseIconModalPageNew = () => setOpenModalPageNew(false);
  const url = window.location.pathname;


  useEffect(() => {
    if (!localStorage.getItem('modalAvisoEstudio')) {
      setOpenModalPageNew(true)
      localStorage.setItem('modalAvisoEstudio', true)
    } else if (!localStorage.getItem('modalAvisoGestao')) {
      setOpenModalPageNew(true)
      localStorage.setItem('modalAvisoGestao', true)
    }
  });



  return (
    <div className='div-icon-info cursor'>
      <Modal
        keepMounted
        open={openModalPageNew}
        onClose={handleCloseModalPageNew}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="modalFormat">
          <div className='closeIcon'>
            <CloseRoundedIcon onClick={handleCloseIconModalPageNew} className="cursor" />
          </div>
          <div className="image-modal">
            <img className="img-responsive" src={img} />
          </div>

          {url == '/gestao' || url == '/' ? (
            <>
              <p className="title-modal-pagenew"> Novidade no Farol! </p>
              <p className="text-modal-pagenew"> Agora o gestão e o planejamento estúdio estão em um só lugar. </p>
            </>) : (<>
              <p className="title-modal-pagenew"> Um novo Estúdio para você! </p>
              <p className="text-modal-pagenew"> O estúdio que você conhece está com mais ferramentas para auxiliar você no dia a dia. </p>
            </>
          )}

        </Box>
      </Modal>
    </div>
  );
}