import React, { useState, useEffect } from "react";
import "./../style.css";
import { Grid } from "@material-ui/core";
import Container from "@mui/material/Container";
import MenuFarolDeskMobile from "../../../components/Gestao/MenuFarol/MenuDeskMobile";
import HeaderDesk from "../../../components/Gestao/HeaderDesk/HeaderDesk";
import { requestSomaStudio } from "../../../utils/functions";
import CardInicio from "../../../components/Fotos/CardInicio/CardInicio";
import CardInicioUpload from "../../../components/Fotos/CardInicioUpload/CardInicioUpload";
import InfoMenuMobile from "../../../components/InfoMenuMobile/InfoMenuMobile";
import BuscaFotos from "../../../components/Fotos/Busca/BuscaFotos";
import { getSessionStorage } from "../../../utils/sessionStorage";

export default function inicio() {
  let tipo_ecom = getSessionStorage("user").marcaSelecionada == "FARM GLOBAL" ? "Shopfy" : "VTEX";
  const [busca, setBusca] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const route = "/pictures/inicio";

  const [semTratamento, setSemTratamento] = useState(false);
  const [emTratamento, setEmTratamento] = useState(false);
  const [tratadas, setTratadas] = useState(false);
  const [pendentes, setPendentes] = useState(false);
  const [upload, setUpload] = useState(false);
  const [uploadErros, setUploadErros] = useState(false);
  const [backup, setBackup] = useState(false);

  const [diretorioAtual, setDiretorioAtual] = useState([]);
  const [dados, setDados] = useState(false);
  const [modif, setModif] = useState(false);
  const [pastasSelecionadas, setPastasSelecionadas] = useState([]);

  useEffect(async () => {
    let foo = await requestSomaStudio(route)
    setSemTratamento(foo.sem_tratamento.info)
    setEmTratamento(foo.em_tratamento.info)
    setTratadas(foo.tratadas.info)
    setPendentes(foo.upload_pendentes.info)
    setUpload(foo.upload_enviadas.info)
    setUploadErros(foo.upload_erros.info)
    setBackup(foo.backup.info)
    setLoading(true)
  }, []);


  return (
    <>
      <MenuFarolDeskMobile
        title={<HeaderDesk setPastasSelecionadas={setPastasSelecionadas} setDiretorioAtual={setDiretorioAtual} setModif={setModif} atualizado={dados.atualizado} setBusca={setBusca} tipoBusca={"FOTOS"} setLoading={setLoading} tipoTela={"PRINCIPAL"} title={busca ? "Resultado da busca" : "Fotos"} />}
        body={
          <>
            <Container maxWidth="sm" className="container">
              <div className="divider-top" />
              <InfoMenuMobile setPastasSelecionadas={setPastasSelecionadas} title={busca ? "Resultado da busca" : "Fotos"} setBusca={setBusca} tipoBusca={"FOTOS"} setLoading={setLoading} atualizado={""} setDiretorioAtual={setDiretorioAtual} setModif={setModif} tipoTela={true} enderecoVoltar={"/fotos/inicio"} />

              {!busca ? (<>

                <div style={{ padding: "56px 0" }}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <CardInicio item={{ load: isLoading, tituloCard: "Sem Tratamento", textoModal: "Fotos que não receberam tratamento.", qtd: semTratamento, endereco: "/sem-tratamento" }} />
                    <CardInicio item={{ load: isLoading, tituloCard: "Em Tratamento", textoModal: "Fotos que estão recebendo tratamento.", qtd: emTratamento, endereco: "/em-tratamento" }} />
                    <CardInicio item={{ load: isLoading, tituloCard: "Tratadas", textoModal: "Fotos que já receberam tratamento.", qtd: tratadas, endereco: "/tratadas" }} />
                    <CardInicio item={{ load: isLoading, tituloCard: "Aguardando Upload", textoModal: `Fotos que aprovadas pela marca e que estão aguardando o upload da ${tipo_ecom} ser feito pelo Farol.`, qtd: pendentes, endereco: "/pendentes-upload" }} />
                    <CardInicioUpload item={{ load: isLoading, tituloCard: `Uploads ${tipo_ecom}`, textoModal: `Fotos que já houve tentativa de upload na ${tipo_ecom} pelo no Farol.`, qtd: { upload: upload, erros: uploadErros }, endereco: "/upload" }} />
                    <CardInicio item={{ load: isLoading, tituloCard: "Backup", textoModal: "Fotos que estão armazenadas em backup", qtd: backup, endereco: "/backup" }} />
                  </Grid >
                </div>
              </>) : (<>
                <BuscaFotos isLoading={isLoading} setLoading={setLoading} retBusca={busca} setDiretorioAtual={setDiretorioAtual} diretorioAtual={diretorioAtual} setBusca={setBusca} />
              </>)}

            </Container >
          </>
        }
      />
    </>
  );
}
