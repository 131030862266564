import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const user = useContext(UserContext);

  if (user.app !== "Farol") {
    return <Navigate to="/login" />;
  }

  return children;
};
