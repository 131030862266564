import { Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { formatPrice } from "../../../utils/functions";
import { useNavigate } from "react-router";
import SendGTAGEvent from "../../../utils/sendGTAGEvent";

export default function CardOfertadosNaoOfertados({ email, porcentagem, qtd, qtdRefs, valor, cor, texto, isLoading }) {
  let arrow = (texto == "Ofertados") || (texto == "Não ofertados") ? (porcentagem < 100) ? true : false : false;
  const navigate = useNavigate();

  const tela_detalhe = (tipo_tela) => {
    if (tipo_tela == "Ofertados" || tipo_tela == "Não ofertados") {
      navigate(tipo_tela == "Ofertados" ? "/gestao/ofertados" : "/gestao/naoOfertados");
    }
    if (tipo_tela == "Não ofertados") {
      SendGTAGEvent('card_nao_ofertado', 'principal', 'click', email);
    }
  };

  return (
    < Grid item className="box-individual-2 box-individual-2-principal" onClick={() => { tela_detalhe(texto) }}>
      {isLoading ? (<Skeleton className="skeleton-format" variant="rectangular" height={110} />) : (<>
        <div className="nao-ofertados">
          <div className="nao-ofertados-text">
            <div className={`circle ${cor}`} />
            <h6 className="h6formatoffer" > {texto}</h6 >
            <h6 className="valor-ofertado"> {`(${porcentagem}%)`} </h6>
          </div >
          {arrow && (<ArrowForwardIosRoundedIcon className="iconeAvancar" />)}
        </div >
        <Typography className="sub-titulo-1 white-space-2 p-l-40"> Qtd. de Referências: <strong className="sub-titulo-3"> {qtdRefs} </strong> </Typography>
        <Typography className="sub-titulo-1 white-space-2 p-l-40"> Qtd. de SKU: <strong className="sub-titulo-3"> {qtd} </strong> </Typography>
        <Typography className="sub-titulo-1 white-space-2 p-l-40">Receita: <strong className="sub-titulo-3"> {formatPrice(valor)} </strong> </Typography>
      </>)}
    </Grid >

  );
}
