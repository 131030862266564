import React, { useState, useEffect } from "react";
import "../Diretorio/diretorio.css"
import { Grid } from "@material-ui/core";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import BasicBreadcrumbsBusca from "../../Breadcrumbs/BreadcrumbsBusca";

export default function DiretorioBusca({ diretorios,  setPastasSelecionadas, pastasSelecionadas }) {

  let prevent = 0;
  let timer = 0;

  const handleClick = (path, id) => {
    if (window.innerWidth < 959) {
      handleDoubleClick(id)

    } else {
      timer = setTimeout(() => {

        if (!prevent) {
          let el = document.getElementById(id);
          if (el) {
            el = el.classList;
            if (Object.values(el).includes('cor-normal')) {
              el.remove('cor-normal');
              el.add('cor-selecionada');

              setPastasSelecionadas(prevArray => [...prevArray, path]);
            } else {
              el.add('cor-normal');
              el.remove('cor-selecionada');

              let foo = pastasSelecionadas.filter(item => {

                return item != path;
              })
              setPastasSelecionadas(foo)

            }
          }
        }
        prevent = false;
      }, 200);
    };
  };

  const handleDoubleClick = async (e) => {
    e = (e.replace("//", "/")).split("/");
    let foo = e;
    let primeiro = foo.shift();
    e.pop();

    let diretorioBusca = `${primeiro}/${e.join("/")}`

    window.location.href = `/fotos/${primeiro.replaceAll('_', '-')}?diretorioBusca=${diretorioBusca}`
  }

  const ultimo = diretorios[diretorios.length - 1];

  return (<>{
    diretorios.map((item) => {

      let path_sem_nome = item.split("/");
      let nome = path_sem_nome.pop();
      path_sem_nome = `${path_sem_nome.join("/")}/`
      let path = `${item}/`;

      return <div className="p-b-16">
        <div className="box-breadcrumbs">
          <BasicBreadcrumbsBusca diretorios={path_sem_nome} />
        </div>

        <Grid container justifyContent="flex-start" alignItems="center" spacing={4}>

          <Grid item xs={6} sm={2} className="cursor-diretorio" onClick={() => handleClick(path, item)} onDoubleClick={() => handleDoubleClick(path)}>
            <div className="card-diretorio cor-normal" id={item} nome={nome} path={path}>
              <div className="width-100 alingamento-card-diretorio" >
                <FolderOutlinedIcon />
                <p className="p-card-diretorio">{nome}</p>
              </div>
            </div>
          </Grid >

        </Grid >
        {item != ultimo && <div className="divider m-t-16" />}
      </div >
    })}
  </>);
}

