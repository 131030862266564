import React, { useContext } from "react";
import SearchButton from "../Gestao/SearchButton/Search";
import UserInfo from "../Gestao/UserInfo/UserInfo";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useNavigate } from "react-router";
import { getSessionStorage, setSessionStorage } from "../../utils/sessionStorage";
import BasicBreadcrumbs from "../Breadcrumbs/Breadcrumbs";

export default function InfoMenuMobile({ title, tipoBusca, setBusca, setLoading, atualizado, tipoTela, enderecoVoltar, setPastasSelecionadas, setDiretorioAtual, setModif }) {

    const navigate = useNavigate();
    // const marcas = getSessionStorage("user").marcasPermitidas;
    
    return (<>
        <div className="box-top-mob">
            <div className="info-top">

                {tipoTela && (
                    <div className="icone-superior">
                        <ArrowBackIosRoundedIcon className="iconeAvancar icone-avancar-not " onClick={() => navigate(enderecoVoltar)} />
                    </div>
                )}
                <div className="info-top-2">
                    {/* <h1 className="titulo-principal">{title}</h1> */}
                    <BasicBreadcrumbs diretorios={title} setDiretorioAtual={setDiretorioAtual} setModif={setModif} />

                    <h6 className="sub-titulo-principal"> Última atualização: {atualizado} </h6>
                </div>
                <div className="info-top-3-mob">
                    <SearchButton setPastasSelecionadas={setPastasSelecionadas} tipoBusca={tipoBusca} setBusca={setBusca} setLoading={setLoading} />
                    <div className="info-top-4"> <UserInfo /> </div>
                </div>
            </div>
            <div className="divider" />
        </div>
    </>);
}

