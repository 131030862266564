import React from "react";
import { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";


export default function SelectColecoes({ colecoes, setDadosTable, dadosOriginais, setTotalOferta, setLoading }) {

  const [colecoesSelecionadas, setColecoesSelecionadas] = useState(["Todas"]);
  const [pacotesSelecionados, setPacotesSelecionados] = useState(["Todos"]);

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);



  const handleChangeColecoes = (event) => {
    const { target: { value } } = event;

    if (value.indexOf("OK") > -1) { return; }
    if (value.length > 1 && value[0] === "Todas") { value.shift(); }
    const colecoes = value.length === 0 ? ["Todas"] : value;
    setColecoesSelecionadas(colecoes);
    handleClick(colecoes);

  };



  const handleClick = async (colecoes) => {
    setLoading(true);

    if (colecoes.length === 0 || colecoes.includes("Todas")) {
      setDadosTable(dadosOriginais);
    } else {

      const filteredDados = await dadosOriginais.filter(dado => {

        let colecao = dado.COLECAO ? dado.COLECAO : dado.colecao;
        return colecoes.includes(colecao.toUpperCase())
      });

      setDadosTable(filteredDados);
      setTotalOferta(filteredDados.reduce((prev, curr) => prev + (parseFloat(curr.PRECO_ECOMMERCE) * parseFloat(curr.ESTOQUE)), 0))
    }

    setLoading(false);
  };

  return (

    <div className="">
      <Typography component="h2" className="title-box-select title-box-select-1" > Coleção </Typography>
      <FormControl variant="outlined" className="select select-1 select-scroll-lateral" >
        <InputLabel id="colecoes-multiple-checkbox-label"></InputLabel>
        <Select
          name="filtro_colecao"
          labelId="colecoes-multiple-checkbox-label"
          multiple
          value={colecoesSelecionadas}
          onChange={handleChangeColecoes}
          renderValue={(selected) => selected.join(", ")}
        >
          {/* <MenuItem value="OK"> <button id="btn_sel_colecoes_select" className="btn-sel-colecoes-select" onClick={handleClick} > Consultar </button> </MenuItem> */}
          {colecoes &&
            colecoes.map((colecao) => (
              <MenuItem key={colecao} value={colecao} >
                <Checkbox checked={colecoesSelecionadas.indexOf(colecao) > -1} />
                <ListItemText primary={colecao} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>

  );
}
