import React from "react";
import "./cardFoto.css"
import { getBucketName, drag, resizeFile } from "../../../utils/functions";
import { getSessionStorage, setSessionStorage } from "../../../utils/sessionStorage";
import Checkbox from "../../../components/Checkbox/Checkbox";
// import fetch from "fetch";

export default function CardFoto({ url, titulo, setStateRefSelecionadas, chip, handlerOpenModalDetalheFoto, name, referencia }) {
  chip = chip ? chip.toLowerCase() : "";

  const handleClickFoto = async (e) => {
    if (window.innerWidth < 959) {
      handlerOpenModalDetalheFoto(referencia)
    } else {

      const marca = (getSessionStorage("user").marcaSelecionada).replace(/ /g, "");
      const chave = `fotos_selecionadas_${marca}`

      const ref = e.target.value;
      const path = (e.target).getAttribute('url')
      const obj = { path: path.replace(`https://storage.googleapis.com/${await getBucketName(marca)}/`, ""), ref: ref };

      const check = e ? e.target.checked : false;
      let card = document.getElementById(ref);
      let fotos = getSessionStorage(chave) ? getSessionStorage(chave) : [];

      if (check) {
        fotos.push(obj);
        card.classList.add('card-back-blue');
      } else if (!check) {
        fotos.splice(fotos.indexOf(ref), 1);
        card.classList.remove('card-back-blue');
      }

      let valid = [];
      fotos = fotos.filter(item => {
        if (!valid.includes(item.ref)) {
          valid.push(item.ref)
          return item;
        }
      })
      setSessionStorage(chave, fotos)
      setStateRefSelecionadas(fotos.length)

      // APENAS PARA SELECIONAR O CHECKBOX DA LINHA DE PRODUTOS QUANDO TODOS OS ITENS FOREM SELECIONADO
      let elem = document.getElementsByName(e.target.name);
      const qtd = elem[0].getAttribute('qtdfotos');
      let valid2 = 0;

      elem.forEach(element => { if ((!element.getAttribute('qtdFotos')) && element.checked) { valid2++; } });

      if ((valid2 == qtd) && check && !elem[0].checked) {
        elem[0].click();
      } else if ((valid2 != qtd) && elem[0].checked) {
        elem[0].setAttribute('checked', false);
        document.getElementById(`${elem[0].getAttribute('id')}_check`).classList.add('displayNone')
        document.getElementById(`${elem[0].getAttribute('id')}_not_check`).classList.remove('displayNone')
      } else if ((valid2 == qtd) && check && elem[0].checked) {
        elem[0].setAttribute('checked', true);
        document.getElementById(`${elem[0].getAttribute('id')}_not_check`).classList.add('displayNone')
        document.getElementById(`${elem[0].getAttribute('id')}_check`).classList.remove('displayNone')
      }
    }

  };

  return (<>
    <div id={`${referencia}_${titulo}`} className="p-16-card-foto cursor">
      <label onDoubleClick={() => handlerOpenModalDetalheFoto(referencia)}>
        <div className="displayNone"> <Checkbox value={`${referencia}_${titulo}`} name={name} click={handleClickFoto} url={url} /> </div>
        <div className="box-foto">
          <div className="box-info-foto">
            <div className={`chip-card-foto ${chip} `}>{chip == "sucesso" ? "Enviada" : chip == "erro" ? "Com erro" : ""}</div>
          </div>

          <img src={url} className="img-foto" draggable="true" onDragStart={(e) => drag(e)} />
        </div>
        <p className=" nome-foto" >{titulo}</p>

      </label>
    </div >
  </>);
}

